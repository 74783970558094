import React from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import { PostAxios, PatchAxios } from "../../../helper/AxiosHelper";

import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { ContactSchema } from "./Schemas";
import { useQueryClient } from "react-query";

const ContactForm = ({ edit }) => {
  const location = useLocation();
  const client = useQueryClient();
  const navigate = useNavigate();
  const InitialState = {
    first_name: edit ? location.state.first_name : "",
    last_name: edit ? location.state.last_name : "",
    email: edit ? location.state.email : "",
    phone: edit ? location.state.phone : "",
    status: edit ? location.state.status : "active",
    message: edit ? location.state.message : "",
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: ContactSchema,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });
  const handlePost = async (e) => {
    const res = edit
      ? await PatchAxios(`/admin/contact/${location.state._id}`, values)
      : await PostAxios("/admin/contact", values);
    if (res.success) {
      client.invalidateQueries("ContactList");
      navigate("/admin/contact");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
      return true;
    } else {
      toast.error(res.message);
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="m-2 p-2">
          <h2 className="text-center text-muted mb-2">Add Contact</h2>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="name">
                First Name
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  id="name"
                  placeholder="First Name"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.first_name && touched.first_name ? (
                <p className="text-danger">{errors.first_name}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="name">
                Last Name
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  id="name"
                  placeholder="Last Name"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.last_name && touched.last_name ? (
                <p className="text-danger">{errors.last_name}</p>
              ) : null}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="colourIdentifier">
                Email<span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  id="colourIdentifier"
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
              </InputGroup>
              {errors.email && touched.email ? (
                <p className="text-danger">{errors.email}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="colourIdentifier">
                Phone<span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  id="colourIdentifier"
                  placeholder="Phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
              </InputGroup>
              {errors.phone && touched.phone ? (
                <p className="text-danger">{errors.phone}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="residentType">Status</Form.Label>
              <Form.Select
                className="form-select form-select-sm mb-3"
                aria-label="Default select example"
                name="status"
                value={values.status}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={8}>
            <Form.Group>
              <Form.Label htmlFor="roomNumber">Message</Form.Label>
              <div className="input-group">
                <Form.Control
                  as="textarea"
                  rows={1}
                  className="form-control"
                  placeholder=" Address"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {errors.description && touched.description ? (
                <p className="text-danger">{errors.description}</p>
              ) : null}
            </Form.Group>
          </Col>
          <div className="mt-4 d-flex justify-content-between">
            <Button
              type="submit"
              className={`btn btn-primary  `}
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting}
            >
              {/* {edit && "Update"}
              {!edit && "Submit"} */}
              {/* {edit
                ? isSubmitting && "Updating...."
                : !isSubmitting && "Submit"} */}
              {edit
                ? isSubmitting
                  ? "Updating...."
                  : "Update"
                : isSubmitting
                ? "Adding...."
                : "Submit"}
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default ContactForm;
