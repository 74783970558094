// store.js
import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./adminSlice";
import EmpReducer from "./ESlice";
const store = configureStore({
  reducer: {
    // Add your slices here
    admin: adminReducer,
    emp: EmpReducer,
  },
});

export default store;
