import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { PatchAxios } from "../../../../helper/AxiosHelper";
import { toast } from "react-toastify";

const NotViewModel = ({ show, handleClose, content }) => {
  const fileURL = process.env.REACT_APP_FILE_URL;
  const { vacancies } = content
  const { vacancy, employee, employer } = vacancies[0];
  const handleAssign = async () => {
    const res = await PatchAxios(`/admin/apply_vacancy/${content.id}?employer_status=active`);
    if (res.success) {
      toast.success(res.message);
    }
    else {
      toast.error(res.message);
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      scrollable
      className="not-confirm"
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title>Vacancy Detail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {/* Vacancy Information */}
          <Col md={4}>
            <h5>Vacancy Information</h5>
            <p>Title: {vacancy.title}</p>
            <p>Description:</p>
            <div
              dangerouslySetInnerHTML={{ __html: vacancy.description }}
              className="vacancy-description " id="dangerouslySetImage"
            />
            <p>Employer: {employer.company_name}</p>
            <p>Status: {vacancy.status}</p>
            <p>Category: {vacancy.category.map(cat => cat.title).join(", ")}</p>
            <p>Tags: {vacancy.tag.map(tag => tag.title).join(", ")}</p>
            <p>Created At: {vacancy.createdAt}</p>
            {/* Add more vacancy details as needed */}
          </Col>

          {/* Employee Information */}
          <Col md={4} >
            <h5>Employee Information</h5>
            <p>Name: {employee.first_name} {employee.last_name}</p>
            <p className="text-wrap">Email: {employee.email}</p>
            <p>Phone: {employee.phone}</p>
            <p>Status: {employee.status}</p>
            <p>Image: <img src={fileURL + employee.image} alt="Employee" className="w-100" /></p>
            <p>Created At: {employee.createdAt}</p>
            {/* Add more employee details as needed */}
          </Col>

          {/* Employer Information */}
          <Col md={4}>
            <h5>Employer Information</h5>
            <p>Email: {employer.signing_officer_email}</p>
            <p>Phone: {employer.phone}</p>
            <p>Company Name: {employer.company_name}</p>
            <p>City: {employer.city}</p>
            <p>State: {employer.state}</p>
            <p>Zip Code: {employer.zip_code}</p>
            <p>Designation: {employer.designation}</p>
            <p>Business Type: {employer.sth_business_type}</p>
            <p>Revenue: {employer.sth_gross_revenue}</p>
            <p>Net Income: {employer.sth_net_income}</p>
            <p>Website: {employer.sth_website}</p>
            <p>Is Nonprofit Affiliated: {employer.is_nonprofit_affiliated}</p>
            {/* Add more employer details as needed */}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="btn btn-secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="btn btn--accent" onClick={handleAssign}>
          Assign
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NotViewModel;
