import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import { UploadImage } from "../../assets/images/";
import { toast } from "react-toastify";
import {
  PatchUserAxios,
  PostUserAxios,
  getUserAxios,
} from "../../../helper/UserAxios";
import { useQuery } from "react-query";
import { useFormik } from "formik";

const EditImagesCatTag = () => {
  const navigate = useNavigate();
  const [country, setCountry] = useState([]);
  const [workExperiences, setWorkExperiences] = useState();
  const [initialWork, setInitialWork] = useState();
  const getCountry = async (e) => {
    const res = await getUserAxios("/country");
    setCountry(res.data.country);
  };

  const handleAddWork = () => {
    setWorkExperiences((prevExperiences) => [
      ...prevExperiences,
      { ...workInitialState },
    ]);
  };
  async function getField() {
    const url = `/employee/experience`;
    try {
      const response = await getUserAxios(url);
      const fetchedList = response.data;
      setWorkExperiences(fetchedList.experience);
      setInitialWork(fetchedList.experience)
    } catch (error) {
      console.log(error);
    }
  }

  const handlePost = async (e) => {
    for (const work of workExperiences) {
      const found = initialWork.find(item => item._id === work._id);

      if (found !== undefined) {
        const res1 = await PatchUserAxios(`/employee/experience/${work._id}`, work);

        if (res1.success) {
          continue;
        } else {
          toast.error(res1.message);
        }
      } else {
        const res1 = await PostUserAxios(`/employee/experience/`, [work]);
        if (res1.success) {
          continue
        } else {
          toast.error(res1.message);
        }
      }
    }
    navigate("/employee/dashboard");
    toast.success("Work Experience Updated");
  }
  const workInitialState = {
    work_employer: "",
    work_mailing_address: "",
    work_city: "",
    work_state_provience: "",
    work_zip_code: "",
    work_country: "",
    work_contact_number: "",
    work_starting_date: "",
    work_ended:"",
    work_ending_date: "",
  };
  // const { data, isError, isLoading } = useQuery("EmpExp", getField);
  console.log(workExperiences)
  const handleWorkChange = (event, index) => {
    const { name, value } = event.target;

    setWorkExperiences((prevExperiences) => {
      const newExperiences = [...prevExperiences];
      newExperiences[index] = {
        ...newExperiences[index],
        [name]: value,
      };
      return newExperiences;
    });
  };

  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    setFieldValue,
  } = useFormik({
    onSubmit: async (values, action) => {
      const res = await handlePost();

      if (res) {
        action.resetForm();
      }
    },
  });
  useEffect(() => {
    getCountry();
    getField();
  }, []);

  return (
    <main className="body-wrapper  bg--1 p-tb-60">
      <div className="container" bis_skin_checked="1">
        <div className="pine-cost" bis_skin_checked="1">
          <h2 className="text-center"> Add Work Experience Here</h2>
          <Form className="data-collection-form module">

            {workExperiences && workExperiences.map((experience, index) => (
              <><Row key={index}>
                <>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>
                        Country
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <InputGroup>
                        <Form.Select
                          aria-label="Default select example"
                          id="work_country"
                          name="work_country"
                          value={experience.work_country}
                          onChange={(event) =>
                            handleWorkChange(event, index)
                          }
                          //onBlur={handleBlur}
                          required
                        >
                          <option value=" ">Select One</option>
                          {country.map((ctry) => (
                            <option
                              value={ctry.title}
                              key={ctry.title}
                            >
                              {ctry.title}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                      {touched.work_country &&
                        errors.work_country && (
                          <div className="text-danger">
                            {errors.work_country}
                          </div>
                        )}
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <div className="form-group">
                      <label htmlFor="work_mailing_address">
                        Mailing Address
                        <span className="text-danger">*</span>
                      </label>
                      <InputGroup
                        className={`text-dark bg-light ${touched.work_mailing_address &&
                          errors.work_mailing_address
                          ? "is-invalid"
                          : ""
                          }`}
                      >
                        <input
                          type="text"
                          className={`form-control ${touched.work_mailing_address &&
                            errors.work_mailing_address
                            ? "is-invalid"
                            : ""
                            }`}
                          id="work_mailing_address"
                          placeholder="Mailing Address"
                          name="work_mailing_address"
                          value={experience.work_mailing_address}
                          onChange={(event) =>
                            handleWorkChange(event, index)
                          }
                        //onBlur={handleBlur}
                        />
                      </InputGroup>
                      {touched.work_mailing_address &&
                        errors.work_mailing_address && (
                          <div className="text-danger">
                            {errors.work_mailing_address}
                          </div>
                        )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label htmlFor="employer">
                        Employer
                        <span className="text-danger">*</span>
                      </label>
                      <InputGroup
                        className={`text-dark bg-light ${touched.employer && errors.employer
                          ? "is-invalid"
                          : ""
                          }`}
                      >
                        <input
                          type="text"
                          className={`form-control ${touched.employer && errors.employer
                            ? "is-invalid"
                            : ""
                            }`}
                          id="employer"
                          placeholder="Employer"
                          name="work_employer"
                          value={experience.work_employer}
                          onChange={(event) =>
                            handleWorkChange(event, index)
                          }
                        //onBlur={handleBlur}
                        />
                      </InputGroup>
                      {touched.employer && errors.employer && (
                        <div className="text-danger">
                          {errors.employer}
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="form-group">
                      <label htmlFor="work_city">
                        City
                        <span className="text-danger">*</span>
                      </label>
                      <InputGroup
                        className={`text-dark bg-light ${touched.work_city && errors.work_city
                          ? "is-invalid"
                          : ""
                          }`}
                      >
                        <input
                          type="text"
                          className={`form-control ${touched.work_city && errors.work_city
                            ? "is-invalid"
                            : ""
                            }`}
                          id="work_city"
                          placeholder="City"
                          name="work_city"
                          value={experience.work_city}
                          onChange={(event) =>
                            handleWorkChange(event, index)
                          }
                        //onBlur={handleBlur}
                        />
                      </InputGroup>
                      {touched.work_city && errors.work_city && (
                        <div className="text-danger">
                          {errors.work_city}
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="form-group">
                      <label htmlFor="work_state_provience">
                        State or Province
                        <span className="text-danger">*</span>
                      </label>
                      <InputGroup
                        className={`text-dark bg-light ${touched.work_state_provience &&
                          errors.work_state_provience
                          ? "is-invalid"
                          : ""
                          }`}
                      >
                        <input
                          type="text"
                          className={`form-control ${touched.work_state_provience &&
                            errors.work_state_provience
                            ? "is-invalid"
                            : ""
                            }`}
                          id="work_state_provience"
                          placeholder="State or Province"
                          name="work_state_provience"
                          value={experience.work_state_provience}
                          onChange={(event) =>
                            handleWorkChange(event, index)
                          }
                        //onBlur={handleBlur}
                        />
                      </InputGroup>
                      {touched.work_state_provience &&
                        errors.work_state_provience && (
                          <div className="text-danger">
                            {errors.work_state_provience}
                          </div>
                        )}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="form-group">
                      <label htmlFor="work_zip_code">
                        Zip Code
                        <span className="text-danger">*</span>
                      </label>
                      <InputGroup
                        className={`text-dark bg-light ${touched.work_zip_code &&
                          errors.work_zip_code
                          ? "is-invalid"
                          : ""
                          }`}
                      >
                        <input
                          type="text"
                          className={`form-control ${touched.work_zip_code &&
                            errors.work_zip_code
                            ? "is-invalid"
                            : ""
                            }`}
                          id="work_zip_code"
                          placeholder="Zip Code"
                          name="work_zip_code"
                          value={experience.work_zip_code}
                          onChange={(event) =>
                            handleWorkChange(event, index)
                          }
                        //onBlur={handleBlur}
                        />
                      </InputGroup>
                      {touched.work_zip_code &&
                        errors.work_zip_code && (
                          <div className="text-danger">
                            {errors.work_zip_code}
                          </div>
                        )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label htmlFor="work_contact_number">
                        Contact Number
                        <span className="text-danger">*</span>
                      </label>
                      <InputGroup
                        className={`text-dark bg-light ${touched.work_contact_number &&
                          errors.work_contact_number
                          ? "is-invalid"
                          : ""
                          }`}
                      >
                        <input
                          type="text"
                          className={`form-control ${touched.work_contact_number &&
                            errors.work_contact_number
                            ? "is-invalid"
                            : ""
                            }`}
                          id="work_contact_number"
                          placeholder="Contact Number"
                          name="work_contact_number"
                          value={experience.work_contact_number}
                          onChange={(event) =>
                            handleWorkChange(event, index)
                          }
                        //onBlur={handleBlur}
                        />
                      </InputGroup>
                      {touched.work_contact_number &&
                        errors.work_contact_number && (
                          <div className="text-danger">
                            {errors.work_contact_number}
                          </div>
                        )}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="form-group">
                      <label htmlFor="work_starting_date">
                        Starting Date
                        <span className="text-danger">*</span>
                      </label>
                      <InputGroup
                        className={`text-dark bg-light ${touched.work_starting_date &&
                          errors.work_starting_date
                          ? "is-invalid"
                          : ""
                          }`}
                      >
                        <input
                          type="date"
                          className={`form-control ${touched.work_starting_date &&
                            errors.work_starting_date
                            ? "is-invalid"
                            : ""
                            }`}
                          id="work_starting_date"
                          name="work_starting_date"
                          value={experience.work_starting_date}
                          onChange={(event) =>
                            handleWorkChange(event, index)
                          }
                          max={new Date().toISOString().split("T")[0]}
                        //onBlur={handleBlur}
                        />
                      </InputGroup>
                      {touched.work_starting_date &&
                        errors.work_starting_date && (
                          <div className="text-danger">
                            {errors.work_starting_date}
                          </div>
                        )}
                    </div>
                  </Col>
                  <Col md={6}>
                      <div className="form-group">
                        <label htmlFor="work_ended">
                          Are you currently involved in this Job? 
                          <span className="text-danger">*</span>
                        </label>

                        <select
                          className="form-select"
                          id="work_ended"
                          name="work_ended"
                          value={experience.work_ended}
                          onChange={(event) =>
                            handleWorkChange(event, index)
                          }
                          onBlur={handleBlur}
                        >
                          <option value=" ">Select One</option>
                          <option value="false">No</option>
                          <option value="true">Yes</option>
                        </select>
                      </div>
    
                    </Col>
                  {experience.work_ended=='false' && ( <Col md={6}>
                    <div className="form-group">
                      <label htmlFor="work_ending_date">
                        Ending Date
                        <span className="text-danger">*</span>
                      </label>
                      <InputGroup
                        className={`text-dark bg-light ${touched.work_ending_date &&
                          errors.work_ending_date
                          ? "is-invalid"
                          : ""
                          }`}
                      >
                        <input
                          type="date"
                          className={`form-control ${touched.work_ending_date &&
                            errors.work_ending_date
                            ? "is-invalid"
                            : ""
                            }`}
                          id="work_ending_date"
                          name="work_ending_date"
                          value={experience.work_ending_date}
                          onChange={(event) =>
                            handleWorkChange(event, index)
                          }
                          //onBlur={handleBlur}
                          max={new Date().toISOString().split("T")[0]}
                        />
                      </InputGroup>
                      {touched.work_ending_date &&
                        errors.work_ending_date && (
                          <div className="text-danger">
                            {errors.work_ending_date}
                          </div>
                        )}
                    </div>
                  </Col>)}

                </>
              </Row>
                <hr />
              </>
            ))}
            <Button onClick={handleAddWork}>
              Add another Work
            </Button>
          </Form>
          <div className="d-flex justify-content-around">
            <button className="btn btn-success" onClick={handlePost}>
              Submit
            </button>
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EditImagesCatTag;
