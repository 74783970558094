export function snakeCaseToTitleCase(inputString) {
  const words = inputString.split("_");
  const titleCaseWords = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return titleCaseWords;
}
function wordsToSnakeCase(words) {
  const snakeCaseWords = words.map((word) => word.toLowerCase());
  return snakeCaseWords.join("_");
}

function titleCaseToSnakeCase(titleCaseStr) {
  const words = titleCaseStr.split(/\s+/);
  return wordsToSnakeCase(words);
}

function camelCaseToSnakeCase(camelCaseStr) {
  const words = camelCaseStr.split(/(?=[A-Z])/);
  return wordsToSnakeCase(words);
}

export function searchKeyConversion(inputStr) {
  console.log(inputStr);
  let snakeCaseResult;
  if (/^\s*[A-Z][a-z]*(\s+[A-Z][a-z]*)*\s*$/.test(inputStr)) {
    snakeCaseResult = titleCaseToSnakeCase(inputStr);
  } else if (/^\s*[a-z][a-zA-Z]*\s*$/.test(inputStr)) {
    snakeCaseResult = camelCaseToSnakeCase(inputStr);
  } else if (/^\s*[^\s]+\s*$/.test(inputStr)) {
    const words = inputStr.split(/\s+/);
    snakeCaseResult = wordsToSnakeCase(words);
  }
  return snakeCaseResult;
}
