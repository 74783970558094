// adminSlice.js
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  emp_info: {},
};
const empSlice = createSlice({
  name: "empInfo",
  initialState,
  reducers: {
    addEmp: (state, action) => {
      state.emp_info = action.payload;
    },
    deleteEmp: (state, action) => {
      state.user_info = initialState; // Delete a user by ID
    },
  },
});

export const { addEmp, deleteEmp } = empSlice.actions;
export default empSlice.reducer;
