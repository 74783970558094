import * as Yup from "yup";

const AddressSchema = Yup.object().shape({
  country: Yup.string().required("This field can't be Empty"),
  current_street: Yup.string().required("This field can't be Empty"),
  current_city_town: Yup.string().required("This field can't be Empty"),
  current_provience_state: Yup.string().required("This field can't be Empty"),
  zipcode: Yup.string()
    .required("This field can't be Empty")
    .matches(/^\d{5}$/, "Invalid Zip Code"),
});

export default AddressSchema;
