import React, { useRef, useState } from 'react'
import { PatchAxios } from '../../../helper/AxiosHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { Button } from 'react-bootstrap';

const ChangeImage = () => {
    const imageRef = useRef(null);
    const [images, setImages] = useState("");
    const location = useLocation();
    const navigate=useNavigate();
    const client=useQueryClient();
    const baseURL = process.env.REACT_APP_FILE_URL;
    const InitialState = {
        image: images,
    };
    function handleImageClick(e) {
        imageRef.current.click();
    }
    function handleImageChange(e) {
        const file = e.target.files[0];
        setImages(file);
    }
    const handlePost = async (e) => {
        const formData = new FormData();
        formData.append('image', images)

        const res = await PatchAxios('/user/changeimage', formData)

        if (res.success) {
            navigate("/admin/dashboard");
            toast.success(res.message);
            client.refetchQueries("adminStatus");
        } else {
            toast.error(res.message);
        }
    };
    const {
        isSubmitting,
        values,
        handleSubmit,
        isValid,
    } = useFormik({
        initialValues: InitialState,
        onSubmit: async (values, action) => {
            const res = await handlePost();
            if (res) {
                action.resetForm();
            }
        },
    });

    return (
        <div className='text-center'>
            <h3 className='text-light'>Upload Image</h3>
            <div className="image_holder">
                {!images ? (
                    <img
                        src={
                            InitialState.image
                                ? `${baseURL}/${InitialState.image}`
                                : "https://media.istockphoto.com/id/1248723171/nl/vector/camera-pictogram-van-het-foto-uploaden-op-ge%C3%AFsoleerde-witte-achtergrond-eps-10-vector.jpg?s=612x612&w=0&k=20&c=WLTMjPeoFbET0aBnnJmLyzDE1wUkJu9nj07KPtmf8qg="
                        }
                        className="rounded-circle"
                        alt="Cinque Terre"
                        height={200}
                        width={200}
                        onClick={handleImageClick}
                    />
                ) : (
                    <img
                        src={URL.createObjectURL(images)}
                        alt="bannerInput"
                        className="rounded-circle"
                        height={200}
                        width={200}
                        onClick={handleImageClick}
                    />
                )}
                <input
                    type="file"
                    ref={imageRef}
                    accept="image/*"
                    onChange={handleImageChange}
                />
            </div>
            <Button
                type="submit"
                className={`btn btn-primary`}
                onClick={handleSubmit}
                disabled={!isValid || isSubmitting}
            >
                {isSubmitting
                    ? "Updating...."
                    : "Update"
                }
            </Button>
        </div>
    )
}

export default ChangeImage