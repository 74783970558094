import React, { useState, useRef, useEffect } from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { VacancySchema } from "./Schemas";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios, getAxios } from "../../../helper/AxiosHelper";

import "./Form.css";
import Editor from "../GeneralUI/Editor/Editor";
import MultiSelect from "../GeneralUI/MultiSelect/MultiSelect";
const VacancyForm = ({ edit }) => {
  const location = useLocation();

  const navigate = useNavigate();
  const client = useQueryClient();
  const [employers, setEmployer] = useState([]);
  // const [tag, setTag] = useState([]);
  // const [category, setCategory] = useState([]);
  const InitialState = {
    title: edit ? location.state.title : "",
    description: edit ? location.state.description : "",
    slug: edit ? location.state.slug : "",
    employer: edit ? location.state.employer._id : "",
    status: edit ? location.state.status : "active",
    // category: edit ? location.state.category.map((item) => item._id) : [],
    // tag: edit ? location.state.tag.map((item) => item._id) : [],
  };

  // const [multiSelectedTag, setMultiSelectedTag] = useState(
  //   edit ? InitialState.tag : []
  // );
  // const [multiSelectedCategory, setMultiSelectedCategory] = useState(
  //   edit ? InitialState.category : []
  // );

  const handlePost = async (e) => {
    const submitValues = {
      ...values,
      description: editorValue,
      // category: multiSelectedCategory,
      // tag: multiSelectedTag,
    };

    const res = edit
      ? await PatchAxios(`/admin/vacancy/${location.state._id}`, submitValues)
      : await PostAxios("/admin/vacancy", submitValues);

    if (res.success) {
      navigate("/admin/vacancy");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
      client.refetchQueries("VacancyList");
    } else {
      toast.error(res.message);
    }
  };

  const [editorValue, setEditorValue] = useState(
    edit ? InitialState.description : ""
  );

  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: VacancySchema,
    onSubmit: async (values, action) => {
      // const errorMessageCategory =
      //   (!multiSelectedCategory || multiSelectedCategory.length === 0) &&
      //   "Please select at least one Category.";
      // const errorMessageTag =
      //   (!multiSelectedTag || multiSelectedTag.length === 0) &&
      //   "Please select at least one Tag.";

      // Display each toast separately
      // if (errorMessageCategory || errorMessageTag) {
      //   errorMessageCategory && toast.error(errorMessageCategory);
      //   errorMessageTag && toast.error(errorMessageTag);
      // } else {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });

  const getEmployer = async (e) => {
    const res = await getAxios("/admin/employer");
    setEmployer(res.data.employer);
  };
  // const getTag = async (e) => {
  //   const res = await getAxios("/admin/tag");
  //   setTag(res.data.tag);
  // };
  // const getCategory = async (e) => {
  //   const res = await getAxios("/admin/category");
  //   setCategory(res.data.category);
  // };
  useEffect(() => {
    getEmployer();
    // getTag();
    // getCategory();
  }, []);
  return (
    <>
      <Form>
        <Row className="m-2 p-2">
          <h2 className="text-center text-muted mb-2">
            {edit ? "Update" : "Add"} Vacancy
          </h2>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="name">
                Title
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  placeholder="Title"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.title && touched.title}
                />
              </InputGroup>
              {errors.title && touched.title && (
                <p className="text-danger">{errors.title}</p>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="slug">Slug</Form.Label>
              <Form.Control
                type="text"
                name="slug"
                value={values.slug}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={errors.slug && touched.slug}
              />
            </Form.Group>
            {errors.slug && touched.slug && (
              <p className="text-danger">{errors.slug}</p>
            )}
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="role_id">
                Employer <span className="text-danger">*</span>
              </Form.Label>

              <Form.Select
                className="form-select form-select-sm mb-3"
                aria-label="Default select example"
                name="employer"
                value={values.employer}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              >
                {!edit ? (
                  <option value="">Select One</option>
                ) : (
                  <option value={InitialState.employer}>
                    {location.state.employer.contact_name}
                  </option>
                )}
                {employers.map((employer) => {
                  return (
                    employer._id !== InitialState.employer && (
                      <option key={employer._id} value={employer._id}>
                        {employer.company_name}
                      </option>
                    )
                  );
                })}
              </Form.Select>
            </Form.Group>
            {errors.employer && touched.employer ? (
              <p className="text-danger">{errors.employer}</p>
            ) : null}
          </Col>

          {/* <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="category">Category</Form.Label>
              <MultiSelect
                data={category}
                values={multiSelectedCategory}
                onValueChange={setMultiSelectedCategory}
                edit={edit}
              />
            </Form.Group>
            {errors.category && touched.category && (
              <p className="text-danger">{errors.category}</p>
            )}
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="tag">Tag</Form.Label>
              <MultiSelect
                data={tag}
                values={multiSelectedTag}
                onValueChange={setMultiSelectedTag}
                edit={edit}
              />
            </Form.Group>
            {errors.tag && touched.tag && (
              <p className="text-danger">{errors.tag}</p>
            )}
          </Col> */}
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="status">Status</Form.Label>
              <Form.Select
                className="form-select form-select-sm mb-3"
                aria-label="Default select example"
                name="status"
                value={values.status}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={errors.status && touched.status}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Select>
            </Form.Group>
            {errors.status && touched.status && (
              <p className="text-danger">{errors.status}</p>
            )}
          </Col>
          <Form.Label htmlFor="hlt_doctors_on_admission">
            Description
          </Form.Label>

          <Editor values={editorValue} onValueChange={setEditorValue} />

          <div className="mt-4 d-flex justify-content-between">
            <Button
              type="submit"
              className={`btn btn-primary  `}
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting}
            >
              {edit
                ? isSubmitting
                  ? "Updating...."
                  : "Update"
                : isSubmitting
                ? "Submitting...."
                : "Submit"}
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default VacancyForm;
