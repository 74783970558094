import React, { useState, useRef, useEffect } from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios } from "../../../helper/AxiosHelper";

import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";

const PermissionForm = ({ edit }) => {
  const location = useLocation();
  const client = useQueryClient();
  const navigate = useNavigate();
  const InitialState = {
    name: edit ? location.state.name : "",
  };
  const PermissionSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
  const handlePost = async (e) => {
    console.log(values);
    const res = edit
      ? await PatchAxios(`/admin/permission/${location.state._id}`, values)
      : await PostAxios("/admin/permission", values);

    if (res.success) {
      navigate("/admin/permission");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
      return true;
    } else {
      toast.error(res.error);
    }
  };
  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: PermissionSchema,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
      if (res.success) {
        navigate("/admin/permission");
        toast.success(edit ? "Updated Successfully" : "Added Successfully");
        return true;
      } else {
        toast.error(res.error);
      }
    },
  });
  return (
    <Form onSubmit={handleSubmit}>
      <Row className="m-2 p-2">
        <Col md={6}>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.name && errors.name}
              placeholder="Permission Name"
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <div className="mt-4 d-flex justify-content-between">
        <Button type="submit" className="btn btn-primary ">
          Submit
        </Button>
        <Button
          type="button"
          className="btn btn-danger"
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
};
export default PermissionForm;
