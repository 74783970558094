import React, { useState, useRef } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import "./Form.css";
import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { DefaultSeoSchema } from "./Schemas";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios } from "../../../helper/AxiosHelper";

import Editor from "../GeneralUI/Editor/Editor";
const DefaultSeoForm = ({ edit }) => {
  const baseURL = process.env.REACT_APP_FILE_URL;
  const location = useLocation();
  const client = useQueryClient();
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const [images, setImages] = useState("");

  function handleImageClick(e) {
    imageRef.current.click();
  }
  function handleImageChange(e) {
    const file = e.target.files[0];
    setImages(file);
  }
  const handlePost = async (e) => {
    const formData = new FormData();
    for (const key in values) {
      if (key === "seo_image") {
        continue;
      } else if (key === "meta_desc") {
        formData.append("meta_desc", editorValue);
      } else formData.append(key, values[key]);
    }
    if (!edit) {
      if (InitialState.seo_image instanceof File) {
        formData.append("seo_image", InitialState.seo_image);
      } else {
        formData.append("seo_image", InitialState.seo_image);
      }
    } else {
      if (edit && images instanceof File) {
        formData.append("seo_image", images);
      } else {
        formData.append("seo_image", InitialState.seo_image);
      }
    }
    const res = edit
      ? await PatchAxios(`/admin/defaultseo/${location.state._id}`, formData)
      : await PostAxios("/admin/defaultseo/", formData);

    if (res.success) {
      client.invalidateQueries("DefaultseoList");
      navigate("/admin/defaultseo");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
    } else {
      toast.error(res.message);
    }
  };
  const InitialState = {
    meta_title: edit ? location.state.meta_title : "",
    meta_desc: edit ? location.state.meta_desc : "",
    seo_image: edit ? location.state.seo_image : images,
  };
  const [editorValue, setEditorValue] = useState(
    edit ? InitialState.meta_desc : ""
  );

  function handleImageClick(e) {
    imageRef.current.click();
  }
  function handleImageChange(e) {
    const file = e.target.files[0];
    setImages(file);
  }
  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: DefaultSeoSchema,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <h2 className="text-center text-muted mb-2">
          {edit ? "Update" : "Add"} Default Seo
        </h2>
        <div className="image_holder">
          {!images ? (
            <img
              src={
                edit && InitialState.seo_image
                  ? `${baseURL}/${InitialState.seo_image}`
                  : "https://media.istockphoto.com/id/1248723171/nl/vector/camera-pictogram-van-het-foto-uploaden-op-ge%C3%AFsoleerde-witte-achtergrond-eps-10-vector.jpg?s=612x612&w=0&k=20&c=WLTMjPeoFbET0aBnnJmLyzDE1wUkJu9nj07KPtmf8qg="
              }
              className="rounded-circle"
              alt="Cinque Terre"
              height={200}
              width={200}
              onClick={handleImageClick}
            />
          ) : (
            <img
              src={URL.createObjectURL(images)}
              alt="bannerInput"
              className="rounded-circle"
              height={200}
              width={200}
              onClick={handleImageClick}
            />
          )}
          <input
            type="file"
            ref={imageRef}
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
        <Row className="m-2 p-2">
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="meta_title">
                Meta Title <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                //id="meta_title"
                name="meta_title"
                value={values.meta_title}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.meta_title && touched.meta_title ? (
                <p className="text-danger">{errors.meta_title}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="meta_desc">Meta Desc</Form.Label>
              <Editor values={editorValue} onValueChange={setEditorValue} />
              {errors.meta_desc && touched.meta_desc ? (
                <p className="text-danger">{errors.meta_desc}</p>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <div className="mt-4 d-flex justify-content-between">
          <Button
            type="submit"
            className={`btn btn-primary  `}
            onClick={handleSubmit}
            disabled={!isValid || isSubmitting}
          >
            {edit
              ? isSubmitting
                ? "Updating...."
                : "Update"
              : isSubmitting
              ? "Submitting...."
              : "Submit"}
          </Button>
          <Button
            type="button"
            className="btn btn-danger "
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
};

export default DefaultSeoForm;
