import React, { useState, useRef, useEffect } from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { LanguageSchema } from "./Schemas";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios } from "../../../helper/AxiosHelper";

const LanguageForm = ({ edit }) => {
  const baseURL = process.env.REACT_APP_FILE_URL;
  const location = useLocation();
  const client = useQueryClient();
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const [images, setImages] = useState("");
  function handleImageClick(e) {
    imageRef.current.click();
  }
  function handleImageChange(e) {
    const file = e.target.files[0];
    setImages(file);
  }
  const InitialState = {
    title: edit ? location.state.title : "",
    symbol: edit ? location.state.symbol : "",
    image: edit ? location.state.image : images,
  };

  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: LanguageSchema,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });
  const handlePost = async (e) => {
    const formData = new FormData();
    for (const key in values) {
      if (key === "image") {
        continue;
      }
      formData.append(key, values[key]);
    }
    if (!edit) {
      if (InitialState.image instanceof File) {
        formData.append("image", InitialState.image);
      } else {
        formData.append("image", InitialState.image);
      }
    } else {
      if (edit && images instanceof File) {
        formData.append("image", images);
      } else {
        formData.append("image", InitialState.image);
      }
    }

    const res = edit
      ? await PatchAxios(`/admin/language/${location.state._id}`, formData)
      : await PostAxios("/admin/language", formData);

    if (res.success) {
      navigate("/admin/language");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
      return true;
    } else {
      toast.error(res.message);
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="m-2 p-2">
          <h2 className="text-center text-muted">Add Language</h2>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="name">
                Title
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  id="name"
                  placeholder="Title"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.title && touched.title ? (
                <p className="text-danger">{errors.title}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="contact_email">
                Symbol
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Symbol"
                  name="symbol"
                  value={values.symbol}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.symbol && touched.symbol ? (
                <p className="text-danger">{errors.symbol}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <div className="image_holder">
              {!images ? (
                <img
                  src={
                    edit && InitialState.image
                      ? `${baseURL}/${InitialState.image}`
                      : "https://media.istockphoto.com/id/1248723171/nl/vector/camera-pictogram-van-het-foto-uploaden-op-ge%C3%AFsoleerde-witte-achtergrond-eps-10-vector.jpg?s=612x612&w=0&k=20&c=WLTMjPeoFbET0aBnnJmLyzDE1wUkJu9nj07KPtmf8qg="
                  }
                  className="rounded-circle"
                  alt="Cinque Terre"
                  height={200}
                  width={200}
                  onClick={handleImageClick}
                />
              ) : (
                <img
                  src={URL.createObjectURL(images)}
                  alt="bannerInput"
                  className="rounded-circle"
                  height={200}
                  width={200}
                  onClick={handleImageClick}
                />
              )}
              <input
                type="file"
                ref={imageRef}
                accept="image/*"
                onChange={handleImageChange}
              />
              <h6 className="text-center">Insert Image</h6>
            </div>
          </Col>
          <div className="mt-4 d-flex justify-content-between">
            <Button
              type="submit"
              className={`btn btn-primary  `}
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting}
            >
              {edit
                ? isSubmitting
                  ? "Updating...."
                  : "Update"
                : isSubmitting
                  ? "Submitting...."
                  : "Submit"}
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default LanguageForm;
