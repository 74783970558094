import React, { useState } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import "./Form.css";
import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { BlogSchema } from "./Schemas";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios } from "../../../helper/AxiosHelper";
import Editor from "../GeneralUI/Editor/Editor";
const BlogForm = ({ edit }) => {
  const baseURL = process.env.REACT_APP_FILE_URL;
  const location = useLocation();
  const client = useQueryClient();
  const navigate = useNavigate();

  const initialSeoImg = edit ? location.state.seo_image : "";
  const initialFeaturedImg = edit ? location.state.featured_image : "";
  const [seo_images, setSEO_Images] = useState(initialSeoImg);
  const [featured_images, setFeatured_Images] = useState(initialFeaturedImg);

  function handleSEOImageChange(e) {
    const file = e.target.files[0];
    setSEO_Images(file);
  }
  function handleFeaturedImageChange(e) {
    const file = e.target.files[0];
    setFeatured_Images(file);
  }
  const handlePost = async (e) => {
    const formData = new FormData();

    for (const key in values) {
      console.log(key);
      if (key === "seo_image" || key === "featured_image") {
        continue;
      } else {
        formData.append(key, values[key]);
      }
    }
    if (!edit) {
      if (InitialState.seo_image instanceof File) {
        formData.append("seo_image", InitialState.seo_image);
      } else {
        formData.append("seo_image", InitialState.seo_image);
      }
      if (InitialState.featured_image instanceof File) {
        formData.append("featured_image", InitialState.featured_image);
      } else {
        formData.append("featured_image", InitialState.featured_image);
      }
    } else {
      if (edit && seo_images instanceof File) {
        formData.append("seo_image", seo_images);
      } else {
        formData.append("seo_image", InitialState.seo_image);
      }
      if (edit && featured_images instanceof File) {
        formData.append("featured_image", featured_images);
      } else {
        formData.append("featured_image", InitialState.featured_image);
      }
    }
    const res = edit
      ? await PatchAxios(`/admin/blog/${location.state._id}`, formData)
      : await PostAxios("/admin/blog/", formData);

    if (res.success) {
      client.refetchQueries("BlogList");
      navigate("/admin/blog");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
    } else {
      toast.error(res.message);
    }
  };

  const InitialState = {
    title: edit ? location.state.title : "",
    meta_title: edit ? location.state.meta_title : "",
    status: edit ? location.state.status : "",
    meta_desc: edit ? location.state.meta_desc : "",
    short_desc: edit ? location.state.short_desc : "",
    long_desc: edit ? location.state.long_desc : "",
    seo_image: edit ? location.state.seo_image : seo_images,
    featured_image: edit ? location.state.featured_image : featured_images,
    featured_image_status: edit ? location.state.featured_image_status : "",
    yt_vide_link: edit ? location.state.yt_vide_link : "",
    yt_video_status: edit ? location.state.yt_video_status : "",
  };

  function handleSEOImageChange(e) {
    const file = e.target.files[0];
    setSEO_Images(file);
  }
  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    setFieldValue,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: BlogSchema,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });
  const [editorValue, setEditorValue] = useState(
    edit ? InitialState.description : ""
  );

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <h2 className="text-center text-muted mb-2">
          {" "}
          {edit ? "Update" : "Add"} Blog
        </h2>
        <Row className="m-2 p-2">
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="title">
                Title<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.title && errors.title && (
                <p className="text-danger">{errors.title}</p>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="featured_image_status">Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={values.status}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="inactive">Inactive</option>
                <option value="active">Active</option>
              </Form.Control>
              {errors.status && touched.status ? (
                <p className="text-danger">{errors.status}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="meta_title">
                Meta Title <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="meta_title"
                value={values.meta_title}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.meta_title && errors.meta_title && (
                <p className="text-danger">{errors.meta_title}</p>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="meta_desc">
                Meta Description<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="meta_desc"
                value={values.meta_desc}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.meta_desc && errors.meta_desc && (
                <p className="text-danger">{errors.meta_desc}</p>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="short_desc">
                Short Description<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="short_desc"
                value={values.short_desc}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.short_desc && errors.short_desc && (
                <p className="text-danger">{errors.short_desc}</p>
              )}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="seo_image">SEO Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                name="seo_image"
                onChange={handleSEOImageChange}
                onBlur={handleBlur}
              />
              {InitialState.seo_image && (
                <p className="text-success">Image Received</p>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="featured_image">Featured Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                name="featured_image"
                onChange={handleFeaturedImageChange}
                onBlur={handleBlur}
              />
              {InitialState.featured_image && (
                <>
                  <p className="text-success">Image Received</p>
                  <p className="text-info">
                    Add a new image if you wish to change
                  </p>
                </>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="featured_image_status">
                Featured Image Status
              </Form.Label>
              <Form.Control
                as="select"
                name="featured_image_status"
                value={values.featured_image_status}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="inactive">Inactive</option>
                <option value="active">Active</option>
              </Form.Control>
              {errors.featured_image_status && touched.featured_image_status ? (
                <p className="text-danger">{errors.featured_image_status}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="yt_vide_link">YouTube Video Link</Form.Label>
              <Form.Control
                type="text"
                name="yt_vide_link"
                value={values.yt_vide_link}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
            {errors.yt_vide_link && touched.yt_vide_link ? (
              <p className="text-danger">{errors.yt_vide_link}</p>
            ) : null}
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="yt_video_status">
                YouTube Video Status
              </Form.Label>
              <Form.Control
                as="select"
                name="yt_video_status"
                value={values.yt_video_status}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="inactive">Inactive</option>
                <option value="active">Active</option>
              </Form.Control>
              {errors.yt_video_status && touched.yt_video_status ? (
                <p className="text-danger">{errors.yt_video_status}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Label htmlFor="long desc">Description</Form.Label>
            <Editor
              values={values.long_desc}
              onValueChange={(value) => setFieldValue("long_desc", value)}
            />
          </Col>
        </Row>
        <div className="mt-4 d-flex justify-content-between">
          <Button
            type="submit"
            className={`btn btn-primary  `}
            onClick={handleSubmit}
            disabled={!isValid || isSubmitting}
          >
            {edit
              ? isSubmitting
                ? "Updating...."
                : "Update"
              : isSubmitting
              ? "Submitting...."
              : "Submit"}
          </Button>
          <Button
            type="button"
            className="btn btn-danger "
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
};

export default BlogForm;
