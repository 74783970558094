import React, { useState } from "react";
import PageData from "../../PageData/PageData";
import { getAxios, delAxios, PatchAxios } from "../../../../helper/AxiosHelper";
import ViewModel from "./ViewModel";
import { useNavigate } from "react-router";
import { useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";

const Employee = () => {
  const [show, setShow] = useState(false);
  const [Content, setContent] = useState({});
  const navigate = useNavigate();
  const client = useQueryClient();
  const handleView = async (row) => {
    const url = `/admin/employee/${row._id}/data`;
    try {
      const response = await getAxios(url);
      const fetchData = response.data.employee;
      toast.success(response.data.msg);
      const fullName = row.first_name + " " + row.last_name;
      const prev = { email: row.email, name: fullName, phone: row.phone };
      const data = { ...fetchData, ...prev };

      setContent(data);
    } catch (error) {
      console.log(error);
    }
    setShow(true);
  };
  const CloseModal = () => {
    setShow(false);
  };
  const handleEdit = (rowData) => {
    navigate(`/admin/employee/edit/${rowData._id}`, { state: rowData });
  };
  const handleDelete = async (uid) => {
    const res = await delAxios(`/admin/employee/${uid}`);
    client.refetchQueries("EmployeeList");
    if (res.success) {
      toast.success("Employee Deleted Successfully");
    } else {
      toast.error(res.message);
    }
  };
  const query = useQueryClient();
  const handleToggle = async (row) => {
    const res = await PatchAxios(
      `/admin/employee/${row._id}/status?stats=${
        row.status === "active" ? "inactive" : "active"
      }`
    );
    query.refetchQueries("EmployeeList");

    if (res.success) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };
  const employeeColumns = [
    {
      name: "Full Name",
      selector: (row) => row.first_name + " " + row.last_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },

    // {
    //   name: "Tag",
    //   selector: (row) => {
    //     console.log(row);
    //     if (row.tag && Array.isArray(row.tag)) {
    //       const titles = row.tag.map((tagObject) => tagObject.title);
    //       const concatenatedTitles = titles.join(", ");
    //       return concatenatedTitles;
    //     }
    //   },
    //   sortable: true,
    // },
    {
      name: "Status",
      selector: (row) => {
        if (row.status === "active") {
          return <p className="text-success"> Active</p>;
        } else {
          return <p className="text-danger"> Inactive</p>;
        }
      },
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <div>
          <button
            className="btn btn-success m-1"
            onClick={() => handleToggle(row)}
          >
            {row.status === "active" ? "Hide" : "Show"}
          </button>
          <button
            className="btn btn-primary m-1"
            onClick={() => handleEdit(row)}
          >
            Edit
          </button>
          <button
            className="btn btn-danger m-1"
            onClick={() => handleDelete(row._id)}
          >
            Delete
          </button>
          <button className="btn btn-info m-1" onClick={() => handleView(row)}>
            View
          </button>
        </div>
      ),
    },
  ];

  async function getField() {
    const url = `/admin/employee`;
    try {
      const response = await getAxios(url);
      const fetchedList = response.data.employee;
      return fetchedList;
    } catch (error) {
      console.log(error);
    }
  }
  const { data, error, isLoading } = useQuery("EmployeeList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }

  return !show ? (
    <PageData
      tableData={data}
      columns={employeeColumns}
      title="Employee"
      addRequired={false}
    />
  ) : (
    <ViewModel
      show={show}
      handleClose={CloseModal}
      isDetail
      Content={Content}
    />
  );
};

export default Employee;
