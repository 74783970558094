import React from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { ExperienceSchema } from "./Schemas";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios } from "../../../helper/AxiosHelper";


const ExperienceForm = ({ edit }) => {
  const location = useLocation();
  const client = useQueryClient();
  const navigate = useNavigate();
  const initialExperience = {
    position: edit ? location.state.position : "",
    company: edit ? location.state.company : "",
    from: edit ? location.state.from : "active",
    to: edit ? location.state.to : "",
  };
  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: initialExperience,
    validationSchema: ExperienceSchema,
    onSubmit: async (action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });
  const handlePost = async (e) => {
    const res = edit
      ? await PatchAxios(`/employee/experience/${location.state._id}`, values)
      : await PostAxios("/employee/experience", values);

    if (res.success) {
      navigate("/admin/experience");
      client.invalidateQueries("ExperienceList");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
      return true;
    } else {
      toast.error(res.error);
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <h2 className="text-center text-muted mb-2">Add Experience</h2>
        <Row className="m-2 p-2">
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="name">
                Position
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  id="name"
                  placeholder="Position"
                  name="position"
                  value={values.position}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.position && touched.position ? (
                <p className="text-danger">{errors.position}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="slug">
                Company<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="company"
                value={values.company}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              {errors.company && touched.company ? (
                <p className="text-danger">{errors.company}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="From">
                Start Date <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                name="from"
                value={values.from}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              {errors.from && touched.from ? (
                <p className="text-danger">{errors.from}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="From">End Date</Form.Label>
              <Form.Control
                type="date"
                name="to"
                value={values.to}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.to && touched.to ? (
                <p className="text-danger">{errors.to}</p>
              ) : null}
            </Form.Group>
          </Col>
          <div className="mt-4 d-flex justify-content-between">
            <Button
              type="submit"
              className={`btn btn-primary  `}
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting}
            >
              {edit
                ? isSubmitting
                  ? "Updating...."
                  : "Update"
                : isSubmitting
                  ? "Submitting...."
                  : "Submit"}
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default ExperienceForm;
