import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { getCMS, getUserAxios } from "../../../helper/UserAxios";

const Journey = () => {
  const fileURL = process.env.REACT_APP_FILE_URL;
  const [journey, setJourney] = useState([]);
  // const [download,setDownload]=useState();
  useEffect(() => {
    getFunc();
  }, []);
  const getFunc = async () => {
    const res = await getCMS("page_id=journey");
    setJourney(res.data[0]);
  };
  const navigate = useNavigate();
  const token = Cookies.get("employeetoken");
  const nexToken = Cookies.get("usertoken");

  const handleJourneyDownload = async () => {
    if (!token && !nexToken) {
      toast.error("Please Login First");
      navigate("/employee/login");
      return false;
    } else {
      const res = await getUserAxios(`/sysinfofile?config_name=journey`)
      const link = document.createElement("a");
      link.href = fileURL + '/' + res.data.system_info_file[0].feature_file;
      document.body.appendChild(link);
      link.target = "_blank";
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <section className="journey p-tb-60">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="journey-content">
              <h2 className="m-b-20">{journey.title}</h2>
              <p>{journey.meta_desc}</p>
              <button
                className="btn btn--accent m-t-20"
                onClick={handleJourneyDownload}
              >
                Download
              </button>
            </div>
          </div>
          <div className="col-md-5">
            <div className="journey-img">
              <img
                src={fileURL + "/" + journey.seo_image}
                alt={journey.title}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Journey;
