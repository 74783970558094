import * as Yup from "yup";

const UserSchema = Yup.object().shape({
  name: Yup.string().required("Please enter your name"),
  email: Yup.string()
    .required("Please enter your email")
    .email("Please enter a valid email")
    .strict()
    .trim(),
  phone: Yup.string()
    .required("Please enter your phone number")
    .strict()
    .trim()
    .length(10),
  password: Yup.string().min(6),
  re_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
  status: Yup.string().oneOf(["active", "inactive"]).default("active"),
  role_id: Yup.string().required("Please select a role"),
  address: Yup.string(),
  image: Yup.string(),
});

export default UserSchema;
