import React, { useState, useEffect } from "react";

import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getUserAxios, LogoutUser } from "../../../../helper/UserAxios";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ActionButton from "../GeneralUI/ActionButton";
import UserPageData from "../../GeneralUI/UserPageData/UserPageData";

const EmployerDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.emp.emp_info.data);

  const { data: vacancyUser } = useQuery("EmployerJobList", getVacancyField);

  async function getVacancyField() {
    const url = `/employer/vacancy`;
    try {
      const response = await getUserAxios(url);
      return response.data.vacancy;
    } catch (error) {
      console.log(error);
    }
  }

  const vacancyUserColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    // {
    //   name: "Employer",
    //   selector: (row) => row.employer.contact_name,
    //   sortable: true,
    // },
    {
      name: "Tag",
      selector: (row) => {
        if (row.tag && Array.isArray(row.tag)) {
          const titles = row.tag.map((tagObject) => tagObject.title);
          const concatenatedTitles = titles.join(", ");
          return concatenatedTitles;
        }
      },
    },
    {
      name: "Action",
      cell: (row) => {
        return <ActionButton row={row} noApply employer={true} />;
      },
    },
  ];

  const handleEdit = (what) => {
    navigate("/employer/profile");
  };
  return (
    <div className="main-content-inner">
      <div className="dashboard__profile card profile-card">
        <div className="card-body profile-card__body">
          {/* <div className="profile-card__avatar">
            <img src="dist/images/avatar-3.jpg" alt="" />
          </div> */}
          <div className="profile-card__name">
            {data.company_name}
            <p>
              Contact Person : {data.signing_officer_first_name}{" "}
              {data.signing_officer_last_name}
            </p>
          </div>
          <div className="profile-card__email">{data.email}</div>
          <div className="profile-card__edit">
            <button
              className="btn btn-secondary btn-sm"
              onClick={() => handleEdit("profile")}
            >
              Edit Profile
            </button>
          </div>
        </div>
      </div>

      <div className="dashboard-table table-responsive m-t-40">
        <h2 className="text-center">Vacancy Announced</h2>

        <UserPageData
          columns={vacancyUserColumns}
          data={vacancyUser && vacancyUser.slice(0, 5)}
          customeHeader
          employer={true}
        />
      </div>
    </div>
  );
};

export default EmployerDashboard;
