import React, { useRef, useState, useEffect } from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import {
  PassPortFrontImage,
  UploadImage,
  PdfIcon,
} from "../../../assets/images/";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import EmployeeSchema, { DetailEditSchema } from "./Schema/EmployeeSchemas";
import { toast } from "react-toastify";
import {
  PatchUserAxios,
  PostUserAxios,
  RegisterAxios,
  getUserAxios,
} from "../../../../helper/UserAxios";
import PhoneInput from "react-phone-input-2";

const Step5 = ({ edit, editData, back, initialState }) => {
  const fileURL = process.env.REACT_APP_FILE_URL;
  const [ispdf, setIsPdf] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const imageRef = useRef(null);
  // const photoRef = useRef(null);
  const [PPFront, setPPFront] = useState(
    edit ? fileURL + editData.passport_front : null
  );
  // const [PPBack, setPPBack] = useState(edit ? fileURL + editData.image : null);
  const [country, setCountry] = useState([]);
  const [displayPPonChange, setDisplayPPonChange] = useState();
  const getCountry = async (e) => {
    const res = await getUserAxios("/country");
    setCountry(res.data.country);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  function handlePPFront(e) {
    const file = e.target.files[0];
    const fileType = file.name.split(".").pop().toUpperCase();
    if (fileType === "PDF") {
      setIsPdf(true);
    } else {
      setIsPdf(false);
    }
    setPPFront(file);

    setDisplayPPonChange(URL.createObjectURL(file));
  }
  // function handlePPBack(e) {
  //   const file = e.target.files[0];
  //   setPPBack(file);
  // }
  function handleImageClick(e) {
    imageRef.current.click();
  }
  // function handlePPClick(e) {
  //   photoRef.current.click();
  // }

  const navigate = useNavigate();
  const workInitialState = {
    work_employer: "",
    work_mailing_address: "",
    work_city: "",
    work_state_provience: "",
    work_zip_code: "",
    work_country: "",
    work_contact_number: "",
    work_starting_date: "",
    work_ended: "",
    work_ending_date: "",
  };

  const [workExperiences, setWorkExperiences] = useState([workInitialState]);

  const handleAddWork = () => {
    setWorkExperiences((prevExperiences) => [
      ...prevExperiences,
      { ...workInitialState },
    ]);
  };

  const handleWorkChange = (event, index) => {
    const { name, value } = event.target;

    setWorkExperiences((prevExperiences) => {
      const newExperiences = [...prevExperiences];
      newExperiences[index] = {
        ...newExperiences[index],
        [name]: value,
      };
      return newExperiences;
    });
  };

  let InitialState = {
    // status: edit ? me.status : "",
    dob: edit ? editData.dob : "",
    country: edit ? editData.country : "",
    current_country: edit ? editData.current_country : "",
    current_street: edit ? editData.current_street : "",
    current_city_town: edit ? editData.current_city_town : "",
    current_provience_state: edit ? editData.current_provience_state : "",
    current_zipcode: edit ? editData.current_zipcode : "",
    //permanent
    permanent_country: edit ? editData.permanent_country : " ",
    // permanent_address: edit ? editData.current_address : "",
    permanent_street: edit ? editData.current_street : "",
    permanent_city_town: edit ? editData.current_city_town : "",
    permanent_province_state: edit ? editData.current_province_state : "",
    permanent_zipcode: edit ? editData.permanent_zipcode : " ",
    //
    special_accomodation: edit ? editData.special_accomodation : "",
    gender: edit ? editData.gender : "",

    // physical_limitation: edit ? "" : "",
    highest_edu_degree: edit ? editData.highest_edu_degree : "",
    edu_year_of_completion: edit ? editData.edu_year_of_completion : "",
    edu_country: edit ? editData.edu_country : "",
    edu_major: edit ? editData.edu_major : "",
    edu_school_name: edit ? editData.edu_school_name : "",

    // education_detail: educationDetail,

    //work

    // work_detail: JSON.stringify(workExperiences),
    has_work_experience: edit ? editData.has_work_experience : "",
    //
    pending_us_asylum: edit ? editData.pending_us_asylum : "",
    denied_to_enter_us: edit ? editData.denied_to_enter_us : " ",
    deported_removed_from_us: edit ? editData.deported_removed_from_us : "",
    passport_front: PPFront,
    same_address: edit ? editData.same_address : " ",
    pine_visa_cost: true,
    obligations: true,
    work_history_disclaimer: true,
    used_in_immigration_fillings: true,
    terms_and_conditions: true,
    ...(edit
      ? {}
      : {
        first_name: initialState.first_name,
        last_name: initialState.last_name,
        email: initialState.email,
        phone: initialState.phone,
        password: initialState.password,
        re_password: initialState.re_password,
      }),
  };
  const handlePost = async (e) => {
    try {
      const formData = new FormData();
      for (const key in values) {
        if (key !== "passport_front" && key !== "work_detail") {
          formData.append(key, values[key]);
        }
      }
      formData.append("passport_front", InitialState.passport_front);

      const res = !edit
        ? await RegisterAxios("/auth/employee/signup", formData)
        : await PatchUserAxios("/employee/info", formData);

      // Handle response
      if (res.success) {
        toast.success(res.message);

        // for (const work of workExperiences) {
          
          const res1 = await PostUserAxios("/employee/experience", workExperiences);

          if (res1.success) {
            toast.success(res1.message)
          } else {
            toast.error(res1.message);
          }
        }
        navigate("/employee/dashboard");
        toast.success("Welcome");
      // } else {
        // toast.error(res.message);
      // }
    } catch (error) {
      // Handle any unexpected errors
      console.error("Error:", error);
      toast.error("An error occurred while processing the request.");
    }
  };

  function handleImageClick(e) {
    imageRef.current.click();
  }

  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    setFieldValue,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: edit ? DetailEditSchema : EmployeeSchema,
    onSubmit: async (values, action) => {
      const res = await handlePost();

      if (res) {
        action.resetForm();
      }
    },
  });
  useEffect(() => {
    getCountry();
  }, []);
  console.log(errors);

  return (
    <main className="body-wrapper  bg--1 p-tb-60">
      <div className="pine-cost-page" bis_skin_checked="1">
        <div className="container" bis_skin_checked="1">
          <div className="pine-cost" bis_skin_checked="1">
            <Form
              className="data-collection-form module"
              onSubmit={handleSubmit}
            >
              <Row className="m-2 p-2">
                {!edit && (
                  <Col md={6}>
                    <div className="form-group">
                      <label htmlFor="name">
                        First Name
                        <span className="text-danger">*</span>
                      </label>
                      <InputGroup className={`text-dark bg-light`}>
                        <input
                          required
                          type="text"
                          className={`form-control ${touched.first_name && errors.first_name
                              ? "is-invalid"
                              : ""
                            }`}
                          id="name"
                          placeholder="First Name"
                          name="first_name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </InputGroup>
                      {touched.first_name && errors.first_name && (
                        <div className="text-danger">{errors.first_name}</div>
                      )}
                    </div>
                  </Col>
                )}
                {!edit && (
                  <Col md={6}>
                    <div className="form-group">
                      <label htmlFor="name">
                        Last Name
                        <span className="text-danger">*</span>
                      </label>
                      <InputGroup
                        className={`text-dark bg-light ${touched.last_name && errors.last_name
                            ? "is-invalid"
                            : ""
                          }`}
                      >
                        <input
                          required
                          type="text"
                          className={`form-control ${touched.last_name && errors.last_name
                              ? "is-invalid"
                              : ""
                            }`}
                          id="name"
                          placeholder="Last Name"
                          name="last_name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </InputGroup>
                      {touched.last_name && errors.last_name && (
                        <div className="text-danger">{errors.last_name}</div>
                      )}
                    </div>
                  </Col>
                )}
                {!edit && (
                  <div className="col-md-6">
                    <div
                      className={`form-group ${errors.email && touched.email ? "has-error" : ""
                        }`}
                    >
                      <label htmlFor="email">
                        Email address
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className={`form-control ${errors.email && touched.email ? "is-invalid" : ""
                          }`}
                        id="email"
                        name="email"
                        placeholder="Email Address"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {errors.email && touched.email ? (
                        <p className="text-danger">{errors.email}</p>
                      ) : null}
                    </div>
                  </div>
                )}
                {!edit && (
                  <Col md={6}>
                    <div className="form-group">
                      <label htmlFor="phone">
                        Your primary phone number
                        <span className="text-danger">*</span>
                      </label>
                      <div
                        className={`form-group ${errors.phone && touched.phone ? "has-error" : ""
                          }`}
                      >
                        <PhoneInput
                          containerStyle={{
                            width: "-webkit-fill-available",
                          }}
                          containerClass="phoneinputspecialnmi"
                          enableSearch={true}
                          value={values.phone}
                          onChange={(phone) => setFieldValue("phone", phone)}
                          onBlur={handleBlur("phone")}
                        />

                        {errors.phone && touched.phone ? (
                          <p className="text-danger">{errors.phone}</p>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                )}
                <Col md={6}>
                  <div className="form-group">
                    <label htmlFor="dob">
                      Date of Birth
                      <span className="text-danger">*</span>
                    </label>
                    <InputGroup
                      className={`text-dark bg-light ${touched.dob && errors.dob ? "is-invalid" : ""
                        }`}
                    >
                      <input
                        type="date"
                        className={`form-control ${touched.dob && errors.dob ? "is-invalid" : ""
                          }`}
                        id="dob"
                        name="dob"
                        value={values.dob}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        max={new Date().toISOString().split("T")[0]}
                      />
                    </InputGroup>
                    {touched.dob && errors.dob && (
                      <div className="text-danger">{errors.dob}</div>
                    )}
                  </div>
                </Col>

                <Col md={6}>
                  <div className="form-group">
                    <label htmlFor="country">
                      Gender
                      <span className="text-danger">*</span>
                    </label>

                    <select
                      className="form-select "
                      aria-label="Default select example"
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>

                    {touched.gender && errors.gender && (
                      <div className="text-danger">{errors.gender}</div>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label htmlFor="country">
                      Country of Birth
                      <span className="text-danger">*</span>
                    </label>

                    <select
                      className="form-select "
                      aria-label="Default select example"
                      id="country"
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    >
                      <option value=" ">Select One</option>
                      {country.map((ctry) => (
                        <option value={ctry.title}>{ctry.title}</option>
                      ))}
                    </select>

                    {touched.country && errors.country && (
                      <div className="text-danger">{errors.country}</div>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>
                      Upload your passport Front(Files supported: JPG, JPEG, PNG
                      & PDF)
                      <span className="text-danger">*</span>
                    </Form.Label>

                    <input
                      type="file"
                      ref={imageRef}
                      accept="image/jpeg,image/jpg,image/png,application/pdf"
                      onChange={handlePPFront}
                      name="passport_front"
                    />

                    <div className="upload-img">
                      {!PPFront ? (
                        <img
                          src={ispdf ? PdfIcon : PassPortFrontImage}
                          onClick={handleImageClick}
                        />
                      ) : !displayPPonChange ? (
                        <img
                          src={edit ? PPFront : URL.createObjectURL(PPFront)}
                          onClick={handleImageClick}
                        />
                      ) : (
                        <img
                          src={ispdf ? PdfIcon : displayPPonChange}
                          onClick={handleImageClick}
                        />
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <h4>Current Address Detail</h4>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>
                      Country
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup>
                      <Form.Select
                        aria-label="Default select example"
                        id="current_country"
                        name="current_country"
                        value={values.current_country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      >
                        <option value=" ">Select One</option>
                        {country.map((ctry) => (
                          <option value={ctry.title} key={ctry.title}>
                            {ctry.title}
                          </option>
                        ))}
                      </Form.Select>
                    </InputGroup>
                    {touched.current_country && errors.current_country && (
                      <div className="text-danger">
                        {errors.current_country}
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label htmlFor="current_street">
                      Current Street
                      <span className="text-danger">*</span>
                    </label>

                    <InputGroup
                      className={`text-dark bg-light ${touched.current_street && errors.current_street
                          ? "is-invalid"
                          : ""
                        }`}
                    >
                      <input
                        type="text"
                        className={`form-control ${touched.current_street && errors.current_street
                            ? "is-invalid"
                            : ""
                          }`}
                        id="current_street"
                        placeholder="Current Street"
                        name="current_street"
                        value={values.current_street}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {touched.current_street && errors.current_street && (
                      <div className="text-danger">{errors.current_street}</div>
                    )}
                  </div>
                </Col>

                <Col md={6}>
                  <div className="form-group">
                    <label htmlFor="current_city_town">
                      City or Town
                      <span className="text-danger">*</span>
                    </label>

                    <InputGroup
                      className={`text-dark bg-light ${touched.current_city_town && errors.current_city_town
                          ? "is-invalid"
                          : ""
                        }`}
                    >
                      <input
                        type="text"
                        className={`form-control ${touched.current_city_town && errors.current_city_town
                            ? "is-invalid"
                            : ""
                          }`}
                        id="current_city_town"
                        placeholder="Current City or Town"
                        name="current_city_town"
                        value={values.current_city_town}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {touched.current_city_town && errors.current_city_town && (
                      <div className="text-danger">
                        {errors.current_city_town}
                      </div>
                    )}
                  </div>
                </Col>

                <Col md={6}>
                  <div className="form-group">
                    <label htmlFor="current_provience_state">
                      Province, State or Country
                      <span className="text-danger">*</span>
                    </label>

                    <InputGroup
                      className={`text-dark bg-light ${touched.current_provience_state &&
                          errors.current_provience_state
                          ? "is-invalid"
                          : ""
                        }`}
                    >
                      <input
                        type="text"
                        className={`form-control ${touched.current_provience_state &&
                            errors.current_provience_state
                            ? "is-invalid"
                            : ""
                          }`}
                        id="current_provience_state"
                        placeholder="Current Province, State, or Country"
                        name="current_provience_state"
                        value={values.current_provience_state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {touched.current_provience_state &&
                      errors.current_provience_state && (
                        <div className="text-danger">
                          {errors.current_provience_state}
                        </div>
                      )}
                  </div>
                </Col>

                <Col md={6}>
                  <div className="form-group">
                    <label htmlFor="zipcode">
                      Zip Code
                      <span className="text-danger">*</span>
                    </label>

                    <InputGroup
                      className={`text-dark bg-light ${touched.current_zipcode && errors.current_zipcode
                          ? "is-invalid"
                          : ""
                        }`}
                    >
                      <input
                        type="text"
                        className={`form-control ${touched.current_zipcode && errors.current_zipcode
                            ? "is-invalid"
                            : ""
                          }`}
                        id="zip_code"
                        placeholder="Zip Code"
                        name="current_zipcode"
                        value={values.current_zipcode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {touched.current_zipcode && errors.current_zipcode && (
                      <div className="text-danger">
                        {errors.current_zipcode}
                      </div>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label htmlFor="is_mailing_same_as_physical">
                      Is Permanent Address the same as your Current Address?
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Select
                        id="is_mailing_same_as_physical"
                        name="same_address"
                        value={values.same_address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select One</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Form.Select>
                    </InputGroup>
                    {errors.same_address && touched.same_address ? (
                      <p className="text-danger">{errors.same_address}</p>
                    ) : null}
                  </Form.Group>
                </Col>

                {values.same_address !== "yes" && (
                  <>
                    <h4>Fill your Permanent Address Detail</h4>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>
                          Country
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <InputGroup>
                          <Form.Select
                            aria-label="Default select example"
                            id="permanent_country"
                            name="permanent_country"
                            value={values.permanent_country}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          >
                            <option value=" ">Select One</option>
                            {country.map((ctry) => (
                              <option value={ctry.title} key={ctry.title}>
                                {ctry.title}
                              </option>
                            ))}
                          </Form.Select>
                        </InputGroup>
                        {touched.permanent_country &&
                          errors.permanent_country && (
                            <div className="text-danger">
                              {errors.permanent_country}
                            </div>
                          )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <div className="form-group">
                        <label htmlFor="permanent_street">
                          Street
                          <span className="text-danger">*</span>
                        </label>
                        <InputGroup
                          className={`text-dark bg-light ${touched.permanent_street && errors.permanent_street
                              ? "is-invalid"
                              : ""
                            }`}
                        >
                          <input
                            type="text"
                            className={`form-control ${touched.permanent_street &&
                                errors.permanent_street
                                ? "is-invalid"
                                : ""
                              }`}
                            id="permanent_street"
                            placeholder="Permanent Street"
                            name="permanent_street"
                            value={values.permanent_street}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </InputGroup>
                        {touched.permanent_street &&
                          errors.permanent_street && (
                            <div className="text-danger">
                              {errors.permanent_street}
                            </div>
                          )}
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="form-group">
                        <label htmlFor="permanent_city_town">
                          City or Town
                          <span className="text-danger">*</span>
                        </label>

                        <InputGroup
                          className={`text-dark bg-light ${touched.permanent_city_town &&
                              errors.permanent_city_town
                              ? "is-invalid"
                              : ""
                            }`}
                        >
                          <input
                            type="text"
                            className={`form-control ${touched.permanent_city_town &&
                                errors.permanent_city_town
                                ? "is-invalid"
                                : ""
                              }`}
                            id="permanent_city_town"
                            placeholder="Permanent City or Town"
                            name="permanent_city_town"
                            value={values.permanent_city_town}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </InputGroup>
                        {touched.permanent_city_town &&
                          errors.permanent_city_town && (
                            <div className="text-danger">
                              {errors.permanent_city_town}
                            </div>
                          )}
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="form-group">
                        <label htmlFor="permanent_province_state">
                          Province, State or Country
                          <span className="text-danger">*</span>
                        </label>

                        <InputGroup
                          className={`text-dark bg-light ${touched.permanent_province_state &&
                              errors.permanent_province_state
                              ? "is-invalid"
                              : ""
                            }`}
                        >
                          <input
                            type="text"
                            className={`form-control ${touched.permanent_province_state &&
                                errors.permanent_province_state
                                ? "is-invalid"
                                : ""
                              }`}
                            id="permanent_province_state"
                            placeholder="Permanent Province, State, or Country"
                            name="permanent_province_state"
                            value={values.permanent_province_state}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </InputGroup>
                        {touched.permanent_province_state &&
                          errors.permanent_province_state && (
                            <div className="text-danger">
                              {errors.permanent_province_state}
                            </div>
                          )}
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="form-group">
                        <label htmlFor="permanent_zipcode">
                          Zip Code
                          <span className="text-danger">*</span>
                        </label>

                        <InputGroup
                          className={`text-dark bg-light ${touched.permanent_zipcode &&
                              errors.permanent_zipcode
                              ? "is-invalid"
                              : ""
                            }`}
                        >
                          <input
                            type="text"
                            className={`form-control ${touched.permanent_zipcode &&
                                errors.permanent_zipcode
                                ? "is-invalid"
                                : ""
                              }`}
                            id="permanent_zip_code"
                            placeholder="Permanent Zip Code"
                            name="permanent_zipcode"
                            value={values.permanent_zipcode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </InputGroup>
                        {touched.permanent_zipcode &&
                          errors.permanent_zipcode && (
                            <div className="text-danger">
                              {errors.permanent_zipcode}
                            </div>
                          )}
                      </div>
                    </Col>
                  </>
                )}
                <h4>Education Detail</h4>
                <Row>
                  <Col md={6}>
                    <div className="form-group">
                      <label htmlFor="deported_removed_from_us">
                        Select your Highest Educational Degree
                        <span className="text-danger">*</span>
                      </label>

                      <select
                        className="form-select"
                        name="highest_edu_degree"
                        value={values.highest_edu_degree}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select One</option>
                        <option value="high_school">
                          Completed High School
                        </option>
                        <option value="bachelor_degree">
                          Bachelor's Degree
                        </option>
                        <option value="master_degree">Master's Degree</option>
                        <option value="PHD">PHD</option>
                      </select>
                    </div>
                    {touched.highest_edu_degree &&
                      errors.highest_edu_degree && (
                        <div className="text-danger">
                          {errors.highest_edu_degree}
                        </div>
                      )}
                  </Col>

                  <Row>
                    <>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>
                            Country
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <InputGroup>
                            <Form.Select
                              aria-label="Default select example"
                              id="edu_country"
                              name="edu_country"
                              value={values.edu_country}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                            >
                              <option value=" ">Select One</option>
                              {country.map((ctry) => (
                                <option value={ctry.title} key={ctry.title}>
                                  {ctry.title}
                                </option>
                              ))}
                            </Form.Select>
                          </InputGroup>
                          {touched.work_country && errors.work_country && (
                            <div className="text-danger">
                              {errors.work_country}
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <div className="form-group">
                          <label htmlFor="edu_year_of_completion">
                            Year of Completion
                            <span className="text-danger">*</span>
                          </label>
                          <InputGroup
                            className={`text-dark bg-light ${touched.edu_year_of_completion &&
                                errors.edu_year_of_completion
                                ? "is-invalid"
                                : ""
                              }`}
                          >
                            <input
                              type="text"
                              className={`form-control ${touched.edu_year_of_completion &&
                                  errors.edu_year_of_completion
                                  ? "is-invalid"
                                  : ""
                                }`}
                              id="edu_year_of_completion"
                              placeholder="Year of Completion"
                              name="edu_year_of_completion"
                              value={values.edu_year_of_completion}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </InputGroup>
                          {touched.edu_year_of_completion &&
                            errors.edu_year_of_completion && (
                              <div className="text-danger">
                                {errors.edu_year_of_completion}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <label htmlFor="edu_school_name">
                            School Name
                            <span className="text-danger">*</span>
                          </label>
                          <InputGroup
                            className={`text-dark bg-light ${touched.edu_school_name && errors.edu_school_name
                                ? "is-invalid"
                                : ""
                              }`}
                          >
                            <input
                              type="text"
                              className={`form-control ${touched.edu_school_name &&
                                  errors.edu_school_name
                                  ? "is-invalid"
                                  : ""
                                }`}
                              id="edu_school_name"
                              placeholder="School Name"
                              name="edu_school_name"
                              value={values.edu_school_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </InputGroup>
                          {touched.edu_school_name &&
                            errors.edu_school_name && (
                              <div className="text-danger">
                                {errors.edu_school_name}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <label htmlFor="edu_major">
                            Major
                            <span className="text-danger">*</span>
                          </label>
                          <InputGroup
                            className={`text-dark bg-light ${touched.edu_major && errors.edu_major
                                ? "is-invalid"
                                : ""
                              }`}
                          >
                            <input
                              type="text"
                              className={`form-control ${touched.edu_major && errors.edu_major
                                  ? "is-invalid"
                                  : ""
                                }`}
                              id="edu_major"
                              placeholder="Major"
                              name="edu_major"
                              value={values.edu_major}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </InputGroup>
                          {touched.edu_major && errors.edu_major && (
                            <div className="text-danger">
                              {errors.edu_major}
                            </div>
                          )}
                        </div>
                      </Col>
                    </>
                    <hr />
                  </Row>
                </Row>
                {!edit && <h4>Work Experience Detail</h4>}
                {!edit && (
                  <Row>
                    <Col md={6}>
                      <div className="form-group">
                        <label htmlFor="deported_removed_from_us">
                          Do You have any work Experience?
                          <span className="text-danger">*</span>
                        </label>

                        <select
                          className="form-select"
                          name="has_work_experience"
                          value={values.has_work_experience}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value=" ">Select One</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      {touched.has_work_experience &&
                        errors.has_work_experience && (
                          <div className="text-danger">
                            {errors.has_work_experience}
                          </div>
                        )}
                    </Col>
                    {values.has_work_experience == "true" && (
                      <>
                        {workExperiences.map((experience, index) => (
                          <Row key={index}>
                            <>
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label>
                                    Country
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <InputGroup>
                                    <Form.Select
                                      aria-label="Default select example"
                                      id="work_country"
                                      name="work_country"
                                      value={experience.work_country}
                                      onChange={(event) =>
                                        handleWorkChange(event, index)
                                      }
                                      onBlur={handleBlur}
                                      required
                                    >
                                      <option value=" ">Select One</option>
                                      {country.map((ctry) => (
                                        <option
                                          value={ctry.title}
                                          key={ctry.title}
                                        >
                                          {ctry.title}
                                        </option>
                                      ))}
                                    </Form.Select>
                                  </InputGroup>
                                  {touched.work_country &&
                                    errors.work_country && (
                                      <div className="text-danger">
                                        {errors.work_country}
                                      </div>
                                    )}
                                </Form.Group>
                              </Col>

                              <Col md={6}>
                                <div className="form-group">
                                  <label htmlFor="work_mailing_address">
                                    Mailing Address
                                    <span className="text-danger">*</span>
                                  </label>
                                  <InputGroup
                                    className={`text-dark bg-light ${touched.work_mailing_address &&
                                        errors.work_mailing_address
                                        ? "is-invalid"
                                        : ""
                                      }`}
                                  >
                                    <input
                                      type="text"
                                      className={`form-control ${touched.work_mailing_address &&
                                          errors.work_mailing_address
                                          ? "is-invalid"
                                          : ""
                                        }`}
                                      id="work_mailing_address"
                                      placeholder="Mailing Address"
                                      name="work_mailing_address"
                                      value={experience.work_mailing_address}
                                      onChange={(event) =>
                                        handleWorkChange(event, index)
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </InputGroup>
                                  {touched.work_mailing_address &&
                                    errors.work_mailing_address && (
                                      <div className="text-danger">
                                        {errors.work_mailing_address}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="form-group">
                                  <label htmlFor="employer">
                                    Employer
                                    <span className="text-danger">*</span>
                                  </label>
                                  <InputGroup
                                    className={`text-dark bg-light ${touched.employer && errors.employer
                                        ? "is-invalid"
                                        : ""
                                      }`}
                                  >
                                    <input
                                      type="text"
                                      className={`form-control ${touched.employer && errors.employer
                                          ? "is-invalid"
                                          : ""
                                        }`}
                                      id="employer"
                                      placeholder="Employer"
                                      name="employer"
                                      value={experience.employer}
                                      onChange={(event) =>
                                        handleWorkChange(event, index)
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </InputGroup>
                                  {touched.employer && errors.employer && (
                                    <div className="text-danger">
                                      {errors.employer}
                                    </div>
                                  )}
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="form-group">
                                  <label htmlFor="work_city">
                                    City
                                    <span className="text-danger">*</span>
                                  </label>
                                  <InputGroup
                                    className={`text-dark bg-light ${touched.work_city && errors.work_city
                                        ? "is-invalid"
                                        : ""
                                      }`}
                                  >
                                    <input
                                      type="text"
                                      className={`form-control ${touched.work_city && errors.work_city
                                          ? "is-invalid"
                                          : ""
                                        }`}
                                      id="work_city"
                                      placeholder="City"
                                      name="work_city"
                                      value={experience.work_city}
                                      onChange={(event) =>
                                        handleWorkChange(event, index)
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </InputGroup>
                                  {touched.work_city && errors.work_city && (
                                    <div className="text-danger">
                                      {errors.work_city}
                                    </div>
                                  )}
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="form-group">
                                  <label htmlFor="work_state_provience">
                                    State or Province
                                    <span className="text-danger">*</span>
                                  </label>
                                  <InputGroup
                                    className={`text-dark bg-light ${touched.work_state_provience &&
                                        errors.work_state_provience
                                        ? "is-invalid"
                                        : ""
                                      }`}
                                  >
                                    <input
                                      type="text"
                                      className={`form-control ${touched.work_state_provience &&
                                          errors.work_state_provience
                                          ? "is-invalid"
                                          : ""
                                        }`}
                                      id="work_state_provience"
                                      placeholder="State or Province"
                                      name="work_state_provience"
                                      value={experience.work_state_provience}
                                      onChange={(event) =>
                                        handleWorkChange(event, index)
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </InputGroup>
                                  {touched.work_state_provience &&
                                    errors.work_state_provience && (
                                      <div className="text-danger">
                                        {errors.work_state_provience}
                                      </div>
                                    )}
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="form-group">
                                  <label htmlFor="work_zip_code">
                                    Zip Code
                                    <span className="text-danger">*</span>
                                  </label>
                                  <InputGroup
                                    className={`text-dark bg-light ${touched.work_zip_code &&
                                        errors.work_zip_code
                                        ? "is-invalid"
                                        : ""
                                      }`}
                                  >
                                    <input
                                      type="text"
                                      className={`form-control ${touched.work_zip_code &&
                                          errors.work_zip_code
                                          ? "is-invalid"
                                          : ""
                                        }`}
                                      id="work_zip_code"
                                      placeholder="Zip Code"
                                      name="work_zip_code"
                                      value={experience.work_zip_code}
                                      onChange={(event) =>
                                        handleWorkChange(event, index)
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </InputGroup>
                                  {touched.work_zip_code &&
                                    errors.work_zip_code && (
                                      <div className="text-danger">
                                        {errors.work_zip_code}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="form-group">
                                  <label htmlFor="work_contact_number">
                                    Contact Number
                                    <span className="text-danger">*</span>
                                  </label>
                                  <InputGroup
                                    className={`text-dark bg-light ${touched.work_contact_number &&
                                        errors.work_contact_number
                                        ? "is-invalid"
                                        : ""
                                      }`}
                                  >
                                    <input
                                      type="text"
                                      className={`form-control ${touched.work_contact_number &&
                                          errors.work_contact_number
                                          ? "is-invalid"
                                          : ""
                                        }`}
                                      id="work_contact_number"
                                      placeholder="Contact Number"
                                      name="work_contact_number"
                                      value={experience.work_contact_number}
                                      onChange={(event) =>
                                        handleWorkChange(event, index)
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </InputGroup>
                                  {touched.work_contact_number &&
                                    errors.work_contact_number && (
                                      <div className="text-danger">
                                        {errors.work_contact_number}
                                      </div>
                                    )}
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="form-group">
                                  <label htmlFor="work_starting_date">
                                    Starting Date
                                    <span className="text-danger">*</span>
                                  </label>
                                  <InputGroup
                                    className={`text-dark bg-light ${touched.work_starting_date &&
                                        errors.work_starting_date
                                        ? "is-invalid"
                                        : ""
                                      }`}
                                  >
                                    <input
                                      type="date"
                                      className={`form-control ${touched.work_starting_date &&
                                          errors.work_starting_date
                                          ? "is-invalid"
                                          : ""
                                        }`}
                                      id="work_starting_date"
                                      name="work_starting_date"
                                      value={experience.work_starting_date}
                                      onChange={(event) =>
                                        handleWorkChange(event, index)
                                      }
                                      max={
                                        new Date().toISOString().split("T")[0]
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </InputGroup>
                                  {touched.work_starting_date &&
                                    errors.work_starting_date && (
                                      <div className="text-danger">
                                        {errors.work_starting_date}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="form-group">
                                  <label htmlFor="work_ended">
                                    Are you currently involved in this Job?
                                    <span className="text-danger">*</span>
                                  </label>

                                  <select
                                    className="form-select"
                                    id="work_ended"
                                    name="work_ended"
                                    value={experience.work_ended}
                                    onChange={(event)=>handleWorkChange(event,index)}
                                    onBlur={handleBlur}
                                  >
                                    <option value=" ">Select One</option>
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                  </select>
                                </div>
                                {touched.work_ended &&
                                  errors.work_ended && (
                                    <div className="text-danger">
                                      {errors.work_ended}
                                    </div>
                                  )}
                              </Col>
                              {experience.work_ended == 'false' && (<Col md={6}>
                                <div className="form-group">
                                  <label htmlFor="work_ending_date">
                                    Ending Date
                                    <span className="text-danger">*</span>
                                  </label>
                                  <InputGroup
                                    className={`text-dark bg-light ${touched.work_ending_date &&
                                        errors.work_ending_date
                                        ? "is-invalid"
                                        : ""
                                      }`}
                                  >
                                    <input
                                      type="date"
                                      className={`form-control ${touched.work_ending_date &&
                                          errors.work_ending_date
                                          ? "is-invalid"
                                          : ""
                                        }`}
                                      id="work_ending_date"
                                      name="work_ending_date"
                                      value={experience.work_ending_date}
                                      onChange={(event) =>
                                        handleWorkChange(event, index)
                                      }
                                      onBlur={handleBlur}
                                      max={
                                        new Date().toISOString().split("T")[0]
                                      }
                                    />
                                  </InputGroup>
                                  {touched.work_ending_date &&
                                    errors.work_ending_date && (
                                      <div className="text-danger">
                                        {errors.work_ending_date}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              )}
                            </>
                            <hr />
                          </Row>
                        ))}
                        <Button onClick={handleAddWork}>
                          Add another Work
                        </Button>
                      </>
                    )}
                  </Row>
                )}
                {!edit && (
                  <>
                    <Col md={6}>
                      <div className="form-group">
                        <label htmlFor="deported_removed_from_us">
                          Have you ever been deported or removed from the United
                          States?
                          <span className="text-danger">*</span>
                        </label>

                        <select
                          className="form-select"
                          id="deported_removed_from_us"
                          name="deported_removed_from_us"
                          value={values.deported_removed_from_us}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value=" ">Select One</option>
                          <option value="false">No</option>
                          <option value="true">Yes</option>
                        </select>
                      </div>
                      {touched.deported_removed_from_us &&
                        errors.deported_removed_from_us && (
                          <div className="text-danger">
                            {errors.deported_removed_from_us}
                          </div>
                        )}
                    </Col>
                    <Col md={6}>
                      <div className="form-group">
                        <label htmlFor="denied_to_enter_us">
                          Have you been denied entry to the United States?
                          <span className="text-danger">*</span>
                        </label>

                        <select
                          className="form-select"
                          id="denied_to_enter_us"
                          name="denied_to_enter_us"
                          value={values.denied_to_enter_us}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value=" ">Select One</option>
                          <option value="false">No</option>
                          <option value="true">Yes</option>
                        </select>
                      </div>
                      {touched.denied_to_enter_us &&
                        errors.denied_to_enter_us && (
                          <div className="text-danger">
                            {errors.denied_to_enter_us}
                          </div>
                        )}
                    </Col>

                    <Col md={6}>
                      <Form.Group>
                        <Form.Label htmlFor="residentType">
                          Do you have a pending U.S. asylum application?
                          <span className="text-danger">*</span>
                        </Form.Label>

                        <Form.Select
                          className="form-select "
                          aria-label="Default select example"
                          name="pending_us_asylum"
                          value={values.pending_us_asylum.toString()}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value=" ">Select One</option>
                          <option value="false">No</option>
                          <option value="true">Yes</option>
                        </Form.Select>
                      </Form.Group>
                      {touched.pending_us_asylum &&
                        errors.pending_us_asylum && (
                          <div className="text-danger">
                            {errors.pending_us_asylum}
                          </div>
                        )}
                    </Col>
                    <Col md={12}>
                      <div className="form-group">
                        <label htmlFor="special_accomodation">
                          Do you require any special accommodations? (Example:
                          work schedule limitations due to religious or cultural
                          beliefs)
                          <span className="text-danger">*</span>
                        </label>

                        <select
                          className="form-select "
                          id="special_accomodation"
                          name="special_accomodation"
                          value={values.special_accomodation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">Select One</option>
                          <option value="false">No</option>
                          <option value="true">Yes</option>
                        </select>
                      </div>
                      {touched.special_accomodation &&
                        errors.special_accomodation && (
                          <div className="text-danger">
                            {errors.special_accomodation}
                          </div>
                        )}
                    </Col>

                    <div className="check-outer m-t-30">
                      <div className="check-inner">
                        <label className="check-box" id="check">
                          <small>
                            I have reviewed and understand all materials shown
                            above.
                          </small>
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </>
                )}

                <div className="btn-wrap m-t-20">
                  <button
                    type="button"
                    className="btn btn-primary w-25"
                    onClick={() => {
                      if (edit) {
                        navigate(-1);
                      } else {
                        back();
                      }
                    }}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn--accent w-75"
                    disabled={!(isChecked && isValid) && !edit}
                  >
                    {isSubmitting
                      ? "Submitting..."
                      : !edit
                        ? "Register"
                        : "Update"}
                  </button>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Step5;
