import { useFormik } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { PostAxios } from "./AxiosHelper";
import { PostUserAxios } from "./UserAxios";
import { toast } from "react-toastify";
const ForgetPassword = () => {
  const location = useLocation();
  const who = location.state;
  const UserSchema = Yup.object().shape({
    email: Yup.string()
      .required("Please enter your email")
      .email("Please enter a valid email")
      .trim(),
  });
  const InitialState = {
    email: "",
  };

  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: UserSchema,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });
  const navigate = useNavigate();
  async function handlePost() {
    const res = who === 'admin' ?
      await PostAxios('/auth/forget_password', values) :
      who === 'employee' ?
        await PostUserAxios('/employee/forget_password', values) :
        await PostUserAxios('/employer/forget_password', values);
    if (res.success) {
      navigate(-1);
      toast.success(res.message)
    }
    else {
      toast.error(res.message)
    }

  }
  return (
    <main className="body-wrapper p-tb-60">
      <div className="container">
        <div className="heading text-center m-b-30"></div>
        <div className="row">
          <div className="col-md-8 col-lg-6 col-xl-5 col-12 mx-auto ">
            <Form className="recover-pwd-form" id="forgotPassword">
              <div className=" module">
                <h3 className="m-b-30 text-center">Recover Password</h3>
                <div className="recover-pwd m-b-20">
                  Enter your email address and we will send you a link to reset
                  your password.
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className={`form-control ${errors.email && touched.email ? "is-invalid" : ""
                      }`}
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email ? (
                    <p className="text-danger">{errors.email}</p>
                  ) : null}
                </div>
                <button
                  type="submit"
                  className="btn btn--accent w-100 mb-3"
                  onClick={handleSubmit}
                  disabled={isSubmitting || !isValid}
                >
                  Reset Password
                </button>
                <div className="return-account">
                  <Link to="/employee/login"> Return to login </Link>
                </div>
              </div>
              <div className="meta-need-account text-center m-t-20">
                Need an account?
                <Link to="/register"> Sign Up </Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ForgetPassword;
