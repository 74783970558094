import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
export const PrivateAdminRoute = ({ component }) => {
  // TODO: logic implement
  const logged = loggedIn();
  return logged ? component : <Navigate to="/admin"></Navigate>;
};

export const loggedIn = () => {
  let is_logged_in = false;

  const token = Cookies.get(`usertoken`);

  if (token) {
    is_logged_in = true;
  }
  return is_logged_in;
};
