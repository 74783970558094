import React from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";

const EmailTemplate = () => {
  const emailTempcolumns = [
    {
      name: "Subject",
      selector: (row) => row.subject,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => <ActionButton row={row} pageTitle="emailtemp" />,
    },
  ];

  async function getField() {
    const url = `/admin/emailtemp`;
    try {
      const response = await getAxios(url);
      const fetchedEmailTemplateList = response.data;
      return fetchedEmailTemplateList.email_template;
    } catch (error) {
      console.log(error);
    }
  }

  const { data, error, isLoading } = useQuery("EmailtempList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  return (
    <>
      <PageData
        columns={emailTempcolumns}
        tableData={data}
        title="Emailtemp"
        displayTitle="Email Template"
      />
    </>
  );
};

export default EmailTemplate;
