import React, { useState } from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios } from "../../../helper/AxiosHelper";

import "./Form.css";
import Editor from "../GeneralUI/Editor/Editor";

const EmailTemplateForm = ({ edit }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const client = useQueryClient();
  const InitialState = {
    subject: edit ? location.state.subject : "",
    design: edit ? location.state.design : "",
  };
  const EmailTemp = Yup.object().shape({
    subject: Yup.string().required("Please enter a subject"),
    design: Yup.string(),
  });
  const [editorValue, setEditorValue] = useState(
    edit ? InitialState.design : ""
  );
  const handlePost = async (e) => {
    const submitValues = {
      ...values,
      design: editorValue,
    };
    const res = edit
      ? await PatchAxios(`/admin/emailtemp/${location.state._id}`, submitValues)
      : await PostAxios("/admin/emailtemp", submitValues);

    if (res.success) {
      navigate("/admin/emailtemplate");
      client.refetchQueries("EmailtempList");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
    } else {
      toast.error(res.message);
    }
  };

  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: EmailTemp,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });
  return (
    <Form>
      <Row className="m-2 p-2">
        <h2 className="text-center text-muted mb-2">Add Email Template</h2>
        <Col md={6}>
          <Form.Group>
            <Form.Label htmlFor="name">
              Subject
              <span className="text-danger">*</span>
            </Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                required
                type="text"
                placeholder="Subject"
                name="subject"
                value={values.subject}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.subject && touched.subject}
              />
              {errors.subject && touched.subject && (
                <p className="text-danger">{errors.subject}</p>
              )}
            </InputGroup>
          </Form.Group>
        </Col>
        <Form.Label htmlFor="Design">Design</Form.Label>

        <Editor values={editorValue} onValueChange={setEditorValue} />
        <div className="mt-4 d-flex justify-content-between">
          <Button
            type="submit"
            className={`btn btn-primary  `}
            onClick={handleSubmit}
            disabled={!isValid || isSubmitting}
          >
            {edit
              ? isSubmitting
                ? "Updating...."
                : "Update"
              : isSubmitting
              ? "Submitting...."
              : "Submit"}
          </Button>
          <Button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </div>
      </Row>
    </Form>
  );
};

export default EmailTemplateForm;
