import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getUserAxios } from "../../../../helper/UserAxios";
import { useNavigate } from "react-router-dom";

const EmpProfile = ({ employer }) => {
  const fileURL = process.env.REACT_APP_FILE_URL;
  const navigate = useNavigate();
  const data = useSelector((state) => state.emp.emp_info.data);
  const getField = async () => {
    const url = `/employee/info`;
    try {
      const response = await getUserAxios(url);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  };
  const { data: EmpInfo } = useQuery("EmpInfo", getField);

  const handleEdit = (what) => {
    navigate("/employee/edit", {
      state: { what: what, data: what === "profile" ? data : EmpInfo },
    });
  };
  return (
    <div className="main-content-inner">
      <div className="profile-outer">
        <div className="dashboard__profile card profile-card">
          <div className="card-body profile-card__body">
            {/* <div className="profile-card__avatar">
              <img src={fileURL + "/" + data.image} alt="" />
            </div> */}
            <div className="profile-card__name">
              {data.first_name} {data.last_name}
            </div>{" "}
            <div className="profile-card__email">{data.email}</div>
            <div className="profile-card__edit">
              <button
                className="btn btn--primary btn-sm"
                onClick={() => handleEdit("profile")}
              >
                Edit Profile
              </button>
            </div>
          </div>
        </div>
        <div className="dashboard__address card address-card address-card--featured">
          <div className="address-card__body">
            <div className="address-card__name">
              {data.first_name} {data.last_name}
            </div>
            <div className="address-card__row">
              {EmpInfo?.current_address}
              <br />
              {EmpInfo?.current_street}

              <br />
              {EmpInfo?.current_city_town}
            </div>
            <div className="address-card__row">
              <div className="address-card__row-title">Phone Number</div>
              <div className="address-card__row-content">{data.phone}</div>
            </div>
            <div className="address-card__row">
              <div className="address-card__row-title">Email Address</div>
              <div className="address-card__row-content">{data.email}</div>
            </div>
            <div className="address-card__footer m-t-20">
              <button
                className="btn btn--accent btn-sm"
                onClick={() => handleEdit("detail")}
              >
                Edit Details
              </button>
            </div>
          </div>
        </div>
        <div className="dashboard__profile card profile-card">
          <div className="card-body profile-card__body">
            <div className="profile-card__avatar">
              <img src="dist/images/avatar-3.jpg" alt="" />
            </div>
            <div className="profile-card__name">
              {data.first_name} {data.last_name}
            </div>{" "}
            <div className="profile-card__email">{data.email}</div>
            <div className="profile-card__edit">
              <button
                className="btn btn--primary btn-sm"
                onClick={() => handleEdit("work")}
              >
                Edit Work Experiences
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmpProfile;
