import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { PatchAxios, PostAxios, delAxios } from "../../../../helper/AxiosHelper";
import { toast } from "react-toastify";

const AppointmentModal = ({ show, handleClose, id }) => {
  async function handleSubmit() {
    const res = await PatchAxios(`/admin/appointment/${id}`);
    if (res.success) {
      toast.success(res.message);
    }
    handleClose();
  }
  async function deleteAppointment() {
    const res = await delAxios(`/admin/appointment/${id}`);
    if (res.success) {
      toast.success(res.message);
    }
    handleClose();
  }
  const [zoomLink, setZoomLink] = useState("");

  const handleZoomLinkChange = (event) => {
    setZoomLink(event.target.value);
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      scrollable
      className="not-confirm"
      // size={isDetail ? "lg" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title>Update appointment zoom Link</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form >
          <Form.Group controlId="formZoomLink">
            <Form.Label>Zoom Link:</Form.Label>
            <Form.Control
              type="text"
              value={zoomLink}
              onChange={handleZoomLinkChange}
              style={{ borderRadius: "15px" }} // Adjust the radius as needed
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>

        <Button variant="primary" onClick={handleSubmit} >Update</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AppointmentModal;
