import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getUserAxios } from '../../../../helper/UserAxios';

const VacancyDetail = ({ vacancy, employer, employerApp }) => {
    console.log(vacancy)
    const fileURL = process.env.REACT_APP_FILE_URL;
    const [employeeData, setEmployeeData] = useState([])
    const getData = async () => {
        console.log("first")
        if (!employerApp) {
            return;
        }
        const res = await getUserAxios(`/employer/apply_vacancy/employee/${vacancy._id}`)
        setEmployeeData(res.data.employee);
    }

    useEffect(() => {
        getData();
    }, [employerApp])




    return (
        <>
            {!employerApp ? (
                <div className="container mt-4">
                    <div dangerouslySetInnerHTML={{ __html: vacancy.description }} id='dangerouslySetImage' />
                    {console.log(vacancy)}
                    {!employer && (<div className="col-md-6">
                        <h4>Employer Details</h4>
                        <p>Email: {vacancy.employer && vacancy.employer.email}</p>
                        <p>Phone: {vacancy.employer && vacancy.employer.phone}</p>
                        <p>Company Name: {vacancy.employer && vacancy.employer.company_name}</p>
                        {/* Add other employer details as needed */}
                    </div>)}

                    <div className="col-md-6">
                        <h4>Category</h4>
                        <ul>
                            {vacancy.category.map((category) => (
                                <li key={category._id}>{category.title}</li>
                            ))}
                        </ul>
                        {/* Add other category details as needed */}
                    </div>


                    <div className="mt-4">
                        <h4>Tags</h4>
                        <ul>
                            {vacancy.tag.map((tag) => (
                                <li key={tag._id}>{tag.title}</li>
                            ))}
                        </ul>
                    </div>
                </div>) :
                (
                    <div className="container-fluid mt-5">
                        <h2 className="mb-4">{employeeData && vacancy.first_name + ' ' + vacancy.last_name}</h2>
                        {employeeData && (
                            <div className="row">
                                <div className="col-md-6">
                                    <p><strong>Country:</strong> {employeeData.country}</p>
                                    <p><strong>Current Address:</strong> {employeeData.current_address}</p>
                                    <p><strong>Current Street:</strong> {employeeData.current_street}</p>
                                    <p><strong>Current City/Town:</strong> {employeeData.current_city_town}</p>
                                    <p><strong>Special Accommodation:</strong> {employeeData.special_accomodation ? 'Yes' : 'No'}</p>
                                    <p><strong>Physical Limitation:</strong> {employeeData.physical_limitation ? 'Yes' : 'No'}</p>
                                    <p><strong>Pending US Asylum:</strong> {employeeData.pending_us_asylum ? 'Yes' : 'No'}</p>
                                    <p><strong>Deported/Removed from US:</strong> {employeeData.deported_removed_from_us ? 'Yes' : 'No'}</p>
                                </div>
                                <div className="col-md-6">
                                    <p><strong>Passport Front:</strong> <img src={fileURL + '/' + employeeData.passport_front} alt="Passport Front" className="img-fluid" /></p>
                                    {employeeData.resume && (
                                        <p>
                                            <strong>Download Resume:</strong>{' '}
                                            <a href={`${fileURL}/${employeeData.resume}`} download target='_blank'>
                                                Download
                                            </a>
                                        </p>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}

        </>

    )
}
export default VacancyDetail;
