import React from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";

const Album = () => {
  const fileURL =
    process.env.REACT_APP_FILE_URL;

  const albumColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Image",
      selector: (row) => row.image,
      cell: (row) => (
        <div className="rounded-circle">
          <img
            className="rounded-circle"
            src={fileURL + "/" + row.image} // Use the image URL from the data
            alt={row.title} // Provide alt text for accessibility
            width={50}
            height={50}
          />
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <ActionButton row={row} pageTitle="album" />
      ),
    },
  ];
  async function getField() {
    const url = `/admin/album`;
    try {
      const response = await getAxios(url);
      const fetchedAlbumList = response.data;
      return fetchedAlbumList.album;
    } catch (error) {
      console.log(error);
    }
  }

  const { data, error, isLoading } = useQuery("AlbumList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }

  return (
    <>

      <PageData columns={albumColumns} tableData={data} title="Album" />

    </>
  );
};

export default Album;
