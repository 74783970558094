import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingSpinner = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 1, 0, 0.5)", // Background color with 50% opacity
        backdropFilter: "blur(10px)", // Add blur effect
        zIndex: 9999, // Make sure it's on top of other elements
      }}
    >
      <Spinner
        animation="border"
        variant="primary"
        size="xl"
        style={{ width: "100px", height: "100px" }}
      />
    </div>
  );
};

export default LoadingSpinner;
