import React from "react";
import { toast } from "react-toastify";
import { PostUserAxios } from "../../../../helper/UserAxios";
const RegButton = ({
  back,
  next,
  hasBack = true,
  hasNext = true,
  isDisabled,
  hasContinue = false,
}) => {
  const apiValue = {
    pine_visa_cost: true,
    obligations: true,
    work_history_disclaimer: true,
    terms_and_conditions: true,
    used_in_immigration_fillings: true,
  };
  const handleContinue = async () => {
    
        next();
    
  };
  return (
    <div class="btn-wrap m-t-20">
      {hasBack && (
        <button
          class="btn btn--primary-outline w-25"
          onClick={() => {
            back();
          }}
        >
          Back
        </button>
      )}
      {hasNext && (
        <button
          class="btn btn--accent w-25"
          disabled={isDisabled}
          onClick={() => {
            next();
          }}
        >
          Next
        </button>
      )}
      {hasContinue && (
        <button
          class="btn btn--accent w-25"
          disabled={isDisabled}
          onClick={handleContinue}
        >
          Continue
        </button>
      )}
    </div>
  );
};

export default RegButton;
