import * as Yup from "yup";

const BlogSchema = Yup.object().shape({
  title: Yup.string().required("Please enter a title").strict(),
  meta_title: Yup.string().required("Please enter meta Title"),
  meta_desc: Yup.string().required("Please enter a Meta description"),
  short_desc: Yup.string().required("Please enter a short description"),
  long_desc: Yup.string(),
  seo_image: Yup.string(),
  featured_image: Yup.string(),
  featured_image_status: Yup.string()
    .oneOf(["active", "inactive"])
    .default("inactive"),
  status: Yup.string().oneOf(["active", "inactive"]).default("inactive"),
  yt_vide_link: Yup.string(),
  yt_video_status: Yup.string()
    .oneOf(["active", "inactive"])
    .default("inactive"),
});

export default BlogSchema;
