import React, { useEffect, useState } from "react";
import { getUserAxios, PostUserAxios } from "../../../helper/UserAxios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import EmployeeStatusModal from "./EmployeeStatusModal";

const EmployeeDashboard = () => {
  const isValidDate = (current) => {
    const tomorrowDate = moment().add(1, "day");
    const userTimeInCST = moment(current).utcOffset(-5); // Assuming CST is UTC-6
    const isAfterTomorrow = userTimeInCST.isAfter(tomorrowDate);
    const isWithinWorkingHours =
      userTimeInCST.hours() >= 9 && userTimeInCST.hours() <= 17;

    if (!isAfterTomorrow || !isWithinWorkingHours) {
      console.warn(
        "Please select a valid date and time (after tomorrow and within working hours)."
      );
    }
    return isAfterTomorrow && isWithinWorkingHours;
  };

  const [isDateTimeVisible, setIsDateTimeVisible] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [steps, setSteps] = useState();
  const [appointment, setAppointment] = useState();
  const [show, setShow] = useState(false);

  const handleGoToOnboarding = () => {
    // if (empInfo.onboarding === "step_1") {
    if (!isDateTimeVisible) {
      toast.info(
        "Select your appointment date and time. Other than Satudray and Sunday"
      );
    }
    setIsDateTimeVisible(!isDateTimeVisible);
    // } else {
    toast.warn("Please wait for your date");
    // }
  };
  const getSteps = async () => {
    const res = await getUserAxios("/employee/steps");
    setSteps(res.data.step);
  };

  const getAppointment = async () => {
    const res = await getUserAxios("/appointment");
    setAppointment(res.data.appointment);
  };
  function isTimeInWorkingHours() {
    const userDateTime = new Date(selectedDateTime);
    const userTimeZoneOffset = userDateTime.getTimezoneOffset();
    const userTimeInGMT = new Date(
      userDateTime.getTime() + userTimeZoneOffset * 60000
    );
    const userHourInGMT = userTimeInGMT.getUTCHours();

    // Check if the user-provided time is between 9 AM and 5 PM CST
    return userHourInGMT >= 9 && userHourInGMT < 17;
  }
  function suggestValidTime(userTime) {
    const userDateTime = new Date(userTime);

    // Get the user's time zone offset in minutes
    const userTimeZoneOffset = userDateTime.getTimezoneOffset();

    // Calculate the user's time in GMT
    const userTimeInGMT = new Date(
      userDateTime.getTime() + userTimeZoneOffset * 60000
    );

    // Extract hours from the adjusted GMT time
    const userHourInGMT = userTimeInGMT.getUTCHours();

    // Check if the user-provided time is between 9 AM and 5 PM CST
    const isWithinWorkingHours = userHourInGMT >= 9 && userHourInGMT < 17;

    // Check if the user-provided time is within the specified time range
    const isWithinTimeRange =
      userDateTime.getHours() >= 9 && userDateTime.getHours() < 17;

    if (isWithinWorkingHours && isWithinTimeRange) {
      // The provided time is valid
      return userTime;
    } else {
      // Suggest a valid time within the specified time range
      const suggestedTime = new Date(userTime);
      suggestedTime.setHours(Math.max(9, Math.min(16, userTime.getHours())));

      // Format the suggested time as a string (in user's local time)
      const suggestedTimeString = suggestedTime.toLocaleString("en-US", {
        timeZone: "America/Chicago",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      });

      return `Please enter a time between 9 AM and 5 PM CST. Suggested time: ${suggestedTimeString}`;
    }
  }
  const isTimeInRange = (time) => {
    const cstOffset = -6 * 60; // CST offset in minutes
    const cstStartTime = new Date(time);
    cstStartTime.setUTCHours(9, 0 + cstOffset, 0, 0);
    const cstEndTime = new Date(time);
    cstEndTime.setUTCHours(17, 0 + cstOffset, 0, 0);

    return time >= cstStartTime && time <= cstEndTime;
  };
  const convertToTimeZoneFormat = (localDateTime, timeZone) => {
    const options = {
      timeZone,
      hour12: false,
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Intl.DateTimeFormat("en-US", options).format(localDateTime);
  };
  const convertToGMTFormat = (localDateTime) => {
    const options = {
      timeZone: "GMT",
      hour12: false,
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Intl.DateTimeFormat("en-US", options).format(localDateTime);
  };

  const handleDateTimeChange = (date) => {
    setSelectedDateTime(date);
  };
  const handleDateSubmit = async () => {
    if (isTimeInWorkingHours(selectedDateTime)) {
      const res = await PostUserAxios("/appointment", {
        datetime: selectedDateTime.toLocaleString(),
      });
      if (res.success) {
        toast.success(res.message);
        getAppointment();
        setIsDateTimeVisible(false);
      } else {
        toast.error(res.message);
      }
    } else {
      toast.error("Invalid Time");
    }
  };
  useEffect(() => {
    getSteps();
    getAppointment();
  }, []);
  const [appDate, setAppDate] = useState();
  const handleViewOnboardingStatus = () => {
    setShow(true);
    const date = new Date(appointment[0].datetime);
    setAppDate(date);
  };

  return !show ? (
    <div className="main-content-inner">
      <p>
        Some message goes here. Your sales rep is Alex Agudelo; reach out to
        them if you have any questions. Please feel free to call.
      </p>

      <div className="onboarding">
        <h3>Congratulations !</h3>
        <h4>
          In order for you to have a better understanding of YOwW, we have some
          materials that you should review to continue with your process.
        </h4>
        {steps && steps.onboarding.appointment === "pending" ? (
          <button
            className="btn btn--primary m-t-20"
            onClick={handleGoToOnboarding}
          >
            Go to onboarding
          </button>
        ) : (
          <button
            className="btn btn--primary m-t-20"
            onClick={handleViewOnboardingStatus}
          >
            View Status
          </button>
        )}
      </div>

      {isDateTimeVisible && (
        <div className="w-100">
          <DateTime
            onChange={handleDateTimeChange}
            open
            minDate={new Date(new Date().getTime()).toISOString().split("T")[0]}
            isValidDate={isValidDate}
            initialViewDate={new Date()}
            input={false}
            value={selectedDateTime}
            dateFormat="YYYY-MM-DD"

            // timeFormat="HH:MM"
          />
          {selectedDateTime && (
            <>
              <p className="m-t-20">
                User's local time: {selectedDateTime.toLocaleString()} <br />
                Us time (CST):{" "}
                {convertToTimeZoneFormat(selectedDateTime, "America/Chicago")}
                <br />
                <h3 className="text-danger">
                  Please select between 9 am and 5 pm in Us(CST)
                </h3>
              </p>
            </>
          )}
        </div>
      )}

      {isDateTimeVisible && (
        <button className="btn btn--accent" onClick={handleDateSubmit}>
          Submit
        </button>
      )}
      <h3 className="m-t-20">Your next steps</h3>
      <div className="next-step">
        <ul>
          <li className="d-flex justify-content-between active">
            <span className="flex1"> Complete onboarding &nbsp;</span>
            <span className="flex-right">
              <i className="fa-solid fa-check"></i>
            </span>
          </li>
          <li className="d-flex justify-content-between active">
            <span className="flex1"> Goto jobs to select your employer </span>
            <span className="flex-right">
              <i className="fa-solid fa-spinner"></i>
            </span>
          </li>
          <li>Document Sign</li>
          <li>Payment</li>
        </ul>
      </div>
    </div>
  ) : (
    <EmployeeStatusModal
      handleClose={() => setShow(false)}
      show={show}
      Content={steps}
      appointmentDate={appDate.toString()}
      link={appointment[0].link}
    />
  );
};

export default EmployeeDashboard;
