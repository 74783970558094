import React from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import "./Form.css";
import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { GoogleMapSchema } from "./Schemas";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios } from "../../../helper/AxiosHelper";

const GoogleMapForm = ({ edit }) => {
  const location = useLocation();
  const client = useQueryClient();
  const navigate = useNavigate();
  const InitialState = {
    title: edit ? location.state.title : "",
    embedded: edit ? location.state.embedded : "",
    display_order: edit ? location.state.display_order : "",
    status: edit ? location.state.status : "active",
  };
  const handlePost = async (e) => {
    const formData = new FormData();

    for (const key in values) {
      formData.append(key, values[key]);
    }
    const res = edit
      ? await PatchAxios(`/admin/gmap/${location.state._id}`, values)
      : await PostAxios("/admin/gmap", values);

    if (res.success) {
      client.invalidateQueries("GmapList");
      navigate("/admin/gmap");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
    } else {
      toast.error(res.error);
    }
  };
  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: GoogleMapSchema,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });
  return (
    <Form onSubmit={handleSubmit}>
      <h1 className="text-center text-white">
        {edit ? "Update " : "Add "} Google Map
      </h1>
      <Row className="m-2 p-2">
        <Col md={6}>
          <Form.Group>
            <Form.Label htmlFor="title">
              Title
              <span className="text-danger">*</span>
            </Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                required
                type="text"
                //id="title"
                placeholder="Title"
                name="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </InputGroup>
            {errors.title && touched.title ? (
              <p className="text-danger">{errors.title}</p>
            ) : null}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="embedded">Embedded</Form.Label>
            <Form.Control
              type="text"
              name="embedded"
              value={values.embedded}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.embedded && touched.embedded ? (
              <p className="text-danger">{errors.embedded}</p>
            ) : null}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label htmlFor="status">Status</Form.Label>
            <Form.Select
              className="form-select form-select-sm mb-3"
              aria-label="Default select example"
              name="status"
              value={values.status}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Form.Select>
            {errors.status && touched.status ? (
              <p className="text-danger">{errors.status}</p>
            ) : null}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="display_order">Display Order</Form.Label>
            <Form.Control
              type="text"
              name="display_order"
              value={values.display_order}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.display_order && touched.display_order ? (
              <p className="text-danger">{errors.display_order}</p>
            ) : null}
          </Form.Group>
        </Col>
      </Row>
      <div className="mt-4 d-flex justify-content-between">
        <Button
          type="submit"
          className={`btn btn-primary  `}
          onClick={handleSubmit}
          disabled={!isValid || isSubmitting}
        >
          {edit
            ? isSubmitting
              ? "Updating...."
              : "Update"
            : isSubmitting
            ? "Submitting...."
            : "Submit"}
        </Button>
        <Button
          type="button"
          className="btn btn-danger "
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
};

export default GoogleMapForm;
