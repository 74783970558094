import * as Yup from "yup";

const RoleSchema = Yup.object().shape({
  name: Yup.string().required("Please enter a name").strict().trim().min(2),
  description: Yup.string().default(""),
  level: Yup.number()
    .default(5)
    .min(0, "Level Must be positive")
    .required("Required"),
  status: Yup.string().oneOf(["active", "inactive"]).default("active"),
});

export default RoleSchema;
