import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { Logout, getAxios } from "../../../../helper/AxiosHelper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { addUser, deleteUser } from "../../../../store/adminSlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const AdminProfile = () => {
  const fileUrl = process.env.REACT_APP_FILE_URL;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const client = useQueryClient();

  const { data, isLoading, error } = useQuery("adminStatus", getField, {
    staleTime: 30000,
    refetchInterval: 30000,
  });

  const handleLogout = async () => {
    const res = await Logout();
    if (res.success) {
      client.removeQueries("adminStatus");
      dispatch(deleteUser());
      navigate("/admin");
      toast.success(res.message);
      localStorage.removeItem("level");
    } else {
      toast.error(res.message);
    }
    return;
  };

  async function getField() {
    const url = `/user/me`;
    try {
      const response = await getAxios(url);
      const List = response.data.data;
      localStorage.setItem("level", JSON.stringify(List.role_id.level));
      dispatch(addUser(List));
      return List;
    } catch (error) {
      console.log(error);
    }
  }
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  return (
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="profileDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img
          className="wd-30 ht-30 rounded-circle"
          src={
            data.image
              ? `${fileUrl + "/" + data.image}`
              : "https://placehold.co/30x30"
          }
          alt="profile"
        />
      </a>

      <div className="dropdown-menu p-0" aria-labelledby="profileDropdown">
        <div className="d-flex flex-column align-items-center border-bottom px-5 py-3">
          <div className="mb-3">
            <img
              className="wd-80 ht-80 rounded-circle"
              src={
                data.image
                  ? `${fileUrl + "/" + data.image}`
                  : "https://placehold.co/80x80"
              }
              alt=""
            />
          </div>
          <div className="text-center">
            <p className="tx-16 fw-bolder">{data.name} </p>
            <p className="tx-12 text-muted"> {data.email}</p>
          </div>
        </div>
        <ul className="list-unstyled p-1">
          {/* <li className="dropdown-item py-2">
            <a href="#" className="text-body ms-0">
              <i className="me-2 icon-md fa fa-user" data-feather="user"></i>
              <span>Profile</span>
            </a>
          </li> */}
          <li className="dropdown-item py-2">
            <Link to="/admin/changeimage" className="text-body ms-0">
              <i
                className="me-2 icon-md fa fa-user-circle"
                data-feather="edit"
              ></i>
              <span>Change Image</span>
            </Link>
          </li>
          <li className="dropdown-item py-2">
            <Link to="/admin/changepassword" className="text-body ms-0">
              <i className="me-2 icon-md fa fa-key" data-feather="repeat"></i>
              <span>Change Password</span>
            </Link>
          </li>

          <li className="dropdown-item py-2 pe-auto" onClick={handleLogout}>
            <a href="#" className="text-body ms-0">
              <i
                className="me-2 icon-md fa fa-sign-out"
                data-feather="log-out"
              ></i>
              <span>Log Out</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default AdminProfile;
