import * as Yup from "yup";

const CMSSchema = Yup.object().shape({
  title: Yup.string().required("Please enter a title").strict().trim(),
  page_id: Yup.string(),
  desc: Yup.string(),
  meta_title: Yup.string(),
  meta_desc: Yup.string(),
  seo_image: Yup.string(),
  status: Yup.string().oneOf(["active", "inactive"]).default("inactive"),
  slug: Yup.string(),
});

export default CMSSchema;
