import React, { useState, useRef } from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios, getAxios } from "../../../helper/AxiosHelper";

import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { PermissionGroupSchema } from "./Schemas";

const PermissionGroupForm = ({ edit }) => {
    const location = useLocation();
    const client = useQueryClient();
    const navigate = useNavigate();

    const InitialState = {
        name: edit ? location.state.name : "",
    };

    const {
        isSubmitting,
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
    } = useFormik({
        initialValues: InitialState,
        validationSchema: PermissionGroupSchema,
        onSubmit: async (values, action) => {
            const res = await handlePost();
            if (res) {
                action.resetForm();
            }
            if (res.success) {
                client.refetchQueries("PermissionGroupList");
                navigate("/admin/permussiongroup");
                toast.success(edit ? "Updated Successfully" : "Added Successfully");
                return true;
            } else {
                toast.error(res.message);
            }
        },
    });
    const handlePost = async (e) => {
        const res = edit
            ? await PatchAxios(`/admin/permissiongroup/${location.state._id}`, values)
            : await PostAxios("/admin/permissiongroup", values);

        if (res.success) {
            navigate("/admin/permissiongroup");
            toast.success(edit ? "Updated Successfully" : "Added Successfully");
            return true;
        } else {
            toast.error(res.message);
        }
    };
    return (
        <>
            <Form>
                <h2 className="text-center text-muted mb-2">
                    {" "}
                    {edit ? "Update" : "Add"} Permission Group
                </h2>

                <Row className="m-2 p-2">
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label htmlFor="name">
                                Name
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    required
                                    type="text"
                                    id="name"
                                    placeholder="Name"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </InputGroup>
                            {errors.name && touched.name ? (
                                <p className="text-danger">{errors.name}</p>
                            ) : null}
                        </Form.Group>
                    </Col>


                    <div className="mt-4 d-flex justify-content-between">
                        <Button
                            type="submit"
                            className={`btn btn-primary  `}
                            onClick={handleSubmit}
                            disabled={!isValid || isSubmitting}
                        >
                            {edit
                                ? isSubmitting
                                    ? "Updating...."
                                    : "Update"
                                : isSubmitting
                                    ? "Submitting...."
                                    : "Submit"}
                        </Button>
                        <Button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </Row>
            </Form>
        </>
    );
};

export default PermissionGroupForm;
