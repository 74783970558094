import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import VacancyApply from "../VacancyApply/VacancyApply";
import Vacancy from "../Vacancy/Vacancy";

const Dashboard = () => {
  const fileURL = process.env.REACT_APP_FILE_URL;
  async function getField() {
    const url = `/admin/employer`;
    try {
      const response = await getAxios(url);
      const fetchedList = response.data;
      return fetchedList.employer;
    } catch (error) {
      console.log(error);
    }
  }
  async function getFieldEmployee() {
    const url = `/admin/employee`;
    try {
      const response = await getAxios(url);
      const fetchedList = response.data;
      return fetchedList.employee;
    } catch (error) {
      console.log(error);
    }
  }
  async function getFieldU() {
    const url = `/admin/user`;

    try {
      const response = await getAxios(url);
      const fetchedUserList = response.data.users;
      console.log(fetchedUserList);
      return fetchedUserList;
    } catch (error) {
      console.log(error);
    }
  }

  const { data: userList } = useQuery("UserssList", getFieldU);
  const { data } = useQuery("EmployerList", getField);
  const { data: empList } = useQuery("EmployeeList", getFieldEmployee);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap grid-margin">
        <div>
          <h4 className="mb-3 mb-md-0 text-light">Welcome to Dashboard</h4>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-xl-12 stretch-card">
          <div className="row flex-grow-1">
            <div className="col-md-6 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <h6 className="card-title mb-0">Employee</h6>
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-12 col-xl-6">
                      <h3 className="mb-2">{empList && empList.length}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <h6 className="card-title mb-0">Employer</h6>
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-12 col-xl-6">
                      <h3 className="mb-2">{data && data.length}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-xl-6 grid-margin grid-margin-xl-0 stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-baseline mb-2">
                  <h6 className="card-title mb-0">Employer</h6>
                </div>
                <div className="d-flex flex-column">
                  {data &&
                    data.map((item) => (
                      <div
                        key={item._id}
                        className="d-flex align-items-center border-bottom pb-3"
                      >
                        <div className="me-3">
                          <img
                            src={`${fileURL}/${item.org_logo}`}
                            className="rounded-circle wd-35"
                            alt="user"
                          />
                        </div>
                        <div className="w-100">
                          <div className="d-flex justify-content-between">
                            <h6 className="text-body mb-2">
                              {item.company_name}
                            </h6>
                          </div>
                          <p className="text-muted tx-13">{item.email}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-6 grid-margin grid-margin-xl-0 stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-baseline mb-2">
                  <h6 className="card-title mb-0">Employee</h6>
                </div>
                <div className="d-flex flex-column">
                  {empList &&
                    empList.slice(-5).map((item) => (
                      <div
                        key={item._id}
                        className="d-flex align-items-center border-bottom pb-3"
                      >
                        <div className="me-3">
                          <i class="fa-solid fa-user"></i>
                        </div>
                        <div className="w-100">
                          <div className="d-flex justify-content-between">
                            <h6 className="text-body mb-2">
                              {item.first_name} {item.last_name}
                            </h6>
                          </div>
                          <p className="text-muted tx-13">{item.email}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 col-xl-8 stretch-card  ">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-baseline mb-2">
                    <h6 className="card-title mb-0">Applications</h6>
                  </div>
                  <VacancyApply home={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
