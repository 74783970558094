import axios from "axios";
import Cookies from "js-cookie";
let response = {};
const baseUrl = process.env.REACT_APP_BASE_URL;

let headers = {};
if (document.cookie) {
  const token = Cookies.get("employeetoken");
  headers = {
    Authorization: `Bearer ${token}`,
    withCredentials: true,
    cookies: "token=" + token,
  };
}

export async function getCMS(page_id) {
  const finalUrl = baseUrl + "/cms?" + page_id;

  try {
    const res = await axios.get(finalUrl);
    if (res.status === 200) {
      response = {
        ...response,
        success: true,
        message: res.data.msg,
        data: res.data.cms,
      };
    } else {
      response = { ...response, success: false, message: res.msg.data };
    }
  } catch (error) {
    response = {
      ...response,
      success: false,
      message: error.response.msg,
    };
  }
  return response;
}

export async function loginUserAxios(url, data) {
  const finalUrl = baseUrl + url;
  try {
    const res = await axios.post(finalUrl, data);
    if (res.status === 200) {
      response = {
        ...response,
        success: true,
        message: "Login successful",
        data: res.data.employee,
      };
      Cookies.set("employeetoken", res.data.token, { expires: 7 });
      headers = {
        Authorization: `Bearer ${res.data.token}`,
        withCredentials: true,
        cookies: `token=` + res.data.token,
      };
    } else {
      response = {
        ...response,
        success: false,
        message: res.data.msg,
      };
    }
  } catch (error) {
    response = {
      ...response,
      success: false,
      message: error.response.data.msg,
    };
  }
  return response;
}
export async function getUserAxios(url) {
  const finalUrl = baseUrl + url;
  try {
    const res = await axios.get(finalUrl, { headers });
    if (res.status === 200) {
      response = {
        ...response,
        success: true,
        message: res.data.msg,
        data: res.data,
      };
    } else {
      response = { ...response, success: false, message: res.data.msg };
    }
  } catch (error) {
    response = {
      ...response,
      success: false,
      message: error.response.data.msg,
    };
  }
  return response;
}
export async function LogoutUser(employee) {
  const response = await getUserAxios(`/auth/${employee}/logout`, { headers });
  Cookies.remove("employeetoken");
  return response;
}
export async function PostUserAxios(url, data) {
  const finalUrl = baseUrl + url;
  try {
    const res = await axios.post(finalUrl, data, { headers });
    if (res.status === 200 || 201) {
      response = {
        ...response,
        success: true,
        message: res.data.msg,
        data: res.data.data,
      };
    } else {
      response = { ...response, success: false, message: res.data.msg};
    }
  } catch (error) {
    response = {
      ...response,
      success: false,
      message: error.response.data.msg,
    };
  }
  return response;
}

export async function RegisterAxios(url, data) {
  const finalUrl = baseUrl + url;
  try {
    const res = await axios.post(finalUrl, data);
    if (res.status === 200 || 201) {
      response = {
        ...response,
        success: true,
        message: res.data.msg,
        data: res.data.data,
      };

      Cookies.set("employeetoken", res.data.token, { expires: 7 });
      headers = {
        Authorization: `Bearer ${res.data.token}`,
        withCredentials: true,
        cookies: `token=` + res.data.token,
      };
    } else {
      response = { ...response, success: false, message: res.data.msg };
    }
  } catch (error) {
    response = {
      ...response,
      success: false,
      message: error.response.data.msg,
    };
  }
  return response;
}

export async function delUserAxios(url, data) {
  const finalUrl = baseUrl + url;

  try {
    const res = await axios.delete(finalUrl, { headers });

    if (res.status === 200) {
      response = { ...response, success: true, message: res.data.msg };
    } else {
      response = { ...response, success: false, message: res.data.msg };
    }
  } catch (error) {
    response = {
      ...response,
      success: false,
      message: error.response.data.msg,
    };
  }
  return response;
}

// export async function multiDelAxios(url, data) {
//   const finalUrl = baseUrl + url;
//   try {
//     const res = await axios.delete(finalUrl, { data, headers });

//     if (res.status === 200) {
//       response = { ...response, success: true, message: res.data.msg };
//     } else {
//       response = { ...response, success: false, message: res.data.msg };
//     }
//   } catch (error) {
//     response = {
//       ...response,
//       success: false,
//       message: error.response.data.msg,
//     };
//   }
//   return response;
// }

export async function PatchUserAxios(url, data) {
  const finalUrl = baseUrl + url;
  try {
    const res = await axios.patch(finalUrl, data, { headers });

    if (res.status === 201 || 200) {
      response = {
        ...response,
        success: true,
        message: res.data.msg,
      };
    } else {
      response = { ...response, success: false, message: res.data.msg };
    }
  } catch (error) {
    response = {
      ...response,
      success: false,
      message: error.response.data.msg,
    };
  }
  return response;
}
