import * as yup from "yup";

const ExperienceSchema = yup.object().shape({
  position: yup.string().required("Position is required"),
  company: yup.string().required("Company name is required"),
  from: yup.date().required("Start date is required"),
  to: yup.date(),
});

export default ExperienceSchema;
