import React, { useState } from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios, delAxios } from "../../../../helper/AxiosHelper";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ContactView from "./ContactView";

const Contact = () => {
  const [showModal, setShowModal] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const setViewModal = (row) => {
    const seen = isSeen();
    const feedback = {
      first_name: row.first_name,
      last_name: row.last_name,
      email: row.email,
      message: row.message,
      id: row._id,
      seen,
    };
    setFeedback(feedback);
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  function isSeen(row) {
    if (row.hasOwnProperty("seen_at")) {
      return true;
    } else {
      return false;
    }
  }
  const handleView = async (rowData) => {
    const res = await getAxios(`/admin/contact/${rowData}/seen`);
    if (res.success) {
      toast.success(res.message);
      getField();
    } else {
      toast.error(res.message);
    }
    setShowModal(false);
  };
  const handleDelete = async (uid) => {
    const res = await delAxios(`/admin/contact/${uid}`);

    if (res.success) {
      toast.success(res.message);
      getField();
    }
  };
  const contactColumns = [
    {
      name: "First Name",
      selector: (row) => row.first_name,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.last_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => row.message.substring(0.3),
      sortable: true,
    },
    {
      name: "Seen",
      selector: (row) => {
        if (isSeen(row)) {
          return <p className="text-success"> Seen</p>;
        } else {
          return <p className="text-danger"> Not Seen</p>;
        }
      },
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <button
            className="btn btn-primary m-1 w-50"
            onClick={() => setViewModal(row)}
          >
            View
          </button>
          <button
            className="btn btn-danger mx-2 w-50"
            onClick={() => handleDelete(row._id)}
            disabled={!isSeen(row)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
  const { error, isLoading } = useQuery("ContactList", getField);
  const [contactList, setContactList] = useState([]);
  async function getField() {
    const url = `/admin/contact`;
    try {
      const response = await getAxios(url);
      const fetchedContactList = response.data;
      setContactList(fetchedContactList.contact);
    } catch (error) {
      console.log(error);
    }
    return contactList;
  }
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  return (
    <>
      {showModal ? (
        <ContactView
          show={showModal}
          handleClose={handleClose}
          handleView={handleView}
          feedback={feedback}
        />
      ) : (
        <PageData
          columns={contactColumns}
          tableData={contactList}
          title="Contact"
          addRequired={false}
        />
      )}
    </>
  );
};

export default Contact;
