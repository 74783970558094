import React from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";

const Category = () => {
  const fileURL =
    process.env.REACT_APP_FILE_URL;

  const categoryColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Image",
      selector: (row) => row.image,
      cell: (row) => (
        <div className="rounded-circle">
          <img
            className="rounded-circle"
            src={fileURL + "/" + row.image} // Use the image URL from the data
            alt={row.title} // Provide alt text for accessibility
            width={50}
            height={50}
          />
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => <ActionButton row={row} pageTitle="category" />,
    },
  ];
  async function getField() {
    const url = `/admin/category`;
    try {
      const response = await getAxios(url);
      const fetchedCategoryList = response.data;
      return fetchedCategoryList.category;
    } catch (error) {
      console.log(error);
    }
  }

  const { data, error, isLoading } = useQuery("CategoryList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  return (
    <PageData columns={categoryColumns} tableData={data} title="Category" />
  );
};

export default Category;
