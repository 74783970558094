import React from "react";
import PageData from "../../PageData/PageData";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";
import { useQuery } from "react-query";
const Gmap = () => {
  const gmapColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Embedded",
      selector: (row) => row.embedded,
    },
    {
      name: "Action",
      cell: (row) => <ActionButton row={row} pageTitle="gmap" />,
    },
  ];
  async function getField() {
    const url = `/admin/gmap`;
    try {
      const response = await getAxios(url);
      const fetchedList = response.data;
      // console.log(fetchedList.map);
      return fetchedList.map;
    } catch (error) {
      console.log(error);
    }
  }
  const { data, error, isLoading } = useQuery("GmapList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }

  return (
    <>
      <PageData
        columns={gmapColumns}
        tableData={data}
        title="gmap"
        displayTitle="Google Map"
      />
    </>
  );
};

export default Gmap;
