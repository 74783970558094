import React from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";

const Vacancy = ({home=false}) => {
  const vacancyColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Employer",
      selector: (row) => row.employer && row.employer.company_name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    !home && {
      name: "Action",
      cell: (row) => <ActionButton row={row} pageTitle="vacancy" />,
    },
  ];

  async function getField() {
    const url = `/admin/vacancy`;
    try {
      const response = await getAxios(url);
      const fetchedList = response.data;
      return fetchedList.vacancy;
    } catch (error) {
      console.log(error);
    }
  }
  const { data, error, isLoading } = useQuery("VacancyList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  return (
    <>
      <PageData columns={vacancyColumns} tableData={data} title="Vacancy" home={home}/>
    </>
  );
};

export default Vacancy;
