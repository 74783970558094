import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import logo from "../../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { getCMS } from "../../../../helper/UserAxios";

const Footer = () => {
  const [content, setContent] = useState([]);
  const navigate = useNavigate();
  const getFunc = async () => {
    const res = await getCMS(
      "page_id=eb-3&page_id=eb-5&page_id=student-visa&page_id=visitor-visa&page_id=business-visa"
    );
    setContent(res.data);
  };
  useEffect(() => {
    getFunc();
  }, []);
  const handleContentPage = (contents) => {
    navigate(`/content/${contents.slug}`, { state: contents });
  };
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg={3} sm={6}>
            <div className="footer__box">
              <img src={logo} alt="" />
              <div className="footer__box-text m-t-30">
                Pine Visa Services is a group of professional lawyers &
                recruitment specialists with years of experience and dedication.
              </div>
            </div>
          </Col>
          <Col lg={3} sm={6}>
            <div className="footer__box footer__box-menu">
              <h2>Quick Links</h2>
              <ul>
                <li>
                  <Link to="/aboutus">About us</Link>
                </li>
                <li>
                  <Link to="/news">Latest News</Link>
                </li>
                <li>
                  <Link to="/media">Media Coverage</Link>
                </li>
                <li>
                  <Link to="/download">Downloads</Link>
                </li>
                <li>
                  <Link to="/blogs">Blogs</Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={3} sm={6}>
            <div className="footer__box footer__box-menu cursor-pointer">
              <h2>Visa Information</h2>
              <ul>
                <li>
                  <div onClick={() => handleContentPage(content[0])}>
                    EB 3 Visa
                  </div>
                </li>
                <li>
                  <div onClick={() => handleContentPage(content[1])}>
                    EB 5 Visa
                  </div>
                </li>
                <li>
                  <div onClick={() => handleContentPage(content[2])}>
                    Student Visa
                  </div>
                </li>
                <li>
                  <div onClick={() => handleContentPage(content[4])}>
                    Business Visa
                  </div>
                </li>
                <li>
                  <div onClick={() => handleContentPage(content[3])}>
                    Tourist or Visitor Visa
                  </div>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={3} sm={6}>
            <div className="footer__box footer__box-menu">
              <h2>Newsletter Signup</h2>
              <p>
                Enter your email address to get the latest updates and offers
                from us. Also, some discount coupons.
              </p>
              <div className="subscribe">
                <Form>
                  <Form.Control
                    type="text"
                    placeholder="Email Address"
                    className="bg-light text-dark"
                  />
                  <Button type="submit">Send</Button>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="last_footer">
        <Container>
          <div className="d-flex justify-content-between flex-wrap">
            <div className="copy">
              &copy; {new Date().getFullYear()}, All rights reserved. Designed &
              Developed By{" "}
              <a href="https://www.pioneersoftech.com">
                Pioneer Software Technologies .
              </a>
            </div>
            <div className="social-media footer__social-media">
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-youtube"></i>
              </a>
              <a href="#">
                <i className="fa-brands fa-instagram"></i>
              </a>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
