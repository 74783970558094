import React, { useState, useEffect } from "react";

import { getUserAxios, PostUserAxios } from "../../../helper/UserAxios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
// import UserPageData from "../GeneralUI/UserPageData/UserPageData";
import { QueryClientProvider, useQuery, useQueryClient } from "react-query";
import ActionButton from "../../Components/EDashboard/GeneralUI/ActionButton";
import { useNavigate } from "react-router-dom";

const EmployeeJob = () => {
  const fileURL = process.env.REACT_APP_FILE_URL;
  const client=useQueryClient();
  const navigate=useNavigate();
  const { data:files } = useQuery("UserFiles", getField);
  async function getField() {
    const url = `/employee/file`;
    try {
      const response = await getUserAxios(url);
      console.log(response);
      const fetchedUserList = response.data;
      return fetchedUserList.files;
    } catch (error) {
      console.log(error);
    }
  }
  
  const [applyForm,setApplyForm]=useState(false);
  useEffect(() => {
    const hasVacancyForm = files && files.some(item => item.title === "VacancyForm");
  
    setApplyForm(hasVacancyForm);
  }, [files]);
  
  const { data: vacancyUser, isLoading } = useQuery(
    "EmploJobList",
    getVacancyField
  );
  const handleApply = async (row) => {

    const res = await PostUserAxios("/employee/apply_vacancy", {
      vacancy: row._id,
    });

    if (res.success) {
      toast.success(res.message);
      client.refetchQueries("EmploJobList");
      navigate("/employee/dashboard");
    } else {
      toast.error(res.message);
    }
  };

  async function getVacancyField() {
    const url = `/employee/vacancy`;
    try {
      const response = await getUserAxios(url);
      return response.data.vacancy;
    } catch (error) {
      console.log(error);
    }
    
  }
  if (isLoading) {
    return <div>Loading......</div>;
  }
  const handleVacancyFormDownload=async()=>{
        const res = await getUserAxios(`/sysinfofile?config_name=eb3Form`)
        const link = document.createElement("a");
        link.href = fileURL + '/' + res.data.system_info_file[0].feature_file;
        document.body.appendChild(link);
        link.target = "_blank";
        link.click();
        document.body.removeChild(link);
  }
 
  
  return (
    <div className="main-content-inner">
      {vacancyUser.length > 0 ? (
        <div className="job-posts">
          {vacancyUser.map((item, index) => (
            <div key={index} className="job-item">
              <h3>{item.title}</h3>
              <div className="date-admin mb-4">
                <div>
                  <i className="fa-solid fa-pen-to-square mr-2 icon"></i>Posted
                  Date: {item.updatedAt.slice(0, 10)}
                </div>
                <div>
                  <i className="fa-solid fa-user mr-2 icon"></i>
                  {item.employer && item.employer.company_name}
                </div>
              </div>

              <div
                dangerouslySetInnerHTML={{
                  __html: item.description.slice(0, 300),
                }}
              />
              <ActionButton row={item} noApply />
              <button className="btn btn-primary" onClick={handleVacancyFormDownload}>Download Form</button>
              <div className="info">Upload the form after filling in Documents Section with the file Name VacancyForm and Apply to the vacancy</div>
              <button
          className="btn btn-success m-1" disabled={!applyForm}
          onClick={() => handleApply(item)}
        >
          Apply
        </button>

            </div>
          ))}
        </div>
      ) : (
        <h2 className="text-center">No Suitable Jobs for you</h2>
      )}
    </div>
  );
};

export default EmployeeJob;
