import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Footer, NavBar } from "../../Components/GeneralUI";
import { Spinner } from "react-bootstrap";

const NotFound = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, [])

  return (
    <>
      {loading ? (<div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Background color with 50% opacity
          backdropFilter: 'blur(10px)', // Add blur effect
          zIndex: 9999, // Make sure it's on top of other elements
        }}
      >
        <Spinner animation="border" variant="primary" size="xl" style={{ width: "100px", height: "100px" }} />
      </div>) : (
        <div className="nmi">
          <NavBar />
          <div className="body-wrapper p-t-80 p-b-60">
            <div className="container">
              <div className="not-found">
                <div className="not-found__404">Oops! Error 404</div>
                <div className="not-found__content">
                  <h1 className="not-found__title">Page Not Found</h1>
                  <p className="not-found__text">
                    We can't seem to find the page you're looking for.
                    <br />
                    Try to read our{" "}
                    <strong>
                      <Link to="/"> blogs.</Link>
                    </strong>
                  </p>

                  <p className="not-found__text">
                    Or go to the home page to start over.
                  </p>
                  <Link to="/" className="btn btn-secondary btn-sm m-b-10">
                    Go To Home Page
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>)}
    </>
  );
};

export default NotFound;
