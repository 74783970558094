import React, { useEffect, useState } from "react";
import { getUserAxios } from "../../../../helper/UserAxios";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import ActionButton from "./ActionButton";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const EmpOffering = () => {
  const [content, setContent] = useState([]);
  const navigate = useNavigate();
  const getFunc = async () => {
    const res = await getUserAxios('/vacancy');
    return (res.data.vacancy);
  };
  const { data, isLoading, error } = useQuery("EmployerOffering", getFunc)
  console.log(data)
  
  const token = Cookies.get("employeetoken");
  const handleViewMore = () => {
    if (!token) {
      toast.error("Please Login First");
      navigate("/employee/login");
    }
    else{
      navigate("/employee/dashboard");

    }
  };
  useEffect(() => {
    getFunc();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="edashboard">
      <div className="main-content-inner">
        {data && data.length > 0 ? (
          <div className="job-posts">
            {data.map((item, index) => (
              <div key={index} className="job-item">
                <h3>{item.title}</h3>
                <div className="date-admin mb-4">
                  <div>
                    <i className="fa-solid fa-pen-to-square mr-2 icon"></i>Posted
                    Date: {item.updatedAt.slice(0, 10)}
                  </div>
                  <div>
                    <i className="fa-solid fa-user mr-2 icon"></i>
                    {item.employer && item.employer.org_name}
                  </div>
                </div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: item.description.slice(0, 300),
                  }}
                />
                <buton className="btn btn-primary" onClick={handleViewMore}>
                  Detail
                </buton>
              </div>
            ))}
          </div>
        ) : (
          <h2 className="text-center">No Suitable Jobs for you</h2>
        )}
      </div>
    </div>
  );
};

export default EmpOffering;
