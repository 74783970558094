import React, { useState, useEffect } from "react";
import { logo } from "../../../assets/images";
import { Link, NavLink, useLocation } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { loggedIn } from "../../../Routes/emp.routing";
import EmpInfo from "../../../GenNav/EmpInfo";
const NavBar = () => {
  const [isLogged, setIsLogged] = useState(false);
  const location = useLocation();
  const [data, setData] = useState([]);
  const [activeState, setActiveIndex] = useState(null);
  const [employer, setEmployer] = useState();
  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];
    switch (lastSegment.toLowerCase()) {
      case "service":
        setActiveIndex(1);
        break;
      case "offering":
        setActiveIndex(2);
        break;
      default:
        setActiveIndex(0); // Or any default value if the variable doesn't match any case
    }
  }, [location.pathname]);
  useEffect(() => {
    const status = loggedIn();
    setIsLogged(status);
    setEmployer(localStorage.getItem("Employer") && JSON.parse(localStorage.getItem("Employer")));
    setData(localStorage.getItem("EmpInfoList") && JSON.parse(localStorage.getItem("EmpInfoList")));
  }, [isLogged]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const handleMenu = () => {
    if (window.innerWidth < 992) {
      setIsMenuOpen(!isMenuOpen);
    }
  };
  const CloseMenu = () => {
    if (window.innerWidth < 992) {
      setIsMenuOpen(false);
    }
  };

  return (
    <>
      <ScrollToTop
        smooth={true}
        className="rounded-circle bg-black "
        color="white"
      />
      <div
        className={`bsnav-mobile-overlay ${isMenuOpen ? "active" : ""} `}
        onClick={CloseMenu}
      ></div>
      <header className="header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3 col-lg-2 col-7">
              <NavLink to="/" href="" className="logo">
                <img src={logo} alt="Logo" />
              </NavLink>
            </div>
            <div className="col-md-9 col-lg-10 col-5">
              <div className="header__middle-right d-flex justify-content-end align-items-center">
                <nav className="menu desktop-menu">
                  <nav
                    className={`navbar navbar-expand-lg navbar-dark bg-dark navdiv  ${!isMenuOpen}?'active' : ''`}
                  >
                    <ul className="navbar-nav align-items-center nav-list">
                      <li
                        className={`nav-item ${activeState === 1 ? "active" : ""
                          }`}
                      >
                        <NavLink className="nav-link" to="/service">
                          Services
                          {activeState === 1 && (
                            <span className="sr-only">(current)</span>
                          )}
                        </NavLink>
                      </li>
                      <li
                        className={`nav-item ${activeState === 2 ? "active" : ""
                          }`}
                      >
                        <NavLink to="/offering" className="nav-link">
                          Employer Offerings
                        </NavLink>
                      </li>
                    </ul>
                  </nav>
                </nav>
                {!isLogged ? (
                  <div className="btn-outer desktop--btn-outer">
                    <NavLink
                      to="/employee/register"
                      className="btn btn--white btn--register"
                    >
                      <i className="fa-solid fa-pen-to-square me-2"></i>Register
                    </NavLink>

                    <NavLink
                      className={`btn btn--white btn--register ms-2 ${isDropdownOpen ? "show" : "bg-white"
                        }`}
                      id="dropdownMenuButton1"
                      onClick={toggleDropdown}
                    >
                      <i className="fa-solid fa-pen-to-square me-2"></i>
                      Login
                    </NavLink>

                    {isDropdownOpen && (
                      <ul
                        className={`dropdown-menu ${isDropdownOpen ? "show drp-css" : ""
                          }`}
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <NavLink
                            className="dropdown-item"
                            to="/employee/login"
                            onClick={toggleDropdown}
                          >
                            Login as an employee
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className="dropdown-item"
                            to="/employer/login"
                            onClick={toggleDropdown}
                          >
                            Login as an employer
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </div>
                ) : (
                  <EmpInfo data={data} employer={employer} />
                )}

                <button
                  className={`navbar-toggler toggler-spring ${isMenuOpen ? "active" : ""
                    }`}
                  onClick={handleMenu}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <nav className="menu mobile-menu">
          <nav
            className={`navbar navbar-expand-lg navbar-dark bg-dark navdiv  ${isMenuOpen ? "active" : ""
              }`}
          >
            <ul className="navbar-nav nav-list">
              <li className="nav-item active">
                <a className="nav-link" href="index.html">
                  Services
                  <span className="sr-only">(current)</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="about-us.html">
                  Employer Offerings
                </a>
              </li>
            </ul>
            {!isLogged ? (
              <div className="btn-outer mobile--btn-outer">
                <NavLink
                  to="/register"
                  className="btn btn--white btn--register"
                >
                  <i className="fa-solid fa-pen-to-square me-2"></i>Register
                </NavLink>
                <NavLink
                  className="btn btn--white btn--register ms-2"
                  onClick={toggleDropdown}
                >
                  <i className="fa-solid fa-pen-to-square me-2"></i>Login
                </NavLink>
                {isDropdownOpen && (
                  <ul
                    className={`dropdown-menu ${isDropdownOpen ? "show drp-css" : ""
                      }`}
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <NavLink className="dropdown-item" to="/employee/login">
                        Login as an employee
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/employer/login">
                        Login as an employer
                      </NavLink>
                    </li>
                  </ul>
                )}
              </div>
            ) : (
              ""
            )}
          </nav>
        </nav>
      </header>
    </>
  );
};

export default NavBar;
