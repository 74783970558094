import React, { useState, useRef, useEffect } from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import "./Form.css";
import { useQueryClient } from "react-query";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { UserSchema } from "./Schemas";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios, getAxios } from "../../../helper/AxiosHelper";


const UserForm = ({ edit }) => {
  const baseURL =
    process.env.REACT_APP_FILE_URL;
  const location = useLocation();
  const client = useQueryClient();
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const [images, setImages] = useState("");

  const handlePost = async (e) => {
    const formData = new FormData();
    for (const key in values) {
      if (key === "image") {
        continue;
      }
      formData.append(key, values[key]);
    }
    if (!edit) {
      if (InitialState.image instanceof File) {
        formData.append("image", InitialState.image);
      } else {
        formData.append("image", InitialState.image);
      }
    } else {
      if (edit && images instanceof File) {
        formData.append("image", images);
      } else {
        formData.append("image", InitialState.image);
      }
    }
    const res = edit
      ? await PatchAxios(`/admin/user/${location.state._id}`, formData)
      : await PostAxios("/admin/user/create", formData);

    if (res.success) {
      navigate("/admin/user");
      toast.success(res.message);
      client.refetchQueries("UserList");
    } else {
      toast.error(res.message);
    }
  };
  const InitialState = {
    name: edit ? location.state.name : "",
    email: edit ? location.state.email : "",
    phone: edit ? location.state.phone : "",
    password: edit ? location.state.password : "",
    re_password: edit ? location.state.password : "",
    status: edit ? location.state.status : "active",
    role_id: edit ? location.state.role_id._id : "",
    address: edit ? location.state.address : "",
    image: edit ? location.state.image : images,
  };

  function handleImageClick(e) {
    imageRef.current.click();
  }
  function handleImageChange(e) {
    const file = e.target.files[0];
    setImages(file);
  }
  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: UserSchema,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });
  const [roles, setRoles] = useState([]);
  const getRole = async (e) => {
    const res = await getAxios("/admin/role");
    setRoles(res.data.roles);
  };
  useEffect(() => {
    getRole();
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <h2 className="text-center text-muted mb-2">
          {edit ? "Update" : "Add"} User
        </h2>
        <div className="image_holder">
          {!images ? (
            <img
              src={
                edit && InitialState.image
                  ? `${baseURL}/${InitialState.image}`
                  : "https://media.istockphoto.com/id/1248723171/nl/vector/camera-pictogram-van-het-foto-uploaden-op-ge%C3%AFsoleerde-witte-achtergrond-eps-10-vector.jpg?s=612x612&w=0&k=20&c=WLTMjPeoFbET0aBnnJmLyzDE1wUkJu9nj07KPtmf8qg="
              }
              className="rounded-circle"
              alt="Cinque Terre"
              height={200}
              width={200}
              onClick={handleImageClick}
            />
          ) : (
            <img
              src={URL.createObjectURL(images)}
              alt="bannerInput"
              className="rounded-circle"
              height={200}
              width={200}
              onClick={handleImageClick}
            />
          )}
          <input
            type="file"
            ref={imageRef}
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
        <Row className="m-2 p-2">
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="name">
                Name
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  id="name"
                  placeholder="Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.name && touched.name ? (
                <p className="text-danger">{errors.name}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="email">
                Email<span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  id="email"
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
              </InputGroup>
              {errors.email && touched.email ? (
                <p className="text-danger">{errors.email}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="phone">
                Phone<span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  id="phone"
                  placeholder="Phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
              </InputGroup>
              {errors.phone && touched.phone ? (
                <p className="text-danger">{errors.phone}</p>
              ) : null}
            </Form.Group>
          </Col>

          {!edit && (
            <Col md={6}>
              <Form.Group>
                <Form.Label htmlFor="password">
                  Password<span className="text-danger">*</span>
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="password"
                    id="password"
                    placeholder="Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                </InputGroup>
                {errors.password && touched.password ? (
                  <p className="text-danger">{errors.password}</p>
                ) : null}
              </Form.Group>
            </Col>
          )}
          {!edit && (
            <Col md={6}>
              <Form.Group>
                <Form.Label htmlFor="password">
                  Retype Password<span className="text-danger">*</span>
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="password"
                    id="re_password"
                    placeholder="Retype Password"
                    name="re_password"
                    value={values.re_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                </InputGroup>
                {errors.re_password && touched.re_password ? (
                  <p className="text-danger">{errors.re_password}</p>
                ) : null}
              </Form.Group>
            </Col>
          )}
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="status">Status</Form.Label>
              <Form.Select
                className="form-select form-select-sm mb-3"
                aria-label="Default select example"
                name="status"
                value={values.status}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Select One</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Select>
              {errors.status && touched.status ? (
                <p className="text-danger">{errors.status}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="role_id">
                Role <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                className="form-select form-select-sm mb-3"
                aria-label="Default select example"
                name="role_id"
                value={values.role_id}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              >
                {!edit ? (
                  <option value="">Select One</option>
                ) : (
                  <option value={InitialState.role_id}>
                    {location.state.role_id.name}
                  </option>
                )}
                {roles.map(
                  (role) =>
                    role._id !== InitialState.role_id && (
                      <option key={role._id} value={role._id}>
                        {role.name}
                      </option>
                    )
                )}
              </Form.Select>
              {errors.role_id && touched.role_id ? (
                <p className="text-danger">{errors.role_id}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={8}>
            <Form.Group>
              <Form.Label htmlFor="address">Address</Form.Label>
              <div className="input-group">
                <Form.Control
                  as="textarea"
                  rows={1}
                  className="form-control"
                  placeholder="Address"
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.address && touched.address ? (
                  <p className="text-danger">{errors.address}</p>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <div className="mt-4 d-flex justify-content-between">
            <Button
              type="submit"
              className={`btn btn-primary  `}
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting}
            >
              {edit
                ? isSubmitting
                  ? "Updating...."
                  : "Update"
                : isSubmitting
                  ? "Submitting...."
                  : "Submit"}
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default UserForm;
