import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { multiDelAxios, getAxios } from "../../../helper/AxiosHelper";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const PageData = ({
  home,
  columns,
  tableData,
  title,
  addRequired = true,
  displayTitle,
}) => {
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  // const [filteredData, setFilteredData] = useState(tableData);
  const [search, SetSearch] = useState(null);
  const [data, setData] = useState(tableData);
  const [option, setOption] = useState([10, 20, 30, 50, 100]);
  const [defaultPagination, setDefaultPagination] = useState(20);
  const perm = useSelector((state) => state.admin.permission);
  const curr_perm =
    perm && perm.find((item) => item.title === title.toLowerCase());
  const level = localStorage.getItem("level");

  useEffect(() => {
    setData(tableData);
  }, [tableData]);
  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  async function getField() {
    const url = `/admin/pagelimit`;
    try {
      const response = await getAxios(url);
      const dataList = response.data;
      const limitNos =
        dataList.page_limit && dataList.page_limit.map((item) => item.limit_no);

      setDefaultPagination(dataList.page_limit_default[0].limit_no);
      setOption(limitNos);
    } catch (error) {
      console.log(error);
    }
  }
  const navigate = useNavigate();
  const handleFormAdd = () => {
    navigate(`/admin/${title.toLowerCase()}/add`);
  };
  const resetSearch = () => {
    SetSearch("");
    setData(tableData);
  };
  const handleSearch = (e) => {
    const params = e.target.value;

    SetSearch(params);
    if (columns) {
      const result = tableData.filter((item) => {
        const { _id, createdAt, updatedAt, ...itemWithoutId } = item;
        const concatenatedString = Object.values(itemWithoutId).join(" ");
        return concatenatedString.toLowerCase().includes(params.toLowerCase());
      });
      setData(result);
    }
    return;
  };
  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#ccc",
      },
    },
    subHeader: {
      header: {
        margin: 0, // Set the margin for the subheader to zero
      },
    },
  };
  const handleMultiDelete = async () => {
    const idArray = selectedRows.map((item) => item._id);
    const data = {
      [`${title.toLowerCase()}_id`]: idArray,
    };
    const res = await multiDelAxios(`/admin/${title.toLowerCase()}`, data);
    if (res.success) {
      navigate(`/admin/${title.toLowerCase()}`);
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };
  const { error, isLoading } = useQuery("PageLimitList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  return (
    <>
      {!home &&
        <h1 className="h1 text-center text-white">
          {displayTitle ? displayTitle : title}
        </h1>
      }

      {((curr_perm && curr_perm.add) || level === "1") && addRequired && (
        !home && (<div className="d-flex justify-content-between">
          <Button className="btn btn-primary my-2" onClick={handleFormAdd}>
            Add {displayTitle ? displayTitle : title}
          </Button>

          {selectedRows.length > 0 &&
            title.toLowerCase() !== "role" &&
            title.toLowerCase() !== "permission" && (
              <button
                className="btn btn-danger my-2"
                onClick={handleMultiDelete}
              >
                Delete Selected
              </button>
            )}
        </div>)

      )}


      <DataTable
        customStyles={tableHeaderstyle}
        pagination={!home}
        columns={columns}
        data={data}
        selectableRows={!home}
        highlightOnHover
        fixedHeader
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        paginationRowsPerPageOptions={option}
        paginationPerPage={defaultPagination}
        subHeader={!home}
        subHeaderComponent={
          <>
            <button className="btn btn-danger m-1" onClick={resetSearch}>
              Reset
            </button>
            <input
              type="text"
              className="w-25 form-control bg-light text-dark"
              placeholder="Search..."
              value={search}
              onChange={handleSearch}
            />
          </>
        }

      // subHeaderAlign="right"
      />
    </>
  );
};
export const ResetDatas = () => { };
export default PageData;
