import React, { useState, useEffect } from "react";
import { getCMS } from "../../../helper/UserAxios";
import { Link, useNavigate } from "react-router-dom";
const HeroSection = () => {
  const fileURL = process.env.REACT_APP_FILE_URL;
  const [herosec, setHerosec] = useState([]);
  useEffect(() => {
    getFunc();
  }, []);

  const getFunc = async () => {
    const res = await getCMS("page_id=hero-section");
    setHerosec(res.data[0]);
  };
  const navigate = useNavigate();
  const handleContentPage = (contents) => {
    navigate(`/content/${contents.slug}`, { state: contents });
  };
  return (
    <section className="hero hero-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="hero-content">
              <h2 className="hero-title">{herosec.title}</h2>
              <p>{herosec.meta_desc}</p>

              {herosec.desc && herosec.desc !== "<p><br></p>" && (
                <div
                  className="btn btn--accent mt-3 btn--big cursor-pointer"
                  onClick={() => handleContentPage(herosec)}
                >
                  view more
                </div>
              )}
            </div>
          </div>
          <div className="col-md-5">
            <img
              src={fileURL + "/" + herosec.seo_image}
              alt={herosec.title}
              className="floating"
              id="hero-img"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
