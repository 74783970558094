import React, { useEffect, useState } from "react";
import { foldedLogo, logo } from "../../../assets/images";
import { Link, NavLink, useLocation } from "react-router-dom";
import { getUserAxios } from "../../../../helper/UserAxios";

const ASideNav = ({ handleBarClick, employer = false }) => {
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [steps,setSteps]=useState();
  const getSteps=async()=>{
    const res=await getUserAxios('/employee/steps');
    setSteps(res.data.step)   
  }
  const handleLinkChange = () => {
    if (window.matchMedia("(min-width: 779px)").matches) {
      return;
    }
    handleBarClick();
  };
  useEffect(() => {
    
    const pathSegments = location.pathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];
    switch (lastSegment.toLowerCase()) {
      case "dashboard":
        setActiveIndex(0);
        break;
      case "profile":
        setActiveIndex(1);
        break;
      case "jobs":
        setActiveIndex(2);
        break;
      case "application":
        setActiveIndex(3);
        break;
      default:
        setActiveIndex(0); // Or any default value if the variable doesn't match any case
    }
    getSteps();
  }, [location.pathname]);
  return (
    <>
      <aside className="sidebar" id="sidebar">
        <div className="close-menu" onClick={handleBarClick}>
          <i className="fas fa-times"></i>
        </div>
        <div className="sidebar__brand">
          <Link to="/" className="main-logo">
            <img src={logo} alt="" />
          </Link>
          <NavLink to="/" className="shrink-logo">
            <img src={foldedLogo} alt="Folded" />
          </NavLink>
        </div>

        <nav className="sidebar__menu">
          <ul>
            <li
              className={`sidebar__menu-item  ${
                activeIndex === 0 ? "active" : ""
              }`}
            >
              <Link
                to={employer ? "/employer/dashboard" : "/employee/dashboard"}
                onClick={handleLinkChange}
              >
                <i className="fas fa-tachometer-alt"></i>
                <span>Dashboard</span>
              </Link>
            </li>
            {!employer && (
              <li
                className={`sidebar__menu-item  ${
                  activeIndex === 1 ? "active" : ""
                }`}
              >
                <Link to="/employee/profile" onClick={handleLinkChange}>
                  <i className="fa-solid fa-user"></i> <span>Profile</span>
                </Link>
              </li>
            )}
            {steps && steps.onboarding.appointment==="done" && steps.onboarding.zoom_link==="done" && steps.onboarding.meeting==="done" &&
            <li
              className={`sidebar__menu-item  ${
                activeIndex === 2 ? "active" : ""
              }`}
            >
              <Link
                to={employer ? "/employer/jobs" : "/employee/jobs"}
                onClick={handleLinkChange}
              >
                <i className="fa-solid fa-briefcase"></i> <span>Jobs</span>
              </Link>
            </li>
        }
            <li
              className={`sidebar__menu-item  ${
                activeIndex === 3 ? "active" : ""
              }`}
            >
              <Link
                to={
                  employer ? "/employer/application" : "/employee/application"
                }
                onClick={handleLinkChange}
              >
                <i className="fa-solid fa-file"></i>
                <span>Documents</span>
              </Link>
            </li>
          </ul>
        </nav>
      </aside>
    </>
  );
};

export default ASideNav;
