import React, { useState, useRef } from "react";


const EmpSteps = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const pickerRef = useRef();

  const openDatePicker = () => {
    // Programmatically open the date picker
    if (pickerRef.current) {
      pickerRef.current.openCalendar();
    }
  };

  return (
    <main className="body-wrapper inner p-t-40 p-b-60">
      <div className="main-content-inner">
        <div className="container">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi sunt
          aliquid quo quod minima velit dolores eveniet voluptatibus reiciendis
          hic dolor quidem, aut, sapiente unde totam veritatis quisquam. Placeat
          aliquam consectetur numquam odit ex minus rerum eveniet atque cumque?
          Libero, aliquam?
          {/* <DateTimePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            ref={pickerRef}
            className="hidden-input"
          /> */}
          {/* <button onClick={openDatePicker}>Open DatePicker</button> */}
        </div>
      </div>
    </main>
  );
};

export default EmpSteps;
