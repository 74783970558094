import React, { useState, useEffect } from "react";
import { getCMS } from "../../../helper/UserAxios";

const Step = () => {
  const fileURL = process.env.REACT_APP_FILE_URL;
  const [stepsec, setStepsec] = useState([]);
  const [stepList, setStepList] = useState([]);
  const [active, SetActive] = useState("Step-1");

  useEffect(() => {
    getFunc();
  }, []);

  const getFunc = async () => {
    const res = await getCMS(
      "page_id=step-main&page_id=list-step-1&page_id=list-step-2&page_id=list-step-3&page_id=list-step-4&page_id=list-step-5&page_id=list-step-6"
    );
    setStepsec(res.data[0]);
    setStepList(res.data.slice(1));
  };
  return (
    <section className="work work--bg p-tb-60">
      <div className="container">
        <div className="section-title section-title--white m-b-30">
          <h2>{stepsec.title}</h2>
        </div>
        <p className="text-center">{stepsec.meta_desc}</p>
        <div className="word-tab m-t-50">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            {stepList.map((step) => (
              <li className="nav-item" role="presentation" key={step.title}>
                <button
                  className={`nav-link ${
                    step.title === active ? "active" : ""
                  }`}
                  id={`${step.title.toLowerCase()}-tab`}
                  data-bs-toggle="tab"
                  data-bs-target={`#${step.title.toLowerCase()}`}
                  type="button"
                  role="tab"
                  aria-controls={`${step.title.toLowerCase()}`}
                  aria-selected={true}
                >
                  <img src={fileURL + "/" + step.seo_image} alt="" />
                  <span>{step.title}</span>
                </button>
              </li>
            ))}
          </ul>
          <div className="tab-content" id="myTabContent">
            {stepList.map((step) => (
              <div
                key={step.title}
                className={`tab-pane fade ${
                  step.title === active ? "show active" : ""
                }`}
                id={step.title.toLowerCase()}
                role="tabpanel"
                aria-labelledby={`${step.title.toLowerCase()}-tab`}
              >
                {step.meta_desc && (
                  <h3 className="tab-content-title m-b-30">{step.meta_desc}</h3>
                )}
                {/* <p> */}
                <div dangerouslySetInnerHTML={{ __html: step.desc }} />
                {/* </p> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Step;
