import React from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";


const Blog = () => {
  const fileURL =
    process.env.REACT_APP_FILE_URL;

  const blogColumns = [
    {
      name: "Title",
      selector: (row) => row.title.substring(0,20),
      sortable: true,
    },
    {
      name: "Short Description",
      selector: (row) => row.short_desc.substring(0,20),
      sortable: true,
    },
    {
      name: "Seo Image",
      selector: (row) => row.seo_image,
      cell: (row) => (
        <div className="rounded-circle">
          <img
            className="rounded-circle"
            src={fileURL + "/" + row.seo_image} // Use the image URL from the data
            alt={row.title} // Provide alt text for accessibility
            width={50}
            height={50}
          />
        </div>
      ),
    },
    {
      name: "Featured Image",
      selector: (row) => row.featured_image,
      cell: (row) => (
        <div className="rounded-circle">
          <img
            className="rounded-circle"
            src={fileURL + "/" + row.featured_image} // Use the image URL from the data
            alt={row.title} // Provide alt text for accessibility
            width={50}
            height={50}
          />
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <ActionButton row={row} pageTitle="blog" />
      ),
    },
  ];
  async function getField() {
    const url = `/admin/blog`;
    try {
      const response = await getAxios(url);
      const fetchedBlogList = response.data;
      return fetchedBlogList.blogs;
    } catch (error) {
      console.log(error);
    }
  }

  const { data, error, isLoading } = useQuery("BlogList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }

  return (
    <>
      <PageData columns={blogColumns} tableData={data} title="Blog" />
    </>
  );
};

export default Blog;
