import React from "react";
import { Modal, Button } from "react-bootstrap";
import VacancyDetail from "../../../../Home/Components/EDashboard/GeneralUI/VacancyDetail";

const ConfirmationModal = ({
  show,
  handleClose,
  handleConfirm,
  Content,
  isDetail = false,
  employer,
  employerApp,
}) => {
  console.log(Content);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      scrollable
      className="not-confirm"
      size={isDetail ? "lg" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {employerApp ? "Employee Details" : Content.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isDetail ? (
          Content.body
        ) : (
          <VacancyDetail
            vacancy={Content}
            employer={employer}
            employerApp={employerApp}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between">
          <Button variant="success" className="mx-5">
            Apply
          </Button>

          {!isDetail && (
            <Button
              variant="danger"
              onClick={() => {
                handleConfirm(Content.id);
              }}
            >
              Delete
            </Button>
          )}
          <Button
            variant="danger"
            className="btn btn-danger"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
