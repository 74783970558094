import React, { useState } from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import { PostAxios, PatchAxios } from "../../../helper/AxiosHelper";

import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { RoleSchema } from "./Schemas";
import { useQueryClient } from "react-query";
const RoleForm = ({ edit }) => {
  const location = useLocation();
  const client = useQueryClient();
  const navigate = useNavigate();
  const InitialState = {
    name: edit ? location.state.name : "",
    level: edit ? location.state.level : 5,
    description: edit ? location.state.description : "",
    status: edit ? location.state.status : "active",
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: RoleSchema,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });
  const handlePost = async (e) => {
    const res = edit
      ? await PatchAxios(`/admin/role/${location.state._id}`, values)
      : await PostAxios("/admin/role", values);
    if (res.success) {
      client.refetchQueries("RoleList");
      navigate("/admin/role");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
      return true;
    } else {
      toast.error(res.message);
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <h2 className="text-center text-muted">Add Role</h2>
        <Row className="m-2 p-2">
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="name">
                Name
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  id="name"
                  placeholder=" Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.name && touched.name ? (
                <p className="text-danger">{errors.name}</p>
              ) : null}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="colourIdentifier">Level</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  id="colourIdentifier"
                  placeholder="Level"
                  name="level"
                  defaultValue={5}
                  value={values.level}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.level && touched.level ? (
                <p className="text-danger">{errors.level}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="residentType">Status</Form.Label>
              <Form.Select
                className="form-select form-select-sm mb-3"
                aria-label="Default select example"
                name="status"
                value={values.status}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={8}>
            <Form.Group>
              <Form.Label htmlFor="roomNumber">Description</Form.Label>
              <div className="input-group">
                <Form.Control
                  as="textarea"
                  rows={3}
                  className="form-control"
                  placeholder=" Description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </Form.Group>
          </Col>

          <div className="mt-4 d-flex justify-content-between">
            <Button
              type="submit"
              className={`btn btn-primary  `}
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting}
            >
              {/* {edit && "Update"}
              {!edit && "Submit"} */}
              {/* {edit
                ? isSubmitting && "Updating...."
                : !isSubmitting && "Submit"} */}
              {edit
                ? isSubmitting
                  ? "Updating...."
                  : "Update"
                : isSubmitting
                  ? "Adding...."
                  : "Submit"}
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default RoleForm;
