import React, { useState, useEffect } from "react";
import UseMultiStep from "./useMultiStep";
import { Step0, Step1, Step2, Step3, Step4, Step5 } from "./List";

import { useLocation } from "react-router-dom";

const UserRegister = ({ edit }) => {
  const location = useLocation();
  const [InitialState, setInitialState] = useState({
    first_name: edit ? location.state.first_name : "",
    last_name: edit ? location.state.last_name : "",
    dob: "",
    email: edit ? location.state.email : "",
    phone: edit ? location.state.phone : "",
    password: "",
    re_password: "",
    status: edit ? location.state.status : "active",
    tags: edit ? location.state.tags : "",
    role: edit ? location.state.role : "",
    country: edit ? location.state.country : "",
    //current
    current_country: " ",
    current_address: edit ? location.state.current_address : "",
    current_street: edit ? location.state.current_street : "",
    current_city_town: edit ? location.state.current_city_town : "",
    current_province_state: edit ? location.state.current_province_state : "",
    current_zipcode: edit ? location.state.zip_code : "",
    //permanent
    permanent_country: " ",
    permanent_address: edit ? location.state.current_address : "",
    permanent_street: edit ? location.state.current_street : "",
    permanent_city_town: edit ? location.state.current_city_town : "",
    permanent_province_state: edit ? location.state.current_province_state : "",
    permanent_zipcode: " ",
    //

    special_accommodation: edit
      ? location.state.special_accommodation
      : "false",
    physical_limitation: edit ? location.state.physical_limitation : "false",
    pending_us_asylum: edit ? location.state.pending_us_asylum : "false",
    deported_removed_from_us: edit
      ? location.state.deported_removed_from_us
      : "false",
    passport_front: null, // Assuming this is a file input
    passport_back: null, // Assuming this is a file input
    resume: null, // Assuming this is a file input
    message: "",
    pine_visa_cost: edit ? location.state.pine_visa_cost : "false",
    obligations: edit ? location.state.obligations : "false",
    work_history_disclaimer: edit
      ? location.state.work_history_disclaimer
      : "false",
    used_in_immigration_filing: edit
      ? location.state.used_in_immigration_filing
      : "false",
  });

  const updateState = (updateValues) => {
    console.log(updateValues);
    setInitialState((prev) => ({
      ...prev,
      first_name: updateValues.first_name,
      last_name: updateValues.last_name,
      email: updateValues.email,
      phone: updateValues.phone,
      password: updateValues.password,
      re_password: updateValues.re_password,
    }));
  };

  function handleNext() {
    next();
  }
  function handleBack() {
    back();
  }

  const allSteps = [
    <Step0
      next={handleNext}
      update={updateState}
      initialState={InitialState}
    />,
    <Step5 initialState={InitialState} back={handleBack} />,
    <Step1 back={handleBack} next={handleNext} />,
    <Step2 next={handleNext} back={handleBack} />,
    <Step3 next={handleNext} back={handleBack} />,
    <Step4 next={handleNext} back={handleBack} />,
  ];
  const { goTo, step, back, next } = UseMultiStep(allSteps);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.state && location.state.index) {
      goTo(location.state.index);
    }
  }, []);

  return <React.Fragment>{step}</React.Fragment>;
};

export default UserRegister;
