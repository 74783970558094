import * as Yup from "yup";

const EmployerSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your email")
    .email("Please enter a valid email")
    .strict()
    .trim(),
  phone: Yup.string().required("Please enter your phone number"),
  password: Yup.string().min(6),
  re_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
  slug: Yup.string(),
  org_logo: Yup.string(),
  status: Yup.string().oneOf(["active", "inactive"]).default("inactive"),
  company_name: Yup.string().required("Required"),
  dba: Yup.string(),
  signing_officer_first_name: Yup.string().required("Required"),
  signing_officer_middle_name: Yup.string(),
  signing_officer_last_name: Yup.string().required("Required"),
  signing_officer_job_title: Yup.string(),
  signing_officer_email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  //section-2
  street_number_name: Yup.string().required("Street Number & Name is required"),
  designation: Yup.string().required("Select Designation is required"),
  suite_floor_apartment_number: Yup.string().required(
    "Suite, Floor or Apartment Number is required"
  ),
  city: Yup.string().required("City is required"),
  county: Yup.string().required("County is required"),
  state: Yup.string().required("State is required"),
  zip_code: Yup.string().required("Zip Code is required"),
  country: Yup.string().required("Country is required"),
  is_mailing_same_as_physical: Yup.string().required(
    "Please specify if mailing address is the same as physical"
  ),
  //section4
  is_higher_education: Yup.string().required("This field is required"),
  is_nonprofit_affiliated: Yup.string().required("This field is required"),
  is_research_organization: Yup.string().required("This field is required"),
  is_primary_secondary_education: Yup.string().required(
    "This field is required"
  ),
  is_curriculum_related_training: Yup.string().required(
    "This field is required"
  ),
  is_small_employer: Yup.string().required("This field is required"),
  employ_50_or_more: Yup.string().required("This field is required"),
  more_than_50_percent_h1b: Yup.string().required("This field is required"),
  received_tarp: Yup.string().required("This field is required"),
  has_collective_bargaining_agreement: Yup.string().required(
    "This field is required"
  ),
  //optional
  sth_street_number: Yup.string(),
  sth_designation: Yup.string(),
  sth_actual_designation: Yup.string(),
  sth_city: Yup.string(),
  sth_state: Yup.string().length(2, "Invalid"),
  sth_zip_code: Yup.string()
    .nullable()
    .transform((value, originalValue) =>
      originalValue.trim() === "" ? null : value
    )
    .test("is-valid-zip-code", "Invalid Zip Code. Must be 5 digits", (value) =>
      value ? /^\d{5}$/.test(value) : true
    ),
  sth_ein: Yup.string(),
  sth_state_tax_id: Yup.string(),
  sth_daytime_tel: Yup.string(),
  sth_mobile_tel: Yup.string(),
  sth_fax: Yup.string(),
  sth_website: Yup.string(),
  sth_business_type: Yup.string(),
  sth_naics_code: Yup.string(),
  sth_date_established: Yup.string(),
  sth_current_employees: Yup.string(),
  sth_h1b_employees: Yup.string(),
  sth_intended_employment: Yup.string(),
  sth_about_us: Yup.string(),
  sth_h1b_dependent: Yup.string(),
  sth_willful_violator: Yup.string(),
  sth_gross_revenue: Yup.string(),
  sth_net_income: Yup.string(),
});

export default EmployerSchema;
