import React, { useState, useEffect } from "react";
import UserPageData from "../../Components/GeneralUI/UserPageData/UserPageData";
import { toast } from "react-toastify";
import { PatchUserAxios, PostUserAxios, delUserAxios, getUserAxios } from "../../../helper/UserAxios";
import ActionButton from "../../Components/EDashboard/GeneralUI/ActionButton";
import { useQuery, useQueryClient } from "react-query";
import { Row, Col, Container } from "react-bootstrap";
import { PdfIcon } from "../../assets/images";
import { FileUploader } from "react-drag-drop-files";
const Application = ({ employer = false }) => {
  const fileURL = process.env.REACT_APP_FILE_URL;
  const [names,setNames]=useState('');
  const [edit,setEdit]=useState('');
  const handleEdit = (fileId) => {
    setEdit(fileId)
    
  };
  const [file, setFile] = useState(null);
  const [editFile, setEditFile] = useState(null);
  const client=useQueryClient();

  const handleChange = (file) => {
    setFile(file);
  };

  const handleEditChange = (file) => {
    setEditFile(file);
  };

  const handleDelete = async(fileId) => {
    const res=await delUserAxios(`/employee/file/${fileId}`);
    if(res.success){
      toast.success(res.message)
      client.refetchQueries('UserFiles');

    }
    else{
        toast.error(res.message)
    }
  };
  async function getField() {
    const url = `/employee/file`;
    try {
      const response = await getUserAxios(url);
      console.log(response);
      const fetchedUserList = response.data;
      return fetchedUserList.files;
    } catch (error) {
      console.log(error);
    }
  }
  const { data, error, isLoading } = useQuery("UserFiles", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  const handleSubmit=async()=>{
    const formData=new FormData();
    formData.append("title",names)
    formData.append("file",file)

    const res=await PostUserAxios('/employee/file',formData);
    if(res.success){
      toast.success(res.message)
      client.refetchQueries('UserFiles');
      setNames('');
    }
    else{
        toast.error(res.message)
    }

  }
  const handleUpdate=async(fileId)=>{
    const formData=new FormData();
    formData.append("file",editFile)

    const res=await PatchUserAxios(`/employee/file/${fileId}`,formData);
    if(res.success){
      toast.success(res.message)
      setEdit('')
    }
    else{
        toast.error(res.message)
    }

  }
  
  return (
    <>
      <main className="m-t-80 m-l-20 m-r-20">
        <h1 className="text-center">Documents</h1>
        <Container>
          <div className="grid grid--3">
            {data.length > 0 ? (
              data.map((file) => (
                
                <div className="grid-item" key={file._id}>
                  {edit===file._id?
                    (
                      <div>
                    <FileUploader handleChange={handleEditChange}  maxSize={2} name={names}/>
                    <button
                        className="btn btn--primary btn--small"
                        onClick={() => handleUpdate(file._id)}
                      >
                        Update
                      </button>
                      </div>
                      ):
                (
                  <div className="filing-card">
                    <div className="filing-card-body">
                     <a href={fileURL+'/'+file.file} target="_blank"> <div className="w-100 text-center" >
                        {console.log(fileURL,file.file)}
                        <img
                          src={PdfIcon}
                          alt={`Thumbnail for ${file.title}`}
                          className="mb-3 mx-auto img-fluid"
                        />
                      </div>
                      </a>
                      <h3 className="text-center">{file.title}</h3>
                      <div className="d-flex justify-content-between">
                      <button
                        className="btn btn--primary btn--small"
                        onClick={() => handleEdit(file._id)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-danger btn-small"
                        onClick={() => handleDelete(file._id)}
                      >
                        Delete
                      </button>
                      </div>
                    </div>
                  </div>
                )}
                </div>
              ))
            ) : (
              <p>No data available</p>
            )}

          </div>
            <Row>
            <div className="">
              <div className="filing-card">
                <div className="filing-card-heading">
                  <h3 className="text-center">Add a new file</h3>
                </div>
                <div className="filing-card-body">
                  Enter file name
                  <input type="text" value={names} onChange={(e) => setNames(e.target.value)} required/>

                <FileUploader handleChange={handleChange}  maxSize={2} name={names}/>

                  
                  <button className="btn btn--primary btn--small" onClick={handleSubmit}>
                    Upload File
                  </button>
                </div>
              </div>
            </div>
            </Row>
        </Container>
      </main>
    </>
  );
};

export default Application;
