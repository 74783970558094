import * as Yup from "yup";

const BannerSchema = Yup.object().shape({
  title: Yup.string().required("Please enter a title"),
  image: Yup.string(),
  description: Yup.string(),
  link: Yup.string(),
  button_name: Yup.string(),
  display_order: Yup.string(),
  status: Yup.string().oneOf(["active", "inactive"]).default("inactive"),
});

export default BannerSchema;
