import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getUserAxios } from "../../../../helper/UserAxios";
import { Link, useNavigate } from "react-router-dom";
import { LoadingSpinner } from "../../../../helper";

const BlogList = () => {
  const fileURL = process.env.REACT_APP_FILE_URL;
  const getBlogs = async () => {
    const res = await getUserAxios("/blog");
    return res.data.blog.reverse();
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState([1]);
  const noPerPage = 6;
  const { data, isLoading, error } = useQuery("BLogListUser", getBlogs);

  useEffect(() => {
    const totalPagesCount = data ? Math.ceil(data.length / noPerPage) : 0;
    const totalPagesArray = Array.from(
      { length: totalPagesCount },
      (_, index) => index + 1
    );
    setTotalPage(totalPagesArray);
  }, [currentPage, data]);

  const navigate = useNavigate();
  const handleBlogDisplay = (blog) => {
    navigate(`/blog/${blog.slug}`, { state: blog });
  };
  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  return (
    <main className="body-wrapper p-tb-60">
      <div className="container">
        <div className="section-title m-b-40">
          <h2>Our Latest Blog</h2>
        </div>
        <div className="blog-page">
          <div className="container ">
            <div className="blogs">
              <div className="grid grid--3 blogs--card">
                {data &&
                  data
                    .slice(
                      (currentPage - 1) * noPerPage,
                      (currentPage - 1) * noPerPage + noPerPage
                    )
                    .map((blog) => (
                      <div className="grid-item" key={blog._id}>
                        <div className="card card-hvr">
                          <div
                            onClick={() => {
                              handleBlogDisplay(blog);
                            }}
                            className="card-img-link cursor-pointer"
                          >
                            <figure>
                              <img
                                src={`${fileURL}/${blog.featured_image}`}
                                alt={blog.title}
                              />
                              <i className="fas fa-arrow-right"></i>
                            </figure>
                          </div>
                          <div className="card-body">
                            <div className="card__date mb-2">
                              <span>{blog.createdAt.substring(0, 10)}</span>
                            </div>
                            <div
                              onClick={() => {
                                handleBlogDisplay(blog);
                              }}
                            >
                              <h3 className="card-title cursor-pointer">
                                {blog.title}
                              </h3>
                            </div>

                            <p className="card-text para-3">
                              {blog.meta_title.length > 50
                                ? `${blog.meta_title.substring(0, 50)}...`
                                : blog.meta_title}{" "}
                              <br />
                              {blog.meta_desc.length > 100
                                ? `${blog.meta_desc.substring(0, 100)}...`
                                : blog.meta_desc}
                            </p>

                            <div
                              onClick={() => {
                                handleBlogDisplay(blog);
                              }}
                              className="btnLink d-inline-flex align-items-center mt-3 cursor-pointer"
                            >
                              Read more
                              <i className="fa-solid fa-arrow-right-long ms-1"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
              <div className="pagination-outer text-center m-t-40">
                <ul className="pagination">
                  <li className="page-item">
                    <span
                      className={`page-link pren prev ${
                        currentPage === 1 ? "buttonbutnobtn" : ""
                      }`}
                      onClick={() => setCurrentPage(currentPage - 1)}
                      aria-label="Previous"
                    >
                      <span aria-hidden="true">
                        <i
                          className="fas
                                            fa-long-arrow-alt-left"
                        ></i>
                      </span>
                      <span className="sr-only">Previous</span>
                    </span>
                  </li>
                  {totalPage.map((page) => (
                    <li
                      className={`page-item pagination-active ${
                        page === currentPage ? "active" : ""
                      }`}
                      key={page}
                    >
                      <div
                        className="page-link"
                        onClick={() => setCurrentPage(page)}
                      >
                        {page}
                      </div>
                    </li>
                  ))}

                  <li className="page-item">
                    <span
                      className={`page-link pren prev ${
                        currentPage === totalPage.length ? "buttonbutnobtn" : ""
                      }`}
                      onClick={() => setCurrentPage(currentPage + 1)}
                      aria-label="Next"
                    >
                      <span aria-hidden="true">
                        <i
                          className="fas
                                            fa-long-arrow-alt-right"
                        ></i>
                      </span>
                      <span className="sr-only">Next</span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default BlogList;
