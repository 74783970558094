import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { useQuery, useQueryClient } from "react-query";
import { PatchAxios, getAxios } from "../../../../helper/AxiosHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ManagePermGroup = () => {
  const client = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const role = location.state;
  const checkedPerm = role && role.permission
  const roleId = role && role._id
  async function getRolePerm() {
    const url = `/admin/permission`;
    try {
      const response = await getAxios(url);
      return response.data.permission;
    } catch (error) {
      console.log(error);
    }
  }

  const [checkedPermissions, setCheckedPermissions] = useState(checkedPerm.map(permission => permission._id));
  console.log(checkedPermissions)
  const { data, isLoading, error } = useQuery('AllPerm', getRolePerm)

  const handlePermissionChange = (id) => {
    const updatedPermissions = [...checkedPermissions];
    const index = updatedPermissions.findIndex(
      (item) => item === id
    );

    if (index !== -1) {
      updatedPermissions.splice(index, 1);
    } else {
      updatedPermissions.push(id);
    }

    setCheckedPermissions(updatedPermissions);
  };
  const uncheckedItems = checkedPerm.filter(
    (permission) => !checkedPermissions.includes(permission._id)
  );
  const handleSubmit = async () => {
    const res = await PatchAxios(`/admin/permissiongroup/${roleId}/permission`, {
      push: checkedPermissions,
      pop: uncheckedItems.length > 0 ? uncheckedItems : [],
    })
    if (res.success) {
      toast.success(res.message)
      client.refetchQueries("PermissionGroupList");
      navigate('/admin/permissiongroup')
    }
    else {
      toast.error(res.message)
    }

  }
  console.log(data)

  return (
    <Container>
      <h1 className="text-white text-center">{role && role.name}</h1>
      <Row>
        {/* Display permissions starting with "get_" */}
        <Col lg={3} md={6} sm={12} className="mb-4">
          <Card className="text-white">
            <Card.Body>
              <Form>
                {data &&
                  data
                    .filter((permission) => permission.name.startsWith("get_"))
                    .map((permission) => (
                      <Form.Check
                        key={permission._id}
                        type="checkbox"
                        id={`${role.name}-${permission._id}`}
                        label={permission.name}
                        checked={checkedPermissions.some(
                          (item) => item === permission._id
                        )}
                        onChange={() => handlePermissionChange(permission._id)}
                      />
                    ))}
              </Form>
            </Card.Body>
          </Card>
        </Col>

        {/* Display permissions starting with "update_" */}
        <Col lg={3} md={6} sm={12} className="mb-4">
          <Card className="text-white">
            <Card.Body>
              <Form>
                {data &&
                  data
                    .filter((permission) => permission.name.startsWith("update_"))
                    .map((permission) => (
                      <Form.Check
                        key={permission._id}
                        type="checkbox"
                        id={`${role.name}-${permission._id}`}
                        label={permission.name}
                        checked={checkedPermissions.some(
                          (item) => item === permission._id
                        )}
                        onChange={() => handlePermissionChange(permission._id)}
                      />
                    ))}
              </Form>
            </Card.Body>
          </Card>
        </Col>

        {/* Display permissions starting with "delete_" */}
        <Col lg={3} md={6} sm={12} className="mb-4">
          <Card className="text-white">
            <Card.Body>
              <Form>
                {data &&
                  data
                    .filter((permission) => permission.name.startsWith("delete_"))
                    .map((permission) => (
                      <Form.Check
                        key={permission._id}
                        type="checkbox"
                        id={`${role.name}-${permission._id}`}
                        label={permission.name}
                        checked={checkedPermissions.some(
                          (item) => item === permission._id
                        )}
                        onChange={() => handlePermissionChange(permission._id)}
                      />
                    ))}
              </Form>
            </Card.Body>
          </Card>
        </Col>

        {/* Display permissions starting with "add_" */}
        <Col lg={3} md={6} sm={12} className="mb-4">
          <Card className="text-white">
            <Card.Body>
              <Form>
                {data &&
                  data
                    .filter((permission) => permission.name.startsWith("add_"))
                    .map((permission) => (
                      <Form.Check
                        key={permission._id}
                        type="checkbox"
                        id={`${role.name}-${permission._id}`}
                        label={permission.name}
                        checked={checkedPermissions.some(
                          (item) => item === permission._id
                        )}
                        onChange={() => handlePermissionChange(permission._id)}
                      />
                    ))}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Display other permissions in a separate row */}
      <Row>
        <Col lg={12} className="mb-4">
          <Card className="text-white">
            <Card.Body>
              <Form>
                {data &&
                  data
                    .filter(
                      (permission) =>
                        !(
                          permission.name.startsWith("get_") ||
                          permission.name.startsWith("update_") ||
                          permission.name.startsWith("delete_") ||
                          permission.name.startsWith("add_")
                        )
                    )
                    .map((permission) => (
                      <Form.Check
                        key={permission._id}
                        type="checkbox"
                        id={`${role.name}-${permission._id}`}
                        label={permission.name}
                        checked={checkedPermissions.some(
                          (item) => item === permission._id
                        )}
                        onChange={() => handlePermissionChange(permission._id)}
                      />
                    ))}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div className="mt-4 d-flex justify-content-between">
        <Button
          type="submit"
          className={`btn btn-primary  `}
          onClick={handleSubmit}
        >
          Update
        </Button>
        <Button
          type="button"
          className="btn btn-danger "
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </Button>
      </div>
    </Container>

  );
};

export default ManagePermGroup;
