import React from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";


const Banner = () => {
  const fileURL =
    process.env.REACT_APP_FILE_URL;

  const bannerColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Image",
      selector: (row) => row.image,
      cell: (row) => (
        <div className="rounded-circle">
          <img
            className="rounded-circle"
            src={fileURL + "/" + row.image} // Use the image URL from the data
            alt={row.title} // Provide alt text for accessibility
            width={50}
            height={50}
          />
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Button Name",
      selector: (row) => row.button_name,
    },
    {
      name: "Action",
      cell: (row) => (
        <ActionButton row={row} pageTitle="banner" />
      ),
    },
  ];
  async function getField() {
    const url = `/admin/banner`;
    try {
      const response = await getAxios(url);
      const fetchedBannerList = response.data;
      return fetchedBannerList.banners;
    } catch (error) {
      console.log(error);
    }
  }

  const { data, error, isLoading } = useQuery("BannerList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }

  return (
    <>
      <PageData
        columns={bannerColumns}
        tableData={data}
        title="Banner"
      />
    </>
  );
};

export default Banner;
