import React, { useEffect, useState } from "react";
import RegButton from "./RegButton";
import { useNavigate } from "react-router-dom";
import { getCMS } from "../../../../helper/UserAxios";

const Step4 = ({ back, next }) => {
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const handleCheckboxChange1 = () => {
    setIsChecked1(!isChecked1);
  };
  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2);
  };
  const [content, setContent] = useState([]);
  const navigate = useNavigate();
  const getFunc = async () => {
    const res = await getCMS("page_id=term-4");
    setContent(res.data[0]);
  };
  useEffect(() => {}, []);

  useEffect(() => {
    getFunc();
    window.scrollTo(0, 0);
  }, []);
  return (
    <main class="body-wrapper inner bg--1 p-tb-60">
      <div class="pine-cost-page">
        <div class="container">
          <div class="pine-cost">
            <div class="text-center">
              <h3 class="m-b-30">{content.title}</h3>
              <ul class="number-tab">
                <li class="">1</li>
                <li>2</li>
                <li>3</li>
                <li className="active text-white">4</li>
                <li>5</li>
              </ul>
            </div>

            <div class="pine-cost-content m-t-60">
              <div dangerouslySetInnerHTML={{ __html: content.desc }} />

              <div class="pine-cost-content m-t-60">
                {/* <h4 class="text-center">Terms & Conditions / Privacy Policy</h4>{" "}
                <br /> */}
                <div class="check-outer check-outer-center  text-center m-t-60">
                  <div class="check-inner">
                    <label class="check-box" id="check">
                      <small>
                        I have reviewedd and understand all materials shown
                        above.
                      </small>
                      <input
                        type="checkbox"
                        onChange={handleCheckboxChange1}
                        checked={isChecked1}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>

                  <div class="check-inner">
                    <label class="check-box" id="check">
                      <small>I accept the terms and conditions</small>
                      <input
                        type="checkbox"
                        onChange={handleCheckboxChange2}
                        checked={isChecked2}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <RegButton
            hasContinue={true}
            next={next}
            back={back}
            hasNext={false}
            isDisabled={!(isChecked1 && isChecked2)}
          />
        </div>
      </div>
    </main>
  );
};

export default Step4;
