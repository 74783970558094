import axios from "axios";
import Cookies from "js-cookie";
let response = {};
const baseUrl =
  "http://ec2-13-208-174-147.ap-northeast-3.compute.amazonaws.com:9000/api";
// const fileUrl =
//   process.env.REACT_APP_FILE_URL;

let headers = {};
if (document.cookie) {
  const token = Cookies.get("usertoken");
  headers = {
    Authorization: `Bearer ${token}`,
    withCredentials: true,
    cookies: "token=" + token,
  };
}

export async function loginAxios(url, data) {
  const finalUrl = baseUrl + url;

  try {
    const res = await axios.post(finalUrl, data);
    if (res.status === 200) {
      response = {
        ...response,
        success: true,
        message: "Login successful",
        data: res.data.user,
      };

      Cookies.set("usertoken", res.data.token, { expires: 7 });
      headers = {
        Authorization: `Bearer ${res.data.token}`,
        withCredentials: true,
        cookies: `token=` + res.data.token,
      };
    } else {
      response = {
        ...response,
        success: false,
        message: res.data.msg,
      };
    }
  } catch (error) {
    if (error.message === "Request failed with status code 403")
      response = {
        ...response,
        success: false,
        message: error.response.data.msg,
      };
    else
      response = {
        ...response,
        success: false,
        message: error.response.data.msg,
      };
  }
  return response;
}
export async function getAxios(url) {
  const finalUrl = baseUrl + url;

  try {
    const res = await axios.get(finalUrl, { headers });
    if (res.status === 200) {
      response = {
        ...response,
        success: true,
        message: res.data.msg,
        data: res.data,
      };
    } else {
      response = { ...response, success: false, message: res.data.msg };
    }
  } catch (error) {
    response = {
      ...response,
      success: false,
      message: error.response.data.msg,
    };
  }
  return response;
}
export async function Logout() {
  const response = await getAxios("/auth/logout", { headers });
  Cookies.remove("usertoken");
  return response;
}
export async function PostAxios(url, data) {
  const finalUrl = baseUrl + url;
  try {
    const res = await axios.post(finalUrl, data, { headers });
    if (res.status === 200 || 201) {
      response = {
        ...response,
        success: true,
        message: res.data.msg,
        data: res.data.data,
      };
    } else {
      response = { ...response, success: false, message: res.data.msg };
    }
  } catch (error) {
    response = {
      ...response,
      success: false,
      message: error.response.data.msg,
    };
  }
  return response;
}
export async function delAxios(url, data) {
  const finalUrl = baseUrl + url;

  try {
    const res = await axios.delete(finalUrl, { headers });

    if (res.status === 200) {
      response = { ...response, success: true, message: res.data.msg };
    } else {
      response = { ...response, success: false, message: res.data.msg };
    }
  } catch (error) {
    response = {
      ...response,
      success: false,
      message: error.response.data.msg,
    };
  }
  return response;
}

export async function multiDelAxios(url, data) {
  const finalUrl = baseUrl + url;
  try {
    const res = await axios.delete(finalUrl, { data, headers });

    if (res.status === 200) {
      response = { ...response, success: true, message: res.data.msg };
    } else {
      response = { ...response, success: false, message: res.data.msg };
    }
  } catch (error) {
    response = {
      ...response,
      success: false,
      message: error.response.data.msg,
    };
  }
  return response;
}

export async function PatchAxios(url, data) {
  const finalUrl = baseUrl + url;
  try {
    const res = await axios.patch(finalUrl, data, { headers });

    if (res.status === 201 || 200) {
      response = {
        ...response,
        success: true,
        message: res.data.msg,
      };
    } else {
      response = { ...response, success: false, message: res.data.msg };
    }
  } catch (error) {
    response = {
      ...response,
      success: false,
      message: error.response.data.msg,
    };
  }
  return response;
}
