import React, { useEffect, useState } from "react";
import { getCMS } from "../../../helper/UserAxios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const Interview = () => {
  const fileURL = process.env.REACT_APP_FILE_URL;
  const [interview, setInterview] = useState([]);
  const [bookNow, setBookNow] = useState([]);
  const navigate = useNavigate();
  const token = Cookies.get("employeetoken");
  const handleCheckItOut = () => {
    if (!token) {
      toast.error("Please Login First");
      navigate("/employee/login");
    }
  };
  useEffect(() => {
    getFunc();
  }, []);
  const getFunc = async () => {
    const res = await getCMS("page_id=interview&page_id=book-section");
    setInterview(res.data[0]);
    setBookNow(res.data[1]);
  };
  return (
    <>
      <section className="visa-interview p-b-60">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="visa-interview-img">
                <img
                  src={fileURL + "/" + interview.seo_image}
                  alt={interview.title}
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="visa-interview-text">
                <h2>{interview.title}</h2>
                <p>{interview.meta_desc}</p>
                <button
                  className="btn btn--accent m-t-20 btn--big"
                  onClick={handleCheckItOut}
                >
                  check it out
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cta p-tb-60">
        <div className="container">
          <div className="d-flex cta-content justify-content-between align-items-center">
            <div className="cta-left text-white">{bookNow.title}</div>
            <div className="cta-right">
              <button
                className="btn btn--white-outline btn--big"
                variant="outline-white"
                size="lg"
                onClick={handleCheckItOut}
              >
                Book Now
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Interview;
