import React from "react";
import { Button, Modal } from "react-bootstrap";

const ContactView = ({ show, handleClose, handleView, feedback }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Contact Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>First Name:</strong> {feedback.first_name}
          </p>
          <p>
            <strong>Last Name:</strong> {feedback.last_name}
          </p>
          <p>
            <strong>Email:</strong> {feedback.email}
          </p>
          <p>
            <strong>Message:</strong> {feedback.message}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={feedback.seen}
            onClick={() => {
              handleView(feedback.id);
            }}
          >
            Mark as Seen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContactView;
