import React, { useState } from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";
import StepsModal from "./StepsModal";

const Steps = () => {
  const fileURL =
    process.env.REACT_APP_FILE_URL;
  const [show,setShow]=useState(false);

    
  const handleDetail=(row)=>{
    setShow(true);
  }
  const stepsColumns = [
    {
      name: "Employee",
      selector: (row) => row.employee.first_name + ' ' + row.employee.last_name,
    },
    {
      name: "Onboarding",
      selector: (row) => row.onboarding,
      cell: (row) => (
        <div >
          {(row.onboarding.appointment === "pending"
          ? "Appointment"
          : row.onboarding.zoom_link === "pending"
          ? "Zoom Link"
          : row.onboarding.meeting === "pending"
          ? "Meeting"
          : "Completed")}

        </div>
      ),
    },
    {
      name: "Apply",
      selector: (row) => row.apply,
      cell: (row) => (
        <div>
          {row.apply.vacancy_apply === "pending" ? "Vacancy Apply" : (row.apply.admin_form_verification === "pending" ? "Admin Validation" : "Completed")}

        </div>
      ),
    },
    {
      name: "Vacancy Apply",
      selector: (row) => row.vacancy_apply,
      cell: (row) => (
        <div >
         {row.vacancy_apply==="pending"?"Pending":"Completed"}
        </div>
      ),
    },
    {
      name: "Contract",
      selector: (row) => row.contract,
      cell: (row) => (
        <div>
        {row.contract==="pending"?"Pending":"Completed"}
        </div>
      ),
    },
    {
      name: "Payment",
      selector: (row) => row.payment,
      cell: (row) => (
        <div>
        {row.payment==="pending"?"Pending":"Completed"}
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
       <div>
        <button className="btn btn-primary m-1" onClick={() => handleDetail(row)}>
          Detail
        </button>
       </div>
      ),
    },
  ];
  async function getField() {
    const url = `/admin/steps`;
    try {
      const response = await getAxios(url);
      const fetchedAlbumList = response.data;
      return fetchedAlbumList.steps;
    } catch (error) {
      console.log(error);
    }
  }

  const { data, error, isLoading } = useQuery("StepsList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }

  return (
    !show?(
    <>

      <PageData columns={stepsColumns} tableData={data} title="Steps" addRequired={false}/>

    </>):
    (<StepsModal handleClose={()=>setShow(false)} show={show} />)
  )
}

export default Steps;
