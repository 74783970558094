import React, { useState } from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import AppointmentModal from "./AppModel";

const Appointmet = () => {
  const [id, setId] = useState(null);
  const [show, setShow] = useState(false);
  function handleUpdate(row) {
    setId(row._id);
    setShow(true);
  }
  const appointmentColumns = [
    {
      name: "Title",
      selector: (row) => (row.employee ? row.employee.first_name : " "),
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.status,
    // },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex ">
          <button
            className="btn btn-success m-2"
            onClick={() => {
              handleUpdate(row);
            }}
          >
            Update
          </button>
          <button className="btn btn-danger m-2">Delete</button>
        </div>
      ),
    },
  ];
  async function getField() {
    const url = `/admin/appointment`;
    try {
      const response = await getAxios(url);
      const fetchedAppointmetList = response.data;
      return fetchedAppointmetList.appointment;
    } catch (error) {
      console.log(error);
    }
  }

  const { data, error, isLoading } = useQuery("AppointmetList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }

  return !show ? (
    <>
      <PageData
        columns={appointmentColumns}
        tableData={data}
        title="Appointment"
        addRequired={false}
      />
    </>
  ) : (
    <AppointmentModal
      show={show}
      handleClose={() => {
        setShow(false);
      }}
      id={id}
    />
  );
};

export default Appointmet;
