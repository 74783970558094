import * as Yup from "yup";

const EmployeeSchema = Yup.object().shape({
  phone: Yup.number().required("Please enter your phone number"),
  first_name: Yup.string().required("Please Enter your first name"),
  last_name: Yup.string().required("This field can't be Empty"),
  dob: Yup.date()
    .required("Date of Birth is required")
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
      "You Must be Minimum of 18"
    ),
  same_address: Yup.string().required(
    "Please  Specify if your both address are same"
  ),
  highest_edu_degree: Yup.string().required("This field can't be Empty"),
  has_work_experience: Yup.mixed()
    .oneOf(["true", "false"])
    .defined()
    .required("This field can't be Empty"),
  // image: Yup.string(),
  // status: Yup.string().oneOf(["active", "inactive"]).default("active"),
  country: Yup.string().required("This field can't be Empty"),
  //current
  current_country: Yup.string().required("Select a valid Country"),
  // current_address: Yup.string().required("This field can't be Empty"),
  current_street: Yup.string().required("This field can't be Empty"),
  current_city_town: Yup.string().required("This field can't be Empty"),
  current_provience_state: Yup.string().required("This field can't be Empty"),
  current_zipcode: Yup.string()
    .required("This field can't be Empty")
    .matches(/^\d{5}$/, "Invalid Zip Code"),

  //permanent
  permanent_country: Yup.string(),
  // permanent_address: Yup.string(),
  permanent_street: Yup.string(),
  permanent_city_town: Yup.string(),
  permanent_province_state: Yup.string(),
  permanent_zipcode: Yup.string(),
  //end
  special_accomodation: Yup.mixed()
    .oneOf(["true", "false"])
    .defined()
    .required("This field can't be Empty"),
  gender: Yup.mixed()
    .oneOf(["male", "female", "other"], "Select a valid input")
    .defined()
    .required("This field can't be Empty"),
  pending_us_asylum: Yup.mixed()
    .oneOf(["true", "false"])
    .defined("Please Select a valid input")
    .required("This field can't be Empty"),
  deported_removed_from_us: Yup.mixed()
    .oneOf(["true", "false"])
    .defined()
    .required("This field can't be Empty"),
  denied_to_enter_us: Yup.mixed()
    .oneOf(["true", "false"])
    .defined()
    .required("This field can't be Empty"),

  // passport_back: Yup.string(),
  // resume: Yup.string(),
  // message: Yup.string(),
});

export const DetailEditSchema = Yup.object().shape({
  // dob: Yup.string(),
  // image: Yup.string(),
  // status: Yup.string().oneOf(["active", "inactive"]).default("active"),
  country: Yup.string().required("This field can't be Empty"),
  current_address: Yup.string().required("This field can't be Empty"),
  current_street: Yup.string().required("This field can't be Empty"),
  current_city_town: Yup.string().required("This field can't be Empty"),
  current_provience_state: Yup.string().required("This field can't be Empty"),

  zipcode: Yup.string()
    .required("This field can't be Empty")
    .matches(/^\d{5}$/, "Invalid Zip Code"),
  special_accomodation: Yup.boolean("Select any one").required(
    "This field can't be Empty"
  ),
  pending_us_asylum: Yup.boolean("Select any one").required(
    "This field can't be Empty"
  ),
  deported_removed_from_us: Yup.boolean("Select any one").required(
    "This field can't be Empty"
  ),

  // passport_back: Yup.string(),
  // resume: Yup.string(),
  // message: Yup.string(),
});

export default EmployeeSchema;
