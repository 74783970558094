import React, { useState, useRef } from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import "./Form.css";
import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { BannerSchema } from "./Schemas";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios } from "../../../helper/AxiosHelper";
;
const BannerForm = ({ edit }) => {
  const baseURL =
    process.env.REACT_APP_FILE_URL;
  const location = useLocation();
  const client = useQueryClient();
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const [images, setImages] = useState("");
  if (edit) {
    console.log(location.state);
  }
  function handleImageClick(e) {
    imageRef.current.click();
  }
  function handleImageChange(e) {
    const file = e.target.files[0];
    setImages(file);
  }
  const handlePost = async (e) => {
    const formData = new FormData();

    for (const key in values) {
      if (key === "image") {
        continue;
      }
      formData.append(key, values[key]);
    }
    if (!edit) {
      if (InitialState.image instanceof File) {
        formData.append("image", InitialState.image);
      } else {
        formData.append("image", InitialState.image);
      }
    } else {
      if (edit && images instanceof File) {
        formData.append("image", images);
      } else {
        formData.append("image", InitialState.image);
      }
    }
    const res = edit
      ? await PatchAxios(`/admin/banner/${location.state._id}`, formData)
      : await PostAxios("/admin/banner", formData);

    if (res.success) {
      client.refetchQueries("BannerList");
      navigate("/admin/banner");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
    } else {
      toast.error(res.message);
    }
  };

  const InitialState = {
    title: edit ? location.state.title : "",
    image: edit ? location.state.image : images,
    description: edit ? location.state.description : "",
    link: edit ? location.state.link : "",
    button_name: edit ? location.state.button_name : "",
    display_order: edit ? location.state.display_order : "",
    status: edit ? location.state.status : "inactive",
  };
  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: BannerSchema,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });
  return (
    <>
      <Form>
        <h2 className="text-center text-muted mb-2">Add Banner</h2>
        <div className="image_holder">
          {!images ? (
            <img
              src={
                edit && InitialState.image
                  ? `${baseURL}/${InitialState.image}`
                  : "https://media.istockphoto.com/id/1248723171/nl/vector/camera-pictogram-van-het-foto-uploaden-op-ge%C3%AFsoleerde-witte-achtergrond-eps-10-vector.jpg?s=612x612&w=0&k=20&c=WLTMjPeoFbET0aBnnJmLyzDE1wUkJu9nj07KPtmf8qg="
              }
              className="rounded-circle"
              alt="Cinque Terre"
              height={200}
              width={200}
              onClick={handleImageClick}
            />
          ) : (
            <img
              src={URL.createObjectURL(images)}
              alt="bannerInput"
              className="rounded-circle"
              height={200}
              width={200}
              onClick={handleImageClick}
            />
          )}
          <input
            type="file"
            ref={imageRef}
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
        <Row className="m-2 p-2">
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="name">
                Title
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  placeholder="Title"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                />
              </InputGroup>
              {errors.title && touched.title ? (
                <p className="text-danger">{errors.title}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="text"
                name="link"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.link}
              />
              {errors.link && touched.link ? (
                <p className="text-danger">{errors.link}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Display Order</Form.Label>
              <Form.Control
                type="text"
                name="display_order"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.display_order}
              />
            </Form.Group>
            {errors.display_order && touched.display_order ? (
              <p className="text-danger">{errors.display_order}</p>
            ) : null}
          </Col>
          <Col md={6}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Button Name</Form.Label>
              <Form.Control
                type="text"
                name="button_name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.button_name}
              />
            </Form.Group>
            {errors.button_name && touched.button_name ? (
              <p className="text-danger">{errors.button_name}</p>
            ) : null}
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="residentType">Status</Form.Label>
              <Form.Select
                className="form-select form-select-sm mb-3"
                aria-label="Default select example"
                name="status"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.status}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Select>
              {errors.status && touched.status ? (
                <p className="text-danger">{errors.status}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="hlt_doctors_on_admission">
                Description
              </Form.Label>
              <div className="input-group">
                <Form.Control
                  as="textarea"
                  rows={1}
                  className="form-control"
                  placeholder="Description"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                />
                {errors.description && touched.description ? (
                  <p className="text-danger">{errors.description}</p>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <div className="mt-4 d-flex justify-content-between">
            <Button
              type="submit"
              className={`btn btn-primary  `}
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting}
            >
              {edit
                ? isSubmitting
                  ? "Updating...."
                  : "Update"
                : isSubmitting
                  ? "Submitting...."
                  : "Submit"}
            </Button>
            <Button
              type="button"
              className="btn btn-danger "
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default BannerForm;
