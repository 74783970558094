import React, { useState } from "react";
import { ConfirmationModal } from "../index";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { delAxios, getAxios } from "../../../../helper/AxiosHelper";
import { snakeCaseToTitleCase } from "../../../../helper/PageMaping";
import { useSelector } from "react-redux";
import ViewModel from "../../Pages/Employee/ViewModel";
const ActionButton = ({
  row,
  pageTitle,
  hasPermission = false,
  RouteTitle,
  hasdetail = false,
  isEmployer = false,
}) => {
  const perm = useSelector((state) => state.admin.permission);
  const curr_perm = perm && perm.find((item) => item.title === pageTitle);
  const level = localStorage.getItem("level");
  const text = snakeCaseToTitleCase(pageTitle);
  const navigate = useNavigate();
  const client = useQueryClient();
  const apiTitle = RouteTitle ? RouteTitle : pageTitle;
  const handleEdit = (rowData) => {
    navigate(`/admin/${pageTitle}/edit/${rowData._id}`, { state: rowData });
  };

  const [showModal, setShowModal] = useState(false);
  const [employerModel, setEmployerModel] = useState(false);
  const [content, setContent] = useState({});
  const [Empcontent, setEmpContent] = useState();
  const handleView = async (row) => {
    setEmpContent(row);
    setEmployerModel(true);
  };
  const handleDelete = async (uid) => {
    const res = await delAxios(`/admin/${apiTitle}/${uid}`);
    if (res.success) {
      toast.success(res.message);
      setShowModal(false);
    } else {
      toast.error(res.message);
      setShowModal(false);
    }
    client.refetchQueries(text + "List");
  };
  const deleteModel = (row) => {
    let mainText = row.name
      ? row.name
      : row.config_name
      ? row.config_name
      : row.title;
    const newContent = {
      title: `Delete the ${text}`,
      body: `Are you sure you want to delete the ${text} ${mainText} `,
      id: row._id,
    };
    setContent(newContent); // Update the content state
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const updatePermission = (row) => {
    navigate("permission", { state: row });
  };
  return !showModal && !employerModel ? (
    <div>
      {((curr_perm && curr_perm.edit) || level === "1") && (
        <button className="btn btn-primary m-1" onClick={() => handleEdit(row)}>
          Edit
        </button>
      )}
      {hasdetail && (
        <button className="btn btn-info m-1" onClick={() => handleView(row)}>
          View
        </button>
      )}
      {((curr_perm && curr_perm.delete) || level === "1") && (
        <button className="btn btn-danger m-1" onClick={() => deleteModel(row)}>
          Delete
        </button>
      )}
      {hasPermission && level === "1" && (
        <button
          className="btn btn-info m-1"
          onClick={() => updatePermission(row)}
        >
          Perm
        </button>
      )}
    </div>
  ) : !isEmployer ? (
    showModal && (
      <ConfirmationModal
        show={showModal}
        handleClose={handleClose}
        handleConfirm={handleDelete}
        Content={content}
      />
    )
  ) : (
    <ViewModel
      show={employerModel}
      handleClose={() => {
        setEmployerModel(false);
      }}
      isemployer
      EmployerContent={Empcontent}
    />
  );
};

export default ActionButton;
