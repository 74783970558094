import React from "react";
import PageData from "../../PageData/PageData";
import { useQuery, useQueryClient } from "react-query";
import { PatchAxios, getAxios } from "../../../../helper/AxiosHelper";
import { toast } from "react-toastify";

const VacancyApply = ({ home = false }) => {
  const handleAssign = async (row) => {
    console.log(row);
    const res = await PatchAxios(
      `/admin/apply_vacancy/${row._id}?employer_status=active`
    );
    if (res.success) {
      toast.success(res.message);
      client.refetchQueries("VacancyApplyList");
    } else {
      toast.error(res.message);
    }
  };
  const client = useQueryClient();
  const vacancyApplyColumns = [
    {
      name: "Title",
      selector: (row) => row.vacancy && row.vacancy.title,
      sortable: true,
    },
    {
      name: "Employer",
      selector: (row) => row.employer && row.employer.company_name,
      sortable: true,
    },
    // {
    //   name: "Tag",
    //   selector: (row) => {
    //     if (row.vacancy.tag && Array.isArray(row.vacancy.tag)) {
    //       const titles = row.vacancy.tag.map((tagObject) => tagObject.title);
    //       const concatenatedTitles = titles.join(", ");
    //       return concatenatedTitles;
    //     }
    //   },
    // },
    {
      name: "Assigned",
      selector: (row) => (
        <span
          className={`h5 badge text-white bg-${
            row.employer_status === "active" ? "success" : "secondary"
          }`}
        >
          {row.employer_status}
        </span>
      ),
      sortable: true,
    },
    !home && {
      name: "Action",
      cell: (row) => (
        <button
          className="btn btn-primary mx-2"
          onClick={() => handleAssign(row)}
        >
          Assign
        </button>
      ),
    },
  ];

  async function getField() {
    const url = `/admin/apply_vacancy`;
    try {
      const response = await getAxios(url);
      const fetchedList = response.data;
      return fetchedList.vacancy;
    } catch (error) {
      console.log(error);
    }
  }
  const { data, error, isLoading } = useQuery("VacancyApplyList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  return (
    <>
      <PageData
        columns={vacancyApplyColumns}
        tableData={data}
        title={home ? "" : "VacancyApply"}
        addRequired={false}
        home={home}
      />
    </>
  );
};

export default VacancyApply;
