import React from "react";
import PageData from "../../PageData/PageData";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";

import { useQuery } from "react-query";
const SocialLink = () => {

  const socialLinkColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },

    {
      name: "Target URL",
      selector: (row) => row.target_url,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <ActionButton row={row} pageTitle="sociallink" />
      ),
    },
  ];
  async function getField() {
    const url = `/admin/socialLink`;
    try {
      const response = await getAxios(url);
      const fetchedList = response.data;
      return (fetchedList.social_link);
    } catch (error) {
      console.log(error);
    }
  }
  const { data, error, isLoading } = useQuery("SociallinkList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }

  return (
    <>
      <PageData
        columns={socialLinkColumns}
        tableData={data}
        title="SocialLink"
      />
    </>
  );
};

export default SocialLink;
