import React from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";

const CMS = () => {
  const fileURL =
    process.env.REACT_APP_FILE_URL;

  const cmsColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Seo Image",
      selector: (row) => row.seo_image,
      cell: (row) => (
        row.seo_image ? (
          <div className="rounded-circle">
            <img
              className="rounded-circle"
              src={fileURL + "/" + row.seo_image}
              alt={row.title}
              width={50}
              height={50}
            />
          </div>
        ) : null
      ),
    },
    {
      name: "Page Id",
      selector: (row) => row.page_id,
    },
    {
      name: "Action",
      cell: (row) => (
        <ActionButton row={row} pageTitle="cms" />
      ),
    },
  ];
  async function getField() {
    const url = `/admin/cms`;
    try {
      const response = await getAxios(url);
      const fetchedCmsList = response.data;
      return fetchedCmsList.cms;
    } catch (error) {
      console.log(error);
    }
  }

  const { data, error, isLoading } = useQuery("CmsList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  return (
    <>
      <PageData columns={cmsColumns} tableData={data} title="CMS" />
    </>
  );
};

export default CMS;
