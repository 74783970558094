// BlogContent.js

import React, { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

const BlogContent = () => {
  const location = useLocation();
  const fileURL = process.env.REACT_APP_FILE_URL;
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const query = useQueryClient();

  useEffect(() => {
    setBlogs(query.getQueryData("BLogListUser"));
  }, [location.state]);

  if (!location.state) {
    navigate("/nocontent");
    return null;
  }

  const {
    title,
    seo_image,
    meta_title,
    meta_desc,
    short_desc,
    long_desc,
    featured_image,
    yt_vide_link,
    createdAt,
  } = location.state;
  function getVid(url) {
    const match = url.match(/youtu\.be\/([^?]+)/);

    return match ? match[1] : null;
  }

  const handleBlogDisplay = (blog) => {
    navigate(`/blog/${blog.slug}`, { state: blog });
  };

  return (
    <main class="body-wrapper p-tb-60">
      <div class="container">
        <div class="blog-single-page">
          <div class="container wow fadeInDown">
            <div class="row">
              <div class="col-md-12 col-lg-8">
                <div class="blog-single-left">
                  <img
                    src={fileURL + "/" + featured_image}
                    alt={title}
                    class="mb-4"
                  />

                  <div class="blog-post__meta text-color-2">
                    <div class="blog-post__date">
                      <i class="fa fa-calendar me-1"></i>{" "}
                      <span>{createdAt.substring(0, 10)}</span>
                    </div>
                  </div>
                  <h2 class="m-b-20">{title}</h2>

                  <div
                    class="blog-text text-color-2"
                    dangerouslySetInnerHTML={{ __html: long_desc }}
                  />
                  {yt_vide_link && (
                    <div className="embed-responsive embed-responsive-16by9 mt-4">
                      <iframe
                        width="560"
                        height="315"
                        src={`https://www.youtube-nocookie.com/embed/${getVid(
                          yt_vide_link
                        )}`}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </div>
                  )}
                </div>
              </div>
              <div class="col-md-12 col-lg-4">
                <div class="sidebar">
                  <div class="post module m-b-30">
                    <div class="title m-b-30">
                      <h3 class="fz24">Recent Posts</h3>
                    </div>
                    <ul class="post__list">
                      {blogs &&
                        blogs.map((blog) => (
                          <li class="post__item" key={blog._id}>
                            <div
                              onClick={() => {
                                handleBlogDisplay(blog);
                              }}
                              className="cursor-pointer"
                            >
                              <div class="media post__media">
                                <img
                                  class="me-3"
                                  src={fileURL + "/" + blog.featured_image}
                                  alt=""
                                />
                                <div class="media-body">
                                  <p class="media-date">
                                    {blog.updatedAt.substring(0, 10)}
                                  </p>
                                  <h3 class="mt-0">{blog.title}</h3>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default BlogContent;
