import React from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { EducationSchema } from "./Schemas";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios } from "../../../helper/AxiosHelper";

const ExperienceForm = ({ edit }) => {
  const location = useLocation();
  const client = useQueryClient();
  const navigate = useNavigate();
  const initialExperience = {
    title: edit ? location.state.title : "",
    company: edit ? location.state.company : "",
    started: edit ? location.state.started : "active",
    completed: edit ? location.state.completed : "",
  };
  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: initialExperience,
    validationSchema: EducationSchema,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
      if (res.success) {
        client.invalidateQueries("EducationList");
        navigate("/admin/education");
        toast.success(edit ? "Updated Successfully" : "Added Successfully");
        return true;
      } else {
        toast.error(res.error);
      }
    },
  });
  const handlePost = async (e) => {
    const res = edit
      ? await PatchAxios(`/employee/education/${location.state._id}`, values)
      : await PostAxios("/employee/education", values);
    console.log(res);
    if (res.success) {
      navigate("/admin/education");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
      return true;
    } else {
      toast.error(res.error);
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <h2 className="text-center text-muted mb-2">Add Education</h2>
        <Row className="m-2 p-2">
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="name">
                Title
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  id="name"
                  placeholder="Title"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.title && touched.title ? (
                <p className="text-danger">{errors.title}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="From">
                Start Date <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                name="started"
                value={values.started}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              {errors.started && touched.started ? (
                <p className="text-danger">{errors.started}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="From">
                End Date <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                name="completed"
                value={values.completed}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              {errors.completed && touched.completed ? (
                <p className="text-danger">{errors.completed}</p>
              ) : null}
            </Form.Group>
          </Col>
          <div className="mt-4 d-flex justify-content-between">
            <Button
              type="submit"
              className={`btn btn-primary  `}
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting}
            >
              {edit
                ? isSubmitting
                  ? "Updating...."
                  : "Update"
                : isSubmitting
                ? "Submitting...."
                : "Submit"}
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default ExperienceForm;
