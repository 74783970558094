import React from "react";

import EmpInfo from "../../../GenNav/EmpInfo";

const ATopNav = ({ handleBarClick, data, employer }) => {
  return (
    <>
      <div className="main-content__header justify-content-between">
        <div className="d-flex align-items-center">
          <div className="toggle-menu" onClick={handleBarClick}>
            <i className="fas fa-bars"></i>
          </div>
          <div className="main-content__body">
            <h2>
              Welcome {data.first_name} {data.last_name}
            </h2>
          </div>
        </div>
        <EmpInfo data={data} employer={employer} />
      </div>
    </>
  );
};

export default ATopNav;
