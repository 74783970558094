import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { useQuery, useQueryClient } from "react-query";
import { PatchAxios, getAxios } from "../../../../helper/AxiosHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const MyGridComponent = () => {
  const client = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const role = location.state;
  const checkedPerm = role && role.permission;
  const roleId = role && role._id;
  async function getRolePerm() {
    const url = `/admin/permissiongroup`;
    try {
      const response = await getAxios(url);
      return response.data.permission_group;
    } catch (error) {
      console.log(error);
    }
  }

  const [checkedPermissions, setCheckedPermissions] = useState(
    checkedPerm.map((permission) => permission._id)
  );

  const { data, isLoading, error } = useQuery("RolePerm", getRolePerm);

  const handlePermissionChange = (id) => {
    const updatedPermissions = [...checkedPermissions];
    const index = updatedPermissions.findIndex((item) => item === id);

    if (index !== -1) {
      updatedPermissions.splice(index, 1);
    } else {
      updatedPermissions.push(id);
    }

    setCheckedPermissions(updatedPermissions);
  };
  const uncheckedItems = checkedPerm.filter(
    (permission) => !checkedPermissions.includes(permission._id)
  );
  const handleSubmit = async () => {
    const res = await PatchAxios(`/admin/role/${roleId}/permission`, {
      push: checkedPermissions,
      pop: uncheckedItems.length > 0 ? uncheckedItems : [],
    });
    if (res.success) {
      toast.success(res.message);
      client.refetchQueries("RoleList");
      navigate("/admin/role");
    } else {
      toast.error(res.message);
    }
  };

  return (
    <Container>
      <h1 className="text-white text-center">{role && role.name}</h1>

      <Row>
        {data &&
          data.map((role, rowIndex) => (
            <Col key={rowIndex} lg={4} md={6} sm={12} className="mb-4">
              <Card className="text-white">
                <Card.Header>
                  <h4>{role.name}</h4>
                </Card.Header>
                <Card.Body>
                  <Form>
                    {role.permission.map((permission) => (
                      <Form.Check
                        key={permission._id}
                        type="checkbox"
                        id={`${role.name}-${permission._id}`}
                        label={permission.name}
                        checked={checkedPermissions.some(
                          (item) => item === permission._id
                        )}
                        onChange={() => handlePermissionChange(permission._id)}
                      />
                    ))}
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>
      <div className="mt-4 d-flex justify-content-between">
        <Button
          type="submit"
          className={`btn btn-primary  `}
          onClick={handleSubmit}
        >
          Update
        </Button>
        <Button
          type="button"
          className="btn btn-danger "
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </Button>
      </div>
    </Container>
  );
};

export default MyGridComponent;
