import React from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";

const SysInfoFile = () => {
  const fileURL =
    process.env.REACT_APP_FILE_URL;

  const albumColumns = [
    {
      name: "Config Name",
      selector: (row) => row.config_name,
    },
    {
      name: "Config Caption",
      selector: (row) => row.config_caption,
    },
    {
      name: "Action",
      cell: (row) => (
        <ActionButton row={row} pageTitle="systeminfofile" />
      ),
    },
  ];
  async function getField() {
    const url = `/admin/sysinfofile`;
    try {
      const response = await getAxios(url);
      const fetchedAlbumList = response.data;
      return fetchedAlbumList.system_info_file;
    } catch (error) {
      console.log(error);
    }
  }

  const { data, error, isLoading } = useQuery("SysinfofileList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }

  return (
    <>

      <PageData columns={albumColumns} tableData={data} title="systeminfofile" displayTitle="System Info File" />

    </>
  );
};

export default SysInfoFile;
