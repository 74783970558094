import React, { useEffect, useState } from "react";
import { getCMS } from "../../../helper/UserAxios";

const ListSection = () => {
  const fileURL = process.env.REACT_APP_FILE_URL;
  const [listsec, setListsec] = useState({});
  const [ListDatas, setListDatas] = useState([]);
  useEffect(() => {
    getFunc();
  }, []);

  const getFunc = async () => {
    const res = await getCMS(
      "page_id=list-main&page_id=list-we-find&page_id=list-we-match&page_id=list-we-guide&page_id=list-we-advice"
    );

    setListsec(res.data[0]);
    setListDatas(res.data.slice(1));
  };
  return (
    <section className="featured p-t-60">
      <div className="container">
        <div className="section-title m-b-30">
          <h2>{listsec.title}</h2>
        </div>
        <p className="text-center">{listsec.meta_desc}</p>

        <div className="cards cards--featured m-t-40">
          {ListDatas.map((card) => (
            <div key={card._id} className="card text-center card-bg">
              <div className="card-icon m-b-30">
                <img src={fileURL + "/" + card.seo_image} alt={card.title} />
              </div>
              <h3>{card.title}</h3>
              <p>{card.meta_desc}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ListSection;
