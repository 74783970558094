import React from "react";
import PageData from "../../PageData/PageData";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";
import { useQuery } from "react-query";

const Experience = () => {

  const experienceColumns = [
    {
      name: "Position",
      selector: (row) => row.position,
      sortable: true,
    },
    {
      name: "Company",
      selector: (row) => row.company,
      sortable: true,
    },
    {
      name: "From",
      selector: (row) => row.from,
      sortable: true,
    },
    {
      name: "To",
      selector: (row) => row.to,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <ActionButton row={row} pageTitle="experience" />
      ),
    },
  ];

  async function getField() {
    const url = `/employee/experience`;
    try {
      const response = await getAxios(url);
      const fetchedList = response.data;
      console.log(fetchedList.experience);
      return (fetchedList.experience);
    } catch (error) {
      console.log(error);
    }
  }
  const { data, error, isLoading } = useQuery("ExperienceList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }

  return (
    <PageData
      tableData={data}
      columns={experienceColumns}
      title="Experience"
    />
  );
};

export default Experience;
