import { Dashboard } from "./AdminPanel/Components/Pages";
import { Routes, Route } from "react-router-dom";
import AdminUi from "./AdminPanel/Layout/adminUi.layout";
import PageUi from "./AdminPanel/Layout/pageUi.layout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Login, LandingPage } from "./AdminPanel/Components/Pages";
import FormUi from "./AdminPanel/Components/Forms/FormUi";
import { PrivateAdminRoute } from "./AdminPanel/routing/admin.routing";
import HomeUi from "./Home/Layout/homeUi.layout";
import { UserLogin, UserRegister } from "./Home/Components";
import { PrivateEmpRoute } from "./Home/Routes/emp.routing";
import { cloneElement, useEffect, useState } from "react";
import NotFound from "./Home/Pages/Notfound/NotFound";
import EmployerDashboard from "./Home/Components/EDashboard/Employer/EmployerDashboard";
import EmployeeDashboard from "./Home/Components/EDashboard/EmployeeDashboard";
import { Jobs, Application } from "./Home/Pages";
import EmpUi from "./Home/Layout/empUi.layout";
import ChangePassword from "./Home/Components/ChangePassword/ChangePassword";
import Edit from "./Home/Components/EDashboard/Edit";
import ChangePasswordAdmin from "./AdminPanel/Components/GeneralUI/ChangePassword/ChangePassword";
import Aboutus from "./Home/Pages/AboutUs/Aboutus";
import { routesData } from "./adminroutedata";
import EmpProfile from "./Home/Components/EDashboard/EmpProfile/EmpProfile";
import CookieBanner from "./Home/Components/GeneralUI/CookieBanner/CookieBanner";
import BlogList from "./Home/Components/GeneralUI/Blog/BlogList";
import BlogContent from "./Home/Components/GeneralUI/Blog/BlogContent";
import EmpOffering from "./Home/Components/EDashboard/GeneralUI/EmpOffering";
import Services from "./Home/Components/EDashboard/GeneralUI/Services";
import { useDispatch } from "react-redux";
import { getAxios } from "./helper/AxiosHelper";
import { addCount, addPermission, addSidebar } from "./store/adminSlice";
import ManagePerm from "./AdminPanel/Components/Pages/Role/ManagePerm";
import DynamicContent from "./AdminPanel/Components/GeneralUI/DynamicContent/DynamicContent";
import {
  FooterDownloads,
  LatestNews,
  MediaCoverage,
} from "./Home/Components/GeneralUI";
import ManagePermGroup from "./AdminPanel/Components/Pages/PermissionGroup/ManagePermGroup";
import { ForgetPassword } from "./helper";
import { EmployerProfile } from "./Home/Components/EDashboard";
import { EmployerForm } from "./AdminPanel/Components/Forms";
import ChangeImage from "./Home/Components/ChangeImage/ChangeImage";
import EmpSteps from "./Home/Components/EDashboard/EmpSteps";
function App() {
  let level = JSON.parse(localStorage.getItem("level"));
  const [perm, setPerm] = useState([]);
  const [count, setCount] = useState([]);

  const dispatch = useDispatch();
  if (level == null) {
    level = JSON.parse(localStorage.getItem("level"));
  }
  async function getList() {
    if (!level) {
      return;
    }

    const url = level === 1 ? "/admin/sidebar" : "/admin/dashboard";
    try {
      const response = await getAxios(url);
      const sidebarArray =
        level === 1 ? response.data.side_bar : response.data.sidebars;
      const sortedSidebar = sidebarArray.sort(
        (a, b) => a.display_order - b.display_order
      );
      const count = level > 1 ? response.data.counts : [];
      if (level === 1) {
        dispatch(addSidebar(sidebarArray));
      }
      setCount(count);
      if (level > 1) {
        const mappedArray = sidebarArray.map((item) => {
          return {
            title: item.name,
            get: item.permissions.includes("get"),
            add: item.permissions.includes("add"),
            edit: item.permissions.includes("update"),
            delete: item.permissions.includes("delete"),
          };
        });
        setPerm(mappedArray);
        dispatch(addSidebar(sortedSidebar));
        dispatch(addPermission(mappedArray));
        dispatch(addCount(count));
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getList();
  }, [level]);

  let newRoute = routesData.map((route) => {
    const matchingUpdate = perm.find((update) => update.title === route.title);
    if (matchingUpdate) {
      return {
        ...route,
        ...matchingUpdate,
      };
    }
  });
  if (level && level == 1) {
    newRoute = routesData;
  }
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
      />

      <Routes>
        <Route path="/" element={<HomeUi />}>
          <Route index element={<LandingPage />} />
          <Route path="/test" element={<EmpSteps />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/blogs" element={<BlogList />} />
          <Route path="/blog/:id" element={<BlogContent />} />
          <Route path="/content/:id" element={<DynamicContent />} />
          <Route path="/offering" element={<EmpOffering />} />
          <Route path="/service" element={<Services />} />
          <Route path="/media" element={<MediaCoverage />} />
          <Route path="/download" element={<FooterDownloads />} />
          <Route path="/news" element={<LatestNews />} />
          <Route path="employee/login" element={<UserLogin />} />
          <Route
            path="employer/login"
            element={<UserLogin employer={true} />}
          />
          <Route path="employee/register" element={<UserRegister />} />
        </Route>

        <Route path="/employee" element={<EmpUi />}>
          <Route path="edit" element={<Edit />} />
          <Route path="application" element={<Application />} />
          <Route path="jobs" element={<Jobs />} />
          <Route path="profile" element={<EmpProfile />} />
          <Route path="changepassword" element={<ChangePassword />} />

          <Route
            path="dashboard"
            element={<PrivateEmpRoute component={<EmployeeDashboard />} />}
          />
        </Route>

        <Route path="/employer" element={<EmpUi employer />}>
          {/* <Route path="edit" element={<Edit />} /> */}
          <Route path="application" element={<Application employer={true} />} />
          <Route path="jobs" element={<Jobs employer={true} />} />
          <Route
            path="edit"
            element={<EmployerForm edit={true} employerEdit />}
          />
          <Route path="profile" element={<EmployerProfile employer={true} />} />
          <Route
            path="changepassword"
            element={<ChangePassword employer={true} />}
          />
          <Route
            path="dashboard"
            element={<PrivateEmpRoute component={<EmployerDashboard />} />}
          />
        </Route>

        <Route path="/admin" element={<Login />} />
        <Route
          path="/admin"
          element={<PrivateAdminRoute component={<AdminUi />} />}
        >
          <Route path="dashboard" element={<Dashboard />} />

          <Route path="role/permission" element={<ManagePerm />} />
          <Route
            path="permissiongroup/permission"
            element={<ManagePermGroup />}
          />
          <Route path="changepassword" element={<ChangePasswordAdmin />} />
          <Route path="changeimage" element={<ChangeImage />} />
        </Route>
        <Route
          path="/admin/changepassword"
          element={<PrivateAdminRoute component={<AdminUi />} />}
        />
        <Route
          path="/admin"
          element={<PrivateAdminRoute component={<AdminUi />} />}
        >
          {newRoute.map(
            (route) =>
              ((route && route.get) || (level && level == 1)) && (
                <Route
                  key={route.title}
                  path={route.title}
                  element={<PageUi />}
                >
                  <Route index element={route.main} />

                  {route.add && (
                    <Route
                      path="add"
                      element={
                        <FormUi
                          form={cloneElement(route.form, { edit: false })}
                        />
                      }
                    />
                  )}

                  {route.edit && (
                    <Route
                      path={`edit/:id`}
                      element={
                        <FormUi
                          form={cloneElement(route.form, { edit: true })}
                        />
                      }
                    />
                  )}
                </Route>
              )
          )}
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
      <CookieBanner />
    </>
  );
}

export default App;
