import React, { useEffect, useState } from "react";

import Select from "react-select";
import makeAnimated from "react-select/animated";

function MultiSelect({ data, values, onValueChange, edit ,white=false}) {
  const animatedComponents = makeAnimated();
  const [selectedValues, setSelectedValues] = useState(values);

  const handleSelectChange = (selectedOptions) => {
    //console.log("change");
    const selectedIdValues = selectedOptions.map((option) => option.value);
    setSelectedValues(selectedOptions);
    onValueChange(selectedIdValues);
  };

  const reqData = data.map((item) => ({
    value: item._id,
    label: item.title,
  }));

  useEffect(() => {
    if (edit) {
      //console.log(values);
      const resultArray = values.map((value) => {
        const dataItem = data.find((item) => item._id === value);
        //console.log(dataItem);
        return {
          value: value,
          label: dataItem ? dataItem.title : "Loading...",
        };
      });

      setSelectedValues(resultArray);
    }
  }, [data]);
  //console.log(selectedValues);

  const Style = {
    control: (provided) => ({
      ...provided,
      backgroundColor: white?'#fff':"#060d18",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: white?'black':"white",
      backgroundColor: "#060d18",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: white?'#fff':"#060d18",
      color: white?'black':"white",
    }),
  };

  return (
    <>
      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        options={reqData}
        value={selectedValues}
        onChange={handleSelectChange}
        styles={Style}
      />
    </>
  );
}

export default MultiSelect;
