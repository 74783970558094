import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ASideNav from "../Components/EDashboard/GeneralUI/ASideNav";
import ATopNav from "../Components/EDashboard/GeneralUI/ATopNav";
import { useDispatch } from "react-redux";
import { getUserAxios } from "../../helper/UserAxios";
import { useQuery } from "react-query";
import { addEmp } from "../../store/ESlice";

import { loggedIn } from "../Routes/emp.routing";

const EmpUi = ({ employer = false }) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    const status = loggedIn();
    if (!status) {
      navigate("/");
    }
  }, []);

  const dispatch = useDispatch();
  async function getField() {
    const url = `/${employer ? "employer" : "employee"}/me`;
    try {
      const response = await getUserAxios(url);
      dispatch(addEmp(response.data));
      localStorage.setItem("EmpInfoList", JSON.stringify(response.data.data));
      localStorage.setItem("Employer", JSON.stringify(employer ? true : false));
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
  const { data, error, isLoading } = useQuery("EmpInfoList", getField, {
    staleTime: 30000,
    refetchInterval: 30000,
  });
  const [isActive, setIsActive] = useState(false);
  const handleBarClick = () => {
    setIsActive(!isActive);
  };

  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  return (
    <>
      <div className="edashboard">
        <div className={`dashboard ${isActive ? "active" : ""}`}>
          <div className="container-fluid">
            <div className="dashboard-content">
              <ASideNav
                data={data}
                handleBarClick={handleBarClick}
                isActive={isActive}
                employer={employer}
              />

              <div className="main-content">
                <ATopNav
                  handleBarClick={handleBarClick}
                  data={data}
                  employer={employer}
                />
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmpUi;
