import React from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { TagSchema } from "./Schemas";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios } from "../../../helper/AxiosHelper";

const TagForm = ({ edit }) => {
  const location = useLocation();
  const client = useQueryClient();
  const navigate = useNavigate();
  const initialTag = {
    title: edit ? location.state.title : "",
    display_order: edit ? location.state.display_order : "",
    status: edit ? location.state.status : "active",
    slug: edit ? location.state.slug : "",
  };
  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: initialTag,
    validationSchema: TagSchema,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
      if (res.success) {
        navigate("/admin/tag");
        toast.success(edit ? "Updated Successfully" : "Added Successfully");
        return true;
      } else {
        toast.error(res.message);
      }
    },
  });
  const handlePost = async (e) => {
    const res = edit
      ? await PatchAxios(`/admin/tag/${location.state._id}`, values)
      : await PostAxios("/admin/tag", values);

    if (res.success) {
      navigate("/admin/tag");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
      client.refetchQueries("TagList");
      return true;
    } else {
      toast.error(res.message);
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <h2 className="text-center text-muted mb-2">
          {" "}
          {edit ? "Update" : "Add"} Tag
        </h2>
        <Row className="m-2 p-2">
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="name">
                Title
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  id="name"
                  placeholder="Title"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.title && touched.title ? (
                <p className="text-danger">{errors.title}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="slug">Slug</Form.Label>
              <Form.Control
                type="text"
                name="slug"
                value={values.slug}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="display_order">Display Order</Form.Label>
              <Form.Control
                type="text"
                name="display_order"
                value={values.display_order}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="status">Status</Form.Label>
              <Form.Select
                className="form-select form-select-sm mb-3"
                aria-label="Default select example"
                name="status"
                value={values.status}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <div className="mt-4 d-flex justify-content-between">
            <Button
              type="submit"
              className={`btn btn-primary  `}
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting}
            >
              {edit
                ? isSubmitting
                  ? "Updating...."
                  : "Update"
                : isSubmitting
                  ? "Submitting...."
                  : "Submit"}
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default TagForm;
