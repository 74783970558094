import React, { useEffect } from "react";
// import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";

const DynamicContent = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  const location = useLocation();
  const fileURL = process.env.REACT_APP_FILE_URL;
  const navigate = useNavigate();
  if (!location.state) {
    navigate("/nocontent");
    return null;
  }
  const { title, seo_image, meta_title, meta_desc, desc } = location.state;

  return (
    <main className="body-wrapper inner p-t-40 p-b-60">
      <div className="about-page wow fadeInDown" data-wow-duration="2s">
        <div className="container">
          <h1>{title}</h1>
          <div>
            {seo_image && (
              <div className="mb-3">
                <img
                  src={fileURL + "/" + seo_image}
                  alt="Preview"
                  className="img-fluid"
                />
              </div>
            )}

            {/* {meta_title && (
              <div className="mb-3">
                <label className="form-label bg-black text-white">
                  Meta Title:
                </label>
                <p className="form-control-static">{meta_title}</p>
              </div>
            )}

            {meta_desc && (
              <div className="mb-3">
                <label className="form-label bg-black text-white">
                  Meta Description:
                </label>
                <p className="form-control-static">{meta_desc}</p>
              </div>
            )} */}

            {desc && (
              <div className="mb-3">
                <div dangerouslySetInnerHTML={{ __html: desc }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default DynamicContent;
