import React, { useState } from "react";

import AdminProfile from "./AdminProfile";
import { useQuery, useQueryClient } from "react-query";
import { PatchAxios, getAxios } from "../../../../helper/AxiosHelper";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import NotViewModel from "./NotViewModel";

const TopNav = () => {
  const user = useSelector((state) => state.admin.user_info);
  const [show, SetShow] = useState(false);
  const [content, setContent] = useState([]);
  const [isActive, setIsActive] = useState(false);
  function getTimediff(timestamp) {
    const currentTime = new Date();
    const timestampDate = new Date(timestamp);
    const timeDifferenceInMilliseconds = currentTime - timestampDate;

    const days = Math.floor(
      timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24)
    );
    const hours = Math.floor(
      (timeDifferenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifferenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    let result = "";

    if (days > 0) {
      result += `${days} day${days > 1 ? "s" : ""} `;
    }

    if (hours > 0) {
      result += `${hours} hour${hours > 1 ? "s" : ""} `;
    }

    if (minutes > 0 || result === "") {
      result += `${minutes} minute${minutes > 1 ? "s" : ""}`;
    }

    return result;
  }
  async function getField() {
    const url = `/admin/notification`;
    try {
      const response = await getAxios(url);
      const fetchedUserList = response.data;
      return fetchedUserList.notification;
    } catch (error) {
      console.log(error);
    }
  }
  const query = useQueryClient();
  const handleToggleClick = (e) => {
    e.preventDefault();
    setIsActive(!isActive);
    if (window.matchMedia("(min-width: 992px)").matches) {
      e.preventDefault();
      const body = document.body;
      body.classList.toggle("sidebar-folded");
    } else if (window.matchMedia("(max-width: 991px)").matches) {
      e.preventDefault();
      const body = document.body;
      body.classList.toggle("sidebar-open");
    }
  };
  async function handleNotificationClick(item) {
    const res = await PatchAxios("/admin/notification", {
      notifications: [item._id],
    });
    if (res.success) {
      toast.success(res.message);
      query.refetchQueries("NotificationList");
    } else {
      toast.error(res.message);
    }
  }
  const { data, error, isLoading } = useQuery("NotificationList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  const handleNotificationView = async (item) => {
    const res = await getAxios(
      `/admin/apply_vacancy?vacancy_apply=${item.vacancy_apply}`
    );
    setContent({ vacancies: res.data.vacancy, id: item.vacancy_apply });
    SetShow(true);
  };
  return !show ? (
    <nav className="navbar">
      <a
        className={`sidebar-toggler ${isActive ? "active" : "not-active"}`}
        onClick={handleToggleClick}
      >
        <i className="fa fa-bars"></i>
      </a>
      <div className="navbar-content">
        <ul className="navbar-nav">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="notificationDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa-regular fa-bell fa-xl"></i>
              {/* <div className="indicator">
                <div
                  className={`${
                    data.filter((item) => !item.seen.includes(user._id))
                      .length > 0
                      ? "circle"
                      : ""
                  }`}
                ></div>
              </div> */}
            </a>
            <div
              className="dropdown-menu p-0"
              aria-labelledby="notificationDropdown"
            >
              <div className="px-3 py-2 d-flex align-items-center justify-content-between border-bottom">
                <p>
                  {data
                    ? data.filter((item) => !item.seen.includes(user._id))
                        .length
                    : 0}{" "}
                  New Notifications
                </p>
              </div>
              {data && data.length > 0 ? (
                <div className="p-1">
                  {[...data]
                    .reverse()
                    .filter((item) => !item.seen.includes(user._id))
                    .map((item) => (
                      <div key={item._id}>
                        <p
                          className="dropdown-item d-flex align-items-center justify-content-between py-2"
                          onClick={() => {
                            handleNotificationClick(item);
                          }}
                        >
                          <div className="d-flex  cursor-pointer">
                            <div className="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                              <i
                                className="fa fa-xl fa-exclamation-circle text-white"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div className="flex-grow-1 me-2">
                              <div className="d-flex justify-content-between">
                                <p>{item.title}</p>
                              </div>
                              <p className="tx-12 text-muted">
                                {getTimediff(item.createdAt)}
                              </p>
                            </div>
                          </div>
                          <div
                            className="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary eye-me-3 cursor-pointer"
                            onClick={() => handleNotificationView(item)}
                          >
                            <i
                              className="fa fa-xl fa-eye text-white"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </p>
                      </div>
                    ))}
                </div>
              ) : (
                " "
              )}
            </div>
          </li>
          <AdminProfile />
        </ul>
      </div>
    </nav>
  ) : (
    <NotViewModel
      show={show}
      handleClose={() => SetShow(false)}
      content={content}
    />
  );
};

export default TopNav;
