import { useState } from "react";

const UseMultiStep = (steps) => {
  const [count, setCount] = useState(0);

  function next() {
    setCurrentStep((i) => {
      if (i > steps.length - 1) {
        return i;
      }
      return i + 1;
    });
  }
  function back() {
    setCurrentStep((i) => {
      if (i <= 0) {
        return i;
      }
      return i - 1;
    });
  }
  function goTo(index) {
    setCurrentStep(index);
  }
  const [currentStep, setCurrentStep] = useState(0);
  return {
    count,
    setCount,
    currentStep,
    back,
    next,
    goTo,
    step: steps[currentStep],
    steps,
    isFirst: currentStep === 0 ? true : false,
    isSecondLast: currentStep === steps.length - 2 ? true : false,
    isLast: currentStep === steps.length - 1 ? true : false,
  };
};

export default UseMultiStep;
