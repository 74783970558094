import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserLoginSchema from "./UserLoginSchema";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { getCMS, loginUserAxios } from "../../../helper/UserAxios";

const UserLogin = ({ employer }) => {
  const navigate = useNavigate();
  const [createaccSec, setCreateaccSec] = useState([]);
  useEffect(() => {
    getFunc();
  }, []);

  const getFunc = async () => {
    const res = await getCMS("page_id=create-account");
    setCreateaccSec(res.data[0]);
  };

  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: UserLoginSchema,
    onSubmit: async (values, action) => {
      try {
        const res = !employer
          ? await loginUserAxios("/auth/employee/login", values)
          : await loginUserAxios("/auth/employer/login", values);
        if (res.success) {
          action.resetForm();
          toast.success(res.message);
          navigate(employer ? "/employer/dashboard" : "/employee/dashboard");
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
  });
  const handlePwForget = () => {
    navigate('/forget-password', { state: employer ? "employer" : "employee" })
  }

  return (
    <main className="p-tb-60">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <form className="module" id="loginForm" onSubmit={handleSubmit}>
              <h3 className="text-center m-b-30">
                Login to PINE VISA as {employer ? "Employer" : "Employee"}
              </h3>

              <div className="form-group">
                <input
                  type="email"
                  className={`form-control text-dark bg-light ${touched.email && errors.email ? "is-invalid" : ""
                    }`}
                  name="email"
                  id="email"
                  placeholder="Email Address"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.email && errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>

              <div className="form-group">
                <input
                  type="password"
                  className={`form-control text-dark bg-light ${touched.password && errors.password ? "is-invalid" : ""
                    }`}
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.password && errors.password && (
                  <div className="invalid-feedback">{errors.password}</div>
                )}
              </div>

              <div className="form-group d-flex justify-content-between align-items-center form-group--c">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input bg-light"
                    id="exampleCheck1"
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Remember Password
                  </label>
                </div>
                <div to="/recover-password" className="forgot-password cursor-pointer" onClick={handlePwForget}>
                  Forgot your password?
                </div>
              </div>

              <button
                type="submit"
                className="btn btn--accent w-100 mb-3"
                disabled={isSubmitting || !isValid}
              >
                {isSubmitting ? "Logging in..." : "LOGIN"}
              </button>
            </form>
          </div>
          <div className="col-md-6 offset-md-1">
            <div className="account-info">
              <h3>{createaccSec.title}</h3>
              <Link
                to="/employee/register"
                className="btn btn--accent d-block m-t-30"
              >
                Create New Account
              </Link>

              {/* <ul className="account-info-list m-t-30">
                <li>
                  Learn more about BDV Solutions' EB3 process and pricing.
                </li>
                <li>Verify if you are eligible for the EB3 process.</li>
                <li>
                  Each month discover hundreds of newly available jobs and the
                  employers ready to sponsor your visa.
                </li>
                <li>
                  Message our team for help with choosing the right job offering
                  or understanding payment options better.
                </li>
              </ul> */}
              <div className="create_acc_list" dangerouslySetInnerHTML={{ __html: createaccSec.desc }} />

            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default UserLogin;
