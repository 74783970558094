import React, { useEffect } from "react";
import { NavBar, Footer } from "../Components/GeneralUI/index";

import { Outlet } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/all.css";
import "../assets/css/style.css";
import "../assets/css/style1.css";
import "../assets/css/dashboardstyle.css";

const HomeUi = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="nmi">
        <NavBar />
        <div className="body-wrapper p-t-60">
          {/* <div className="page-content"> */}
          <Outlet />
          {/* </div> */}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default HomeUi;
