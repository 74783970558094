import * as Yup from "yup";

const VacancySchema = Yup.object().shape({
  title: Yup.string().required("Please enter Suitable title"),
  description: Yup.string(),
  slug: Yup.string(),
  employer: Yup.string().required("Please select an employer"),
  status: Yup.string().oneOf(["active", "inactive"]).default("active"),
  // category: Yup.array().of(Yup.string()),
  // tag: Yup.array().of(Yup.string()),
});

export default VacancySchema;
