import React, { useState } from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { EmployerSchema } from "./Schemas";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios } from "../../../helper/AxiosHelper";
import { PatchUserAxios } from "../../../helper/UserAxios";
const EmployerForm = ({ edit, employerEdit = false }) => {
  const location = useLocation();
  const client = useQueryClient();
  const navigate = useNavigate();
  const [images, setImages] = useState("");

  const InitialState = {
    email: edit ? location.state.email : "",
    phone: edit ? location.state.phone : "",

    ...(edit
      ? {}
      : {
          password: "",
          re_password: "",
        }),
    slug: edit ? location.state.slug : "",
    ...(employerEdit
      ? {}
      : {
          org_logo: edit ? location.state.org_logo : images,
        }),
    status: edit ? location.state.status : "active",
    //company data
    company_name: edit ? location.state.company_name : "",
    dba: edit ? location.state.dba : "",
    signing_officer_first_name: edit
      ? location.state.signing_officer_first_name
      : "",
    signing_officer_middle_name: edit
      ? location.state.signing_officer_middle_name
      : "",
    signing_officer_last_name: edit
      ? location.state.signing_officer_last_name
      : "",
    signing_officer_job_title: edit
      ? location.state.signing_officer_job_title
      : "",
    signing_officer_email: edit ? location.state.signing_officer_email : "",
    street_number_name: edit ? location.state.street_number_name : "",
    designation: edit ? location.state.designation : "",
    suite_floor_apartment_number: edit
      ? location.state.suite_floor_apartment_number
      : "",
    city: edit ? location.state.city : "",
    county: edit ? location.state.county : "",
    state: edit ? location.state.state : "",
    zip_code: edit ? location.state.zip_code : "",
    country: edit ? location.state.country : "",
    is_mailing_same_as_physical: edit
      ? location.state.is_mailing_same_as_physical
      : "",
    //sth change
    sth_street_number: edit ? location.state.sth_street_number : "",
    sth_designation: edit ? location.state.sth_designation : "",
    sth_actual_designation: edit ? location.state.sth_actual_designation : "",
    sth_city: edit ? location.state.sth_city : "",
    sth_state: edit ? location.state.sth_state : "",
    sth_zip_code: edit ? location.state.sth_zip_code : "",
    sth_ein: edit ? location.state.sth_ein : "",
    sth_state_tax_id: edit ? location.state.sth_state_tax_id : "",
    sth_daytime_tel: edit ? location.state.sth_daytime_tel : "",
    sth_mobile_tel: edit ? location.state.sth_mobile_tel : "",
    sth_fax: edit ? location.state.sth_fax : "",
    sth_website: edit ? location.state.sth_website : "",
    sth_business_type: edit ? location.state.sth_business_type : "",
    sth_naics_code: edit ? location.state.sth_naics_code : "",
    sth_date_established: edit ? location.state.sth_date_established : "",
    sth_current_employees: edit ? location.state.sth_current_employees : "",
    sth_h1b_employees: edit ? location.state.sth_h1b_employees : "",
    sth_intended_employment: edit ? location.state.sth_intended_employment : "",
    sth_about_us: edit ? location.state.sth_about_us : "",
    sth_h1b_dependent: edit ? location.state.sth_h1b_dependent : "",
    sth_willful_violator: edit ? location.state.sth_willful_violator : "",
    sth_gross_revenue: edit ? location.state.sth_gross_revenue : "",
    sth_net_income: edit ? location.state.sth_net_income : "",
    ///end
    is_higher_education: edit ? location.state.is_higher_education : "",
    is_nonprofit_affiliated: edit ? location.state.is_nonprofit_affiliated : "",
    is_research_organization: edit
      ? location.state.is_research_organization
      : "",
    is_primary_secondary_education: edit
      ? location.state.is_primary_secondary_education
      : "",
    is_curriculum_related_training: edit
      ? location.state.is_curriculum_related_training
      : "",
    is_small_employer: edit ? location.state.is_small_employer : "",
    employ_50_or_more: edit ? location.state.employ_50_or_more : "",
    more_than_50_percent_h1b: edit
      ? location.state.more_than_50_percent_h1b
      : "",
    received_tarp: edit ? location.state.received_tarp : "",
    has_collective_bargaining_agreement: edit
      ? location.state.has_collective_bargaining_agreement
      : "",
    //end
  };
  function handleImageChange(e) {
    const file = e.target.files[0];
    setImages(file);
  }
  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: EmployerSchema,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });
  const handlePost = async (e) => {
    const formData = new FormData();

    for (const key in values) {
      if (key === "org_logo") {
        continue;
      }
      formData.append(key, values[key]);
    }

    if (!edit) {
      if (InitialState.org_logo instanceof File) {
        formData.append("org_logo", InitialState.org_logo);
      } else {
        formData.append("org_logo", InitialState.org_logo);
      }
    } else {
      if (images instanceof File) {
        formData.append("org_logo", images);
      }
    }
    const url = !employerEdit
      ? edit
        ? `/admin/employer/${location.state._id}`
        : "/admin/employer"
      : "/employer/updateprofile";
    const res = !employerEdit
      ? edit
        ? await PatchAxios(url, formData)
        : await PostAxios(url, formData)
      : await PatchUserAxios(url, values);

    if (res.success) {
      if (!employerEdit) {
        client.invalidateQueries("UserList");
        navigate("/admin/employer");
      } else {
        navigate("/employer/dashboard");
      }
      toast.success(res.message);
      return true;
    } else {
      toast.error(res.message);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {employerEdit && <Row className="mt-6"></Row>}
        <Row className="m-2 p-2">
          {!employerEdit && (
            <h2 className="text-center text-muted">
              {edit ? "Update" : "Add"} Employer
            </h2>
          )}
          {/* First four column */}

          <>
            <h4 className="text-center">Basic Information</h4>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="email">
                    Email<span className="text-danger">*</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      id="email"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                  </InputGroup>
                  {errors.email && touched.email ? (
                    <p className="text-danger">{errors.email}</p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="phone">
                    Phone<span className="text-danger">*</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="number"
                      placeholder="Phone"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                  </InputGroup>
                  {errors.phone && touched.phone ? (
                    <p className="text-danger">{errors.phone}</p>
                  ) : null}
                </Form.Group>
              </Col>
              {!edit && (
                <Col md={6}>
                  <Form.Group>
                    <Form.Label htmlFor="password">
                      Password<span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="password"
                        id="password"
                        placeholder="Password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                    </InputGroup>
                    {errors.password && touched.password ? (
                      <p className="text-danger">{errors.password}</p>
                    ) : null}
                  </Form.Group>
                </Col>
              )}
              {!edit && (
                <Col md={6}>
                  <Form.Group>
                    <Form.Label htmlFor="password">
                      Retype Password<span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="password"
                        id="re_password"
                        placeholder="Retype Password"
                        name="re_password"
                        value={values.re_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                    </InputGroup>
                    {errors.re_password && touched.re_password ? (
                      <p className="text-danger">{errors.re_password}</p>
                    ) : null}
                  </Form.Group>
                </Col>
              )}

              {!employerEdit && (
                <>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor="status">Status</Form.Label>
                      <Form.Select
                        className="form-select form-select-sm mb-3"
                        aria-label="Default select example"
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </Form.Select>
                      {errors.status && touched.status ? (
                        <p className="text-danger">{errors.status}</p>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="slug" className="mb-3">
                      <Form.Label>Slug</Form.Label>
                      <Form.Control
                        type="text"
                        name="slug"
                        value={values.slug}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.slug && touched.slug ? (
                        <p className="text-danger">{errors.slug}</p>
                      ) : null}
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
          </>

          <>
            <h4 className="text-center">General Company Information</h4>
            <Row>
              {!employerEdit && (
                <Col md={6}>
                  <Form.Group controlId="org_logo" className="mb-3">
                    <Form.Label>Organization Logo</Form.Label>
                    <Form.Control
                      type="file"
                      name="org_logo"
                      onChange={handleImageChange}
                    />
                  </Form.Group>
                  {errors.org_logo && touched.org_logo ? (
                    <p className="text-danger">{errors.org_logo}</p>
                  ) : null}
                </Col>
              )}

              <Col md={6} className="mb-2">
                <Form.Group>
                  <Form.Label htmlFor="company_name">
                    Company or Organization Name
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      required
                      type="text"
                      id="company_name"
                      placeholder="Company or Organization Name"
                      name="company_name"
                      value={values.company_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                  {errors.company_name && touched.company_name ? (
                    <p className="text-danger">{errors.company_name}</p>
                  ) : null}
                </Form.Group>
              </Col>

              <Col md={6} className="mb-2">
                <Form.Group>
                  <Form.Label htmlFor="dba">DBA (if Applicable)</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      id="dba"
                      placeholder="DBA (if Applicable)"
                      name="dba"
                      value={values.dba}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                  {errors.dba && touched.dba ? (
                    <p className="text-danger">{errors.dba}</p>
                  ) : null}
                </Form.Group>
              </Col>

              <Col md={6} className="mb-2">
                <Form.Group>
                  <Form.Label htmlFor="signing_officer_first_name">
                    Signing Officer First Name(s)
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      id="signing_officer_first_name"
                      placeholder="Signing Officer First Name(s)"
                      name="signing_officer_first_name"
                      value={values.signing_officer_first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                  {errors.signing_officer_first_name &&
                  touched.signing_officer_first_name ? (
                    <p className="text-danger">
                      {errors.signing_officer_first_name}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-2">
                <Form.Group>
                  <Form.Label htmlFor="signing_officer_middle_name">
                    Signing Officer Middle Name(s)
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      id="signing_officer_middle_name"
                      placeholder="Signing Officer Middle Name(s)"
                      name="signing_officer_middle_name"
                      value={values.signing_officer_middle_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                  {errors.signing_officer_middle_name &&
                  touched.signing_officer_middle_name ? (
                    <p className="text-danger">
                      {errors.signing_officer_middle_name}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-2">
                <Form.Group>
                  <Form.Label htmlFor="signing_officer_last_name">
                    Signing Officer Last Name(s)
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      id="signing_officer_last_name"
                      placeholder="Signing Officer Last Name(s)"
                      name="signing_officer_last_name"
                      value={values.signing_officer_last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                  {errors.signing_officer_last_name &&
                  touched.signing_officer_last_name ? (
                    <p className="text-danger">
                      {errors.signing_officer_last_name}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-2">
                <Form.Group>
                  <Form.Label htmlFor="signing_officer_job_title">
                    Signing Officer Job Title
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      id="signing_officer_job_title"
                      placeholder="Signing Officer Job Title"
                      name="signing_officer_job_title"
                      value={values.signing_officer_job_title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                  {errors.signing_officer_job_title &&
                  touched.signing_officer_job_title ? (
                    <p className="text-danger">
                      {errors.signing_officer_job_title}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-2">
                <Form.Group>
                  <Form.Label htmlFor="signing_officer_email">
                    Signing Officer e-mail
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="email"
                      id="signing_officer_email"
                      placeholder="Signing Officer e-mail"
                      name="signing_officer_email"
                      value={values.signing_officer_email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                  {errors.signing_officer_email &&
                  touched.signing_officer_email ? (
                    <p className="text-danger">
                      {errors.signing_officer_email}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <h4 className="text-center">MAILING ADDRESS</h4>
            <Row>
              <Col md={6} className="mb-2">
                <Form.Group>
                  <Form.Label htmlFor="street_number_name">
                    Street Number & Name
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      id="street_number_name"
                      placeholder="Street Number & Name"
                      name="street_number_name"
                      value={values.street_number_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                  {errors.street_number_name && touched.street_number_name ? (
                    <p className="text-danger">{errors.street_number_name}</p>
                  ) : null}
                </Form.Group>
              </Col>

              <Col md={6} className="mb-2">
                <Form.Group>
                  <Form.Label htmlFor="designation">
                    Select Designation
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Select
                      id="designation"
                      name="designation"
                      value={values.designation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Designation</option>
                      <option value="apartment">Apartment</option>
                      <option value="floor">Floor</option>
                      <option value="suite">Suite</option>
                      <option value="none">None</option>
                    </Form.Select>
                  </InputGroup>
                  {errors.designation && touched.designation ? (
                    <p className="text-danger">{errors.designation}</p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-2">
                <Form.Group>
                  <Form.Label htmlFor="suite_floor_apartment_number">
                    Suite, Floor or Apartment Number
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      id="suite_floor_apartment_number"
                      placeholder="Suite, Floor or Apartment Number"
                      name="suite_floor_apartment_number"
                      value={values.suite_floor_apartment_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                  {errors.suite_floor_apartment_number &&
                  touched.suite_floor_apartment_number ? (
                    <p className="text-danger">
                      {errors.suite_floor_apartment_number}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>

              <Col md={6} className="mb-2">
                <Form.Group>
                  <Form.Label htmlFor="city">City</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      id="city"
                      placeholder="City"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                  {errors.city && touched.city ? (
                    <p className="text-danger">{errors.city}</p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-2">
                <Form.Group>
                  <Form.Label htmlFor="county">County</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      id="county"
                      placeholder="County"
                      name="county"
                      value={values.county}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                  {errors.county && touched.county ? (
                    <p className="text-danger">{errors.county}</p>
                  ) : null}
                </Form.Group>
              </Col>

              <Col md={6} className="mb-2">
                <Form.Group>
                  <Form.Label htmlFor="state">State</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      id="state"
                      placeholder="State"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                  {errors.state && touched.state ? (
                    <p className="text-danger">{errors.state}</p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-2">
                <Form.Group>
                  <Form.Label htmlFor="zip_code">Zip Code</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      id="zip_code"
                      placeholder="Zip Code"
                      name="zip_code"
                      value={values.zip_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                  {errors.zip_code && touched.zip_code ? (
                    <p className="text-danger">{errors.zip_code}</p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-2">
                <Form.Group>
                  <Form.Label htmlFor="country">Country</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      id="country"
                      placeholder="Country"
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                  {errors.country && touched.country ? (
                    <p className="text-danger">{errors.country}</p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-2">
                <Form.Group>
                  <Form.Label htmlFor="is_mailing_same_as_physical">
                    Is Current Mailing Address the same as your Physical
                    Address?
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Select
                      id="is_mailing_same_as_physical"
                      name="is_mailing_same_as_physical"
                      value={values.is_mailing_same_as_physical}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select One</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </InputGroup>
                  {errors.is_mailing_same_as_physical &&
                  touched.is_mailing_same_as_physical ? (
                    <p className="text-danger">
                      {errors.is_mailing_same_as_physical}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            {values.is_mailing_same_as_physical !== "yes" && (
              <Row>
                <h4 className="text-center">
                  CURRENT PHYSICAL ADDRESS - IF DIFFERENT FROM MAILING ADDRESS
                </h4>
                <Col md={6} className="mb-2">
                  {/* Street Number and Name */}
                  <Form.Group>
                    <Form.Label htmlFor="street_number">
                      Street Number and Name
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="street_number"
                        name="sth_street_number"
                        value={values.sth_street_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_street_number && touched.sth_street_number ? (
                      <p className="text-danger">{errors.sth_street_number}</p>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-2">
                  {/* Select Designation, if None choose None */}
                  <Form.Group>
                    <Form.Label htmlFor="designation">
                      Select Designation, if None choose None
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Select
                        id="designation"
                        name="sth_designation"
                        value={values.sth_designation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">-</option>
                        <option value="apartment">Apartment</option>
                        <option value="floor">Floor</option>
                        <option value="suite">Suite</option>
                        <option value="none">None</option>
                      </Form.Select>
                    </InputGroup>
                    {errors.sth_designation && touched.sth_designation ? (
                      <p className="text-danger">{errors.sth_designation}</p>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-2">
                  {/* Provide ACTUAL Designation (Apt., Floor, or Suite #), if None leave Blank */}
                  <Form.Group>
                    <Form.Label htmlFor="actual_designation">
                      Provide ACTUAL Designation (Apt., Floor, or Suite #), if
                      None leave Blank
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="actual_designation"
                        name="sth_actual_designation"
                        value={values.sth_actual_designation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_actual_designation &&
                    touched.sth_actual_designation ? (
                      <p className="text-danger">
                        {errors.sth_actual_designation}
                      </p>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-2">
                  {/* City */}
                  <Form.Group>
                    <Form.Label htmlFor="city">City</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="city"
                        name="sth_city"
                        value={values.sth_city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_city && touched.sth_city ? (
                      <p className="text-danger">{errors.sth_city}</p>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-2">
                  {/* U.S. State - 2 letter abbreviation */}
                  <Form.Group>
                    <Form.Label htmlFor="state">
                      U.S. State - 2 letter abbreviation
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="state"
                        name="sth_state"
                        value={values.sth_state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_state && touched.sth_state ? (
                      <p className="text-danger">{errors.sth_state}</p>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-2">
                  {/* U.S. Zip Code */}
                  <Form.Group>
                    <Form.Label htmlFor="zip_code">U.S. Zip Code</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="zip_code"
                        name="sth_zip_code"
                        value={values.sth_zip_code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_zip_code && touched.sth_zip_code ? (
                      <p className="text-danger">{errors.sth_zip_code}</p>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-2">
                  {/* Federal Employer Identification Number (EIN) */}
                  <Form.Group>
                    <Form.Label htmlFor="ein">
                      Federal Employer Identification Number (EIN)
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="ein"
                        name="sth_ein"
                        value={values.sth_ein}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_ein && touched.sth_ein ? (
                      <p className="text-danger">{errors.sth_ein}</p>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-2">
                  {/* State Tax ID/Unemployment Ins. # */}
                  <Form.Group>
                    <Form.Label htmlFor="state_tax_id">
                      State Tax ID/Unemployment Ins. #
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="state_tax_id"
                        name="sth_state_tax_id"
                        value={values.sth_state_tax_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_state_tax_id && touched.sth_state_tax_id ? (
                      <p className="text-danger">{errors.sth_state_tax_id}</p>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-2">
                  {/* Daytime Telephone # */}
                  <Form.Group>
                    <Form.Label htmlFor="daytime_tel">
                      Daytime Telephone #
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="daytime_tel"
                        name="sth_daytime_tel"
                        value={values.sth_daytime_tel}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_daytime_tel && touched.sth_daytime_tel ? (
                      <p className="text-danger">{errors.sth_daytime_tel}</p>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-2">
                  {/* Mobile Tel # */}
                  <Form.Group>
                    <Form.Label htmlFor="mobile_tel">Mobile Tel #</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="mobile_tel"
                        name="sth_mobile_tel"
                        value={values.sth_mobile_tel}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_mobile_tel && touched.sth_mobile_tel ? (
                      <p className="text-danger">{errors.sth_mobile_tel}</p>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-2">
                  {/* Fax # */}
                  <Form.Group>
                    <Form.Label htmlFor="fax">Fax #</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="fax"
                        name="sth_fax"
                        value={values.sth_fax}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_fax && touched.sth_fax ? (
                      <p className="text-danger">{errors.sth_fax}</p>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-2">
                  {/* Web Site Address (URL) */}
                  <Form.Group>
                    <Form.Label htmlFor="website">
                      Web Site Address (URL)
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="website"
                        name="sth_website"
                        value={values.sth_website}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_website && touched.sth_website ? (
                      <p className="text-danger">{errors.sth_website}</p>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-2">
                  {/* Type of Business */}
                  <Form.Group>
                    <Form.Label htmlFor="business_type">
                      Type of Business
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="business_type"
                        name="sth_business_type"
                        value={values.sth_business_type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_business_type && touched.sth_business_type ? (
                      <p className="text-danger">{errors.sth_business_type}</p>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-2">
                  {/* NAICS Code */}
                  <Form.Group>
                    <Form.Label htmlFor="naics_code">NAICS Code</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="naics_code"
                        name="sth_naics_code"
                        value={values.sth_naics_code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_naics_code && touched.sth_naics_code ? (
                      <p className="text-danger">{errors.sth_naics_code}</p>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-2">
                  {/* Date Established */}
                  <Form.Group>
                    <Form.Label htmlFor="date_established">
                      Date Established
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="date_established"
                        name="sth_date_established"
                        value={values.sth_date_established}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_date_established &&
                    touched.sth_date_established ? (
                      <p className="text-danger">
                        {errors.sth_date_established}
                      </p>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-2">
                  {/* Current # of Employees */}
                  <Form.Group>
                    <Form.Label htmlFor="current_employees">
                      Current # of Employees
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="current_employees"
                        name="sth_current_employees"
                        value={values.sth_current_employees}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_current_employees &&
                    touched.sth_current_employees ? (
                      <p className="text-danger">
                        {errors.sth_current_employees}
                      </p>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-2">
                  {/* Current # of H-1B Employees (H-1B Visa only) */}
                  <Form.Group>
                    <Form.Label htmlFor="h1b_employees">
                      Current # of H-1B Employees (H-1B Visa only)
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="h1b_employees"
                        name="sth_h1b_employees"
                        value={values.sth_h1b_employees}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_h1b_employees && touched.sth_h1b_employees ? (
                      <p className="text-danger">{errors.sth_h1b_employees}</p>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-2">
                  {/* Number of current employees on payroll in the area of intended employment. (PERM cases only) */}
                  <Form.Group>
                    <Form.Label htmlFor="intended_employment">
                      Number of current employees on payroll in the area of
                      intended employment. (PERM cases only)
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="intended_employment"
                        name="sth_intended_employment"
                        value={values.sth_intended_employment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_intended_employment &&
                    touched.sth_intended_employment ? (
                      <p className="text-danger">
                        {errors.sth_intended_employment}
                      </p>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-2">
                  {/* Are you an H-1B dependent employer? */}
                  <Form.Group>
                    <Form.Label htmlFor="h1b_dependent">
                      Are you an H-1B dependent employer?
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Select
                        id="h1b_dependent"
                        name="sth_h1b_dependent"
                        value={values.sth_h1b_dependent}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select an option</option>
                        <option value="yes">Yes</option>
                        <option value="not_sure">Not sure</option>
                        <option value="no">No</option>
                      </Form.Select>
                    </InputGroup>
                    {errors.sth_h1b_dependent && touched.sth_h1b_dependent ? (
                      <p className="text-danger">{errors.sth_h1b_dependent}</p>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-2">
                  {/* Have you ever been found to be a willful violator? */}
                  <Form.Group>
                    <Form.Label htmlFor="willful_violator">
                      Have you ever been found to be a willful violator?
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Select
                        id="willful_violator"
                        name="sth_willful_violator"
                        value={values.sth_willful_violator}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select an option</option>
                        <option value="yes">Yes</option>
                        <option value="not_sure">Not sure</option>
                        <option value="no">No</option>
                      </Form.Select>
                    </InputGroup>
                    {errors.sth_willful_violator &&
                    touched.sth_willful_violator ? (
                      <p className="text-danger">
                        {errors.sth_willful_violator}
                      </p>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-2">
                  {/* Gross Annual Revenue */}
                  <Form.Group>
                    <Form.Label htmlFor="gross_revenue">
                      Gross Annual Revenue
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="gross_revenue"
                        name="sth_gross_revenue"
                        value={values.sth_gross_revenue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_gross_revenue && touched.sth_gross_revenue ? (
                      <p className="text-danger">{errors.sth_gross_revenue}</p>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-2">
                  <Form.Group>
                    <Form.Label htmlFor="netIncome">
                      Net Annual Income
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        id="netIncome"
                        name="sth_net_income"
                        value={values.sth_net_income}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_net_income && touched.sth_net_income ? (
                      <p className="text-danger">{errors.sth_net_income}</p>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-2">
                  {/* About Us */}
                  <Form.Group>
                    <Form.Label htmlFor="about_us">About Us</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        as="textarea"
                        id="about_us"
                        name="sth_about_us"
                        value={values.sth_about_us}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.sth_about_us && touched.sth_about_us ? (
                      <p className="text-danger">{errors.sth_about_us}</p>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
            )}
            <h4 className="text-center">H-1B Visa Information</h4>
            <Row>
              <Col md={6} className="mb-2">
                {/* Are you an institution of higher education? */}
                <Form.Group>
                  <Form.Label htmlFor="is_higher_education">
                    Are you an institution of higher education?
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Select
                      id="is_higher_education"
                      name="is_higher_education"
                      value={values.is_higher_education}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select an option</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </InputGroup>
                  {errors.is_higher_education && touched.is_higher_education ? (
                    <p className="text-danger">{errors.is_higher_education}</p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-2">
                {/* Are you a nonprofit organization or entity related to or affiliated with an institution of higher education? */}
                <Form.Group>
                  <Form.Label htmlFor="is_nonprofit_affiliated">
                    Are you a nonprofit organization or entity related to or
                    affiliated with an institution of higher education?
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Select
                      id="is_nonprofit_affiliated"
                      name="is_nonprofit_affiliated"
                      value={values.is_nonprofit_affiliated}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select an option</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </InputGroup>
                  {errors.is_nonprofit_affiliated &&
                  touched.is_nonprofit_affiliated ? (
                    <p className="text-danger">
                      {errors.is_nonprofit_affiliated}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>

              <Col md={6} className="mb-2">
                {/* Are you a nonprofit research organization or a governmental research organization? */}
                <Form.Group>
                  <Form.Label htmlFor="is_research_organization">
                    Are you a nonprofit research organization or a governmental
                    research organization?
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Select
                      id="is_research_organization"
                      name="is_research_organization"
                      value={values.is_research_organization}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select an option</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </InputGroup>
                  {errors.is_research_organization &&
                  touched.is_research_organization ? (
                    <p className="text-danger">
                      {errors.is_research_organization}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-2">
                {/* Are you a primary or secondary education institution? */}
                <Form.Group>
                  <Form.Label htmlFor="is_primary_secondary_education">
                    Are you a primary or secondary education institution?
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Select
                      id="is_primary_secondary_education"
                      name="is_primary_secondary_education"
                      value={values.is_primary_secondary_education}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select an option</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </InputGroup>
                  {errors.is_primary_secondary_education &&
                  touched.is_primary_secondary_education ? (
                    <p className="text-danger">
                      {errors.is_primary_secondary_education}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>

              <Col md={6} className="mb-2">
                {/* Are you a non-profit entity that engages in an established curriculum-related clinical training of students registered at such an institution? */}
                <Form.Group>
                  <Form.Label htmlFor="is_curriculum_related_training">
                    Are you a non-profit entity that engages in an established
                    curriculum-related clinical training of students registered
                    at such an institution?
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Select
                      id="is_curriculum_related_training"
                      name="is_curriculum_related_training"
                      value={values.is_curriculum_related_training}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select an option</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </InputGroup>
                  {errors.is_curriculum_related_training &&
                  touched.is_curriculum_related_training ? (
                    <p className="text-danger">
                      {errors.is_curriculum_related_training}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-2">
                {/* Do you currently employ a total of 25 or less full-time equivalent employees in the United States, including any affiliate or subsidiary of your company? */}
                <Form.Group>
                  <Form.Label htmlFor="is_small_employer">
                    Do you currently employ a total of 25 or less full-time
                    equivalent employees in the United States, including any
                    affiliate or subsidiary of your company?
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Select
                      id="is_small_employer"
                      name="is_small_employer"
                      value={values.is_small_employer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select an option</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </InputGroup>
                  {errors.is_small_employer && touched.is_small_employer ? (
                    <p className="text-danger">{errors.is_small_employer}</p>
                  ) : null}
                </Form.Group>
              </Col>

              <Col md={6} className="mb-2">
                {/* Do you employ 50 or more individuals in the U.S.? */}
                <Form.Group>
                  <Form.Label htmlFor="employ_50_or_more">
                    Do you employ 50 or more individuals in the U.S.?
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Select
                      id="employ_50_or_more"
                      name="employ_50_or_more"
                      value={values.employ_50_or_more}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select an option</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </InputGroup>
                  {errors.employ_50_or_more && touched.employ_50_or_more ? (
                    <p className="text-danger">{errors.employ_50_or_more}</p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-2">
                {/* If yes, are more than 50% of those employees in H-1B or L nonimmigrant status? */}
                <Form.Group>
                  <Form.Label htmlFor="more_than_50_percent_H1B">
                    If yes, are more than 50% of those employees in H-1B or L
                    nonimmigrant status?
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Select
                      id="more_than_50_percent_H1B"
                      name="more_than_50_percent_h1b"
                      value={values.more_than_50_percent_h1b}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select an option</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </InputGroup>
                  {errors.more_than_50_percent_h1b &&
                  touched.more_than_50_percent_h1b ? (
                    <p className="text-danger">
                      {errors.more_than_50_percent_h1b}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>

              <Col md={6} className="mb-2">
                {/* Have you received TARP Funding? */}
                <Form.Group>
                  <Form.Label htmlFor="received_TARP">
                    Have you received TARP Funding?
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Select
                      id="received_TARP"
                      name="received_tarp"
                      value={values.received_tarp}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select an option</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </InputGroup>
                  {errors.received_tarp && touched.received_tarp ? (
                    <p className="text-danger">{errors.received_tarp}</p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-2">
                {/* Does your organization have a Collective Bargaining Agreement? */}
                <Form.Group>
                  <Form.Label htmlFor="has_collective_bargaining_agreement">
                    Does your organization have a Collective Bargaining
                    Agreement?
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Select
                      id="has_collective_bargaining_agreement"
                      name="has_collective_bargaining_agreement"
                      value={values.has_collective_bargaining_agreement}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select an option</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </InputGroup>
                  {errors.has_collective_bargaining_agreement &&
                  touched.has_collective_bargaining_agreement ? (
                    <p className="text-danger">
                      {errors.has_collective_bargaining_agreement}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>

            <div className="mt-4 d-flex justify-content-between">
              <Button
                type="submit"
                className={`btn btn-primary  `}
                onClick={handleSubmit}
                disabled={!isValid || isSubmitting}
              >
                {edit
                  ? isSubmitting
                    ? "Updating...."
                    : "Update"
                  : isSubmitting
                  ? "Submitting...."
                  : "Submit"}
              </Button>
              <Button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </div>
          </>
        </Row>
      </Form>
    </>
  );
};

export default EmployerForm;
