import React from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";

const Role = () => {
  const rolesColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Level",
      selector: (row) => row.level,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => <ActionButton row={row} pageTitle="role" hasPermission />,
    },
  ];
  const { data, error, isLoading } = useQuery("RoleList", getField);
  async function getField() {
    const url = `/admin/role`;
    try {
      const response = await getAxios(url);
      const fetchedRoleList = response.data;
      return fetchedRoleList.roles;
    } catch (error) {
      console.log(error);
    }
  }
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }

  return <PageData tableData={data} columns={rolesColumns} title="Role" />;
};

export default Role;
