// adminSlice.js
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  user_info: {},
  permission:[],
  count:[],
  sidebar:[],
};
const adminSlice = createSlice({
  name: "adminInfo",
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.user_info = action.payload; 
    },
    deleteUser: (state, action) => {
      state.user_info = initialState; 
    },
    addPermission: (state, action) => {
      state.permission = action.payload; 
    },
    deletePermission: (state, action) => {
      state.permission = initialState; 
    },
    addCount: (state, action) => {
      state.count = action.payload; 
    },
    deleteCount: (state, action) => {
      state.count = initialState; 
    },
    addSidebar: (state, action) => {
      state.sidebar = action.payload; 
    },
    deleteSidebar: (state, action) => {
      state.sidebar = initialState; 
    },
  },
});

export const { addUser, deleteUser,addPermission,deletePermission,addCount,deleteCount,addSidebar,deleteSidebar } = adminSlice.actions;
export default adminSlice.reducer;
