import React from "react";
import { Modal, Button } from "react-bootstrap";

const EmployeeStatusModal = ({
  show,
  handleClose,
  Content,
  appointmentDate,
  link,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      scrollable
      className="not-confirm"
    >
      <Modal.Header closeButton>
        <Modal.Title>Onboarding Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="li">Appointment date: {appointmentDate}</ul>

        {Content.onboarding.zoom_link === "done" && (
          <>
            <ul className="li">Meeting Link: {link}</ul>
            <ul className="li">
              Meeting:{" "}
              {Content.onboarding.meeting === "pending"
                ? "Not Completed"
                : "Completed"}
            </ul>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeeStatusModal;
