import React from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";

const Permission = () => {

  const permissionColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <ActionButton row={row} pageTitle="permission" />
      ),
    },
  ];

  async function getField() {
    const url = `/admin/permission`;
    try {
      const response = await getAxios(url);
      const fetchedPermissionList = response.data;

      return (fetchedPermissionList.permission);
    } catch (error) {
      console.log(error);
    }
  }

  const { data, error, isLoading } = useQuery("PermissionList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  return (
    <>
      <PageData
        columns={permissionColumns}
        tableData={data}
        title="Permission"
      />
    </>
  );
};

export default Permission;
