import * as Yup from "yup";

const SysInfoFileSchema = Yup.object().shape({
  config_name: Yup.string().required("Please enter a title").trim(),
  config_caption: Yup.string().required("Please enter a caption").trim(),
  image: Yup.string(),
  status: Yup.string().oneOf(["active", "inactive"]).default("active"),
});

export default SysInfoFileSchema;
