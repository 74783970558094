import React from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios } from "../../../helper/AxiosHelper";

import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";

const PageLimitForm = ({ edit }) => {
  const location = useLocation();
  const client = useQueryClient();
  const navigate = useNavigate();
  const InitialState = {
    limit_no: edit ? location.state.limit_no : "",
  };
  const PageLimitSchema = Yup.object().shape({
    limit_no: Yup.string()
      .required("Field is required")
      .min(0, "Page Limit Must be Positive"),
  });
  const handlePost = async (e) => {
    const res = edit
      ? await PatchAxios(`/admin/pagelimit/${location.state._id}`, values)
      : await PostAxios("/admin/pagelimit", values);

    if (res.success) {
      navigate("/admin/pagelimit");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
      return true;
    } else {
      toast.error(res.error);
    }
  };
  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: PageLimitSchema,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
      if (res.success) {
        navigate("/admin/pagelimit");
        toast.success(edit ? "Updated Successfully" : "Added Successfully");
        return true;
      } else {
        toast.error(res.error);
      }
    },
  });
  return (
    <Form onSubmit={handleSubmit}>
      <Row className="m-2 p-2">
        <Col md={6}>
          <Form.Group controlId="limit_no">
            <Form.Label>Limit</Form.Label>
            <Form.Control
              type="number"
              name="limit_no"
              value={values.limit_no}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.limit_no && errors.limit_no}
              placeholder="PageLimit"
            />
            <Form.Control.Feedback type="invalid">
              {errors.limit_no}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <div className="mt-4 d-flex justify-content-between">
        <Button type="submit" className="btn btn-primary ">
          Submit
        </Button>
        <Button
          type="button"
          className="btn btn-danger"
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
};
export default PageLimitForm;
