import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

export const PrivateEmpRoute = ({ component, employer }) => {
  // TODO: logic implement
  const logged = loggedIn();
  return logged ? (
    component
  ) : (
    <Navigate to={`/${employer ? "employer" : "employee"}/login`}></Navigate>
  );
};

export const loggedIn = () => {
  let is_logged_in = false;

  const token = Cookies.get(`employeetoken`);

  if (token) {
    is_logged_in = true;
  }
  return is_logged_in;
};
