import React, { useState } from "react";
import { LogoutUser } from "../../helper/UserAxios";
import { deleteEmp } from "../../store/ESlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

const EmpInfo = ({ data, employer = false }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const client = useQueryClient();
  const handleLogout = async () => {
    const res = await LogoutUser(`${employer ? "employer" : "employee"}`);
    if (res.success) {
      client.removeQueries("EmpInfoList");
      localStorage.removeItem("Employer");
      localStorage.removeItem("EmpInfoList");
      dispatch(deleteEmp());
      navigate(`${employer ? "/employer/dashboard" : "/employee/dashboard"}`);
      toast.success(res.message);
    } else {
      navigate("/");
      toast.error(res.message);
    }
  };
  return (
    <div className="notif">
      <div className="notif-user">
        <div className={`dropdown ${isDropdownOpen ? "show" : ""}`}>
          <div
            className="login-user-profile dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={toggleDropdown}
          >
            {employer
              ? data && data.company_name.charAt(0).toUpperCase()
              : data && data.first_name.charAt(0).toUpperCase()}
          </div>
          <div
            className={`dropdown-menu dropdown-menu--header ${
              isDropdownOpen ? "show drp-css" : ""
            }`}
            aria-labelledby="dropdownMenuButton"
          >
            <div className="dropdown-item">
              <h5 className="m-b-0">Welcome</h5>
              <a href="">
                {!employer ? (
                  `${data && data.first_name} ${data && data.last_name}`
                ) : (
                  <>
                    {data && data.first_name}
                    <br />
                    {data && data.company_name}
                  </>
                )}
              </a>
            </div>
            <div className="dropdown-divider"></div>
            <Link
              className="dropdown-item"
              to={`/${employer ? "employer" : "employee"}/dashboard`}
            >
              <i className="fa fa-tachometer mx-1"></i> Dashboard
            </Link>
            {!employer && (
              <Link
                className="dropdown-item"
                to={`/${employer ? "employer" : "employee"}/profile`}
              >
                <i className="fa fa-user mx-1"></i> My Profile
              </Link>
            )}
            <Link
              className="dropdown-item"
              to={`/${employer ? "employer" : "employee"}/changepassword`}
            >
              <i className="fa fa-key mx-1"></i> Change Password
            </Link>
            <div
              className="dropdown-item cursor-pointer"
              onClick={handleLogout}
            >
              <i className="fa fa-sign-out mx-1"></i>Logout
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmpInfo;
