import * as Yup from "yup";

const ChangePasswordSchema = Yup.object().shape({
  old_password: Yup.string().required("Old Password is required"),
  new_password: Yup.string()
    .required("New Password is required")
    .min(8, "Password must be at least 8 characters"),
  re_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match")
    .required("Retype Password is required"),
});

export default ChangePasswordSchema;
