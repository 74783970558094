import React from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios } from "../../../helper/AxiosHelper";

import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { SocialLinkSchema } from "./Schemas";
import { useQueryClient } from "react-query";
const SocialLinkForm = ({ edit }) => {
  const client = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const InitialState = {
    title: edit ? location.state.title : "",
    target_url: edit ? location.state.target_url : "",
    target_option: edit ? location.state.target_option : "",
  };
  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: SocialLinkSchema,
    onSubmit: async (action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });
  const handlePost = async (e) => {
    const res = edit
      ? await PatchAxios(`/admin/sociallink/${location.state._id}`, values)
      : await PostAxios("/admin/sociallink", values);

    if (res.success) {
      navigate("/admin/sociallink");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
      client.refetchQueries("SociallinkList");
      return true;
    } else {
      toast.error(res.message);
    }
  };
  return (
    <>
      <Form>
        <h2 className="text-center text-muted mb-2">Add Social Link</h2>

        <Row className="m-2 p-2">
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="name">
                Title
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  id="title"
                  placeholder="Title"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.title && touched.title ? (
                <p className="text-danger">{errors.title}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="name">Target URL</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  placeholder="Target Url"
                  name="target_url"
                  value={values.target_url}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.target_url && touched.target_url ? (
                <p className="text-danger">{errors.target_url}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="name">Target Option</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  placeholder="Target Option"
                  name="target_option"
                  value={values.target_option}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.target_option && touched.target_option ? (
                <p className="text-danger">{errors.target_option}</p>
              ) : null}
            </Form.Group>
          </Col>

          <div className="mt-4 d-flex justify-content-between">
            <Button
              type="submit"
              className={`btn btn-primary  `}
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting}
            >
              {edit
                ? isSubmitting
                  ? "Updating...."
                  : "Update"
                : isSubmitting
                ? "Submitting...."
                : "Submit"}
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default SocialLinkForm;
