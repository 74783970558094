import * as yup from "yup";

const RegisterSchema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  phone: yup
    .string()
    .required("Please enter your mobile Number")
    .min(10, "Enter a valid contact"),

  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  re_password: yup
    .string()
    .required("Emter Your Password Again")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const EditSchema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  phone: yup
    .string()
    .required("Please enter your mobile Number")
    .min(12, "Please Enter Valid Contact"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
});
export default RegisterSchema;
