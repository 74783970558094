import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Col, Form } from "react-bootstrap";
const Editor = ({ values, onValueChange }) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleEditorChange = (e) => {
    onValueChange(e);
  };
  return (
    <Col md={12}>
      <Form.Group>
        <ReactQuill
          className="editor-height"
          theme="snow"
          modules={modules}
          formats={formats}
          value={values}
          onChange={handleEditorChange}
        ></ReactQuill>
      </Form.Group>
    </Col>
  );
};

export default Editor;
