import React from "react";

import {
  HeroSection,
  Interview,
  ListSection,
  Step,
  Journey,
} from "../../Components";
const LandingPage = () => {
  return (
    <>
      <HeroSection />
      <ListSection />
      <Journey />
      <Step />
      <Interview />
      
    </>
  );
};

export default LandingPage;
