import React from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { PatchAxios } from "../../../helper/AxiosHelper";
import { useQueryClient } from "react-query";
const SideBarForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const client = useQueryClient();
  const schema = Yup.object().shape({
    display_name: Yup.string().required("Display Name is required"),
    display_order: Yup.string().required("Enter a valid Display Order"),
  });
  const initialState = {
    display_name: state.display_name, // Initial value for side_bar (an empty string)
    display_order: state.display_order, // Initial value for side_bar (an empty string)
  };
  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: initialState,
    validationSchema: schema,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });
  const handlePost = async (e) => {
    const res = await PatchAxios(`/admin/sidebar/${state._id}`, values);
    if (res.success) {
      client.refetchQueries("SidebarList");
      navigate("/admin/sidebar");
      toast.success("Updated Successfully");
      return true;
    } else {
      toast.error(res.message);
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Row className="m-2 p-2">
        <Col md={6}>
          <Form.Group>
            <Form.Label htmlFor="name">
              Side Bar Name
              <span className="text-danger">*</span>
            </Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                required
                type="text"
                id="name"
                placeholder="Title"
                name="display_name"
                value={values.display_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </InputGroup>
            {errors.display_name && touched.display_name ? (
              <p className="text-danger">{errors.display_name}</p>
            ) : null}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label htmlFor="name">
              Display Order
              <span className="text-danger">*</span>
            </Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                required
                type="text"
                placeholder="Display Order"
                name="display_order"
                value={values.display_order}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </InputGroup>
            {errors.display_order && touched.display_order ? (
              <p className="text-danger">{errors.display_order}</p>
            ) : null}
          </Form.Group>
        </Col>
        <div className="mt-4 d-flex justify-content-between">
          <Button
            type="submit"
            className={`btn btn-primary  `}
            onClick={handleSubmit}
            disabled={!isValid || isSubmitting}
          >
            {isSubmitting ? "Submitting...." : "Submit"}
          </Button>
          <Button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </div>
      </Row>
    </Form>
  );
};

export default SideBarForm;
