import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import { getAxios } from "../../../../helper/AxiosHelper";
import Collapsible from "react-collapsible";
import { snakeCaseToTitleCase } from "../../../../helper/PageMaping";
import { logo } from "../../../../Home/assets/images/";
import { useDispatch, useSelector } from "react-redux";

const SideNav = () => {
  let i = 0;
  const [isActive, setIsActive] = useState(false);
  const perm = useSelector((state) => state.admin.permission);
  const [newData, setNewData] = useState([]);
  let level = JSON.parse(localStorage.getItem("level"));
  const sidebarArray = useSelector((state) => state.admin.sidebar);
  const count = useSelector((state) => state.admin.count);

  if (level == null) {
    level = JSON.parse(localStorage.getItem("level"));
  }
  const handleToggleClick = (e) => {
    e.preventDefault();
    setIsActive(!isActive);

    if (window.matchMedia("(min-width: 992px)").matches) {
      e.preventDefault();
      const body = document.body;
      body.classList.toggle("sidebar-folded");
    } else if (window.matchMedia("(max-width: 991px)").matches) {
      e.preventDefault();
      const body = document.body;
      body.classList.toggle("sidebar-open");
    }
  };
  const { data, error, isLoading } = useQuery("SidebarList", getList, {
    refetchInterval: 1000,
  });

  const { data: countForLevel1 } = useQuery(
    "DashboardListAdmin",
    getDashboardCount
  );
  useEffect(() => {
    setNewData(sidebarArray);
  }, [newData, sidebarArray]);

  async function getDashboardCount() {
    const res = await getAxios("/admin/dashboard");
    return res.data.counts;
  }

  async function getList() {
    return sidebarArray;
  }

  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  const noSideNav = ["dashboard", "notification"];
  function renderSideNav(route) {
    return !noSideNav.includes(route);
  }
  return (
    <>
      <nav className="sidebar">
        <div className="sidebar-header">
          <NavLink to="/" className="sidebar-brand ">
            <img src={logo} alt="Pine Visa" height="50px" className="mt-2" />
          </NavLink>
          <div
            className={`sidebar-toggler ${isActive ? "not-active" : "active"}`}
            onClick={handleToggleClick}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="sidebar-body">
          <ul className="nav">
            <li className="nav-item">
              <NavLink to="/admin/dashboard" className="nav-link">
                <i className="link-icon fa fa-box"></i>
                <span className="link-title">Dashboard</span>
              </NavLink>
            </li>
            {newData &&
              newData.map((item, index) => {
                const lowercaseName =
                  level > 1
                    ? item.name.toLowerCase()
                    : item.side_bar.toLowerCase();
                const counter = 0;
                // level > 1
                //   ? count[lowercaseName] || 0
                //   : countForLevel1[lowercaseName] || 0;
                if (item.status === "active" || (level && level > 1)) {
                  const matchingUpdate = perm.find(
                    (update) => update.title === lowercaseName
                  );
                  return (
                    item &&
                    renderSideNav(item.display_name.toLowerCase()) &&
                    (level === 1 || (matchingUpdate && matchingUpdate.get)) && (
                      <li className="nav-item mt-2 w-100" key={index}>
                        <div className="d-flex justify-content-start text-muted">
                          <i className="link-icon fa fa-box pt-1" />
                          <span className="m-2" />
                          <Collapsible
                            trigger={
                              <div className="d-flex justify-content-between w-100 nav-link special-case">
                                <span className="link-title">
                                  {snakeCaseToTitleCase(item.display_name)}
                                  <span className="badge bg-light text-dark mx-2">
                                    {counter}
                                  </span>
                                </span>
                                <i className="fa fa-angle-down pt-1 " />
                              </div>
                            }
                            openedClassName="w-100 text-info"
                            className="link-title w-100"
                          >
                            <NavLink
                              to={`/admin/${lowercaseName}`}
                              className="nav-link"
                            >
                              <i className="fa fa-angle-double-right" />
                              <span className="m-2" />
                              List
                            </NavLink>

                            {level > 1 ? (
                              matchingUpdate &&
                              matchingUpdate.add && (
                                <NavLink
                                  to={`/admin/${lowercaseName}/add`}
                                  className="nav-link"
                                >
                                  <i className="fa fa-plus" />
                                  <span className="m-2" />
                                  Add
                                </NavLink>
                              )
                            ) : (
                              <NavLink
                                to={`/admin/${lowercaseName}/add`}
                                className="nav-link"
                              >
                                <i className="fa fa-plus" />
                                <span className="m-2" />
                                Add
                              </NavLink>
                            )}
                          </Collapsible>
                        </div>
                      </li>
                    )
                  );
                } else {
                  return null; // Or any other default content or element you want when status is not "active"
                }
              })}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default SideNav;
