import React, { useRef, useState } from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios } from "../../../helper/AxiosHelper";
import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { SysInfoFileSchema } from "./Schemas";
import { UploadImage } from "../../../Home/assets/images";
const SysInfoFileForm = ({ edit }) => {
  const baseURL = process.env.REACT_APP_FILE_URL;
  
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file)
  };
  const location = useLocation();
  const navigate = useNavigate();
  const client = useQueryClient();
  console.log(location.state)
  const InitialState = {
    config_name: edit ? location.state.config_name : "",
    config_caption: edit ? location.state.config_caption : "",
    file: edit ? null : selectedFile,
    status: edit ? location.state.status : "active",
  };
  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    isValid,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: SysInfoFileSchema,
    onSubmit: async (action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });
  const handlePost = async (e) => {
    const formData = new FormData();

    for (const key in values) {
      if (key === "file") {
        continue;
      }
      formData.append(key, values[key]);
    }
    if (selectedFile) {
      formData.append("file", selectedFile)
    }
    const res = edit
      ? await PatchAxios(`/admin/sysinfofile/${location.state._id}`, formData)
      : await PostAxios("/admin/sysinfofile", formData);

    if (res.success) {
      client.refetchQueries("SysinfoFileList");
      navigate("/admin/systeminfofile");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
      return true;
    } else {
      toast.error(res.message);
    }
  };
  return (
    <>
      <Form>
        <h2 className="text-center text-muted mb-2">
          {edit ? "Update" : "Add"}System Info File
        </h2>

        <Row className="m-2 p-2">
          {!edit && (<Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="config_name">
                Name
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  id="config_name"
                  placeholder="Title"
                  name="config_name"
                  value={values.config_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.config_name && touched.config_name ? (
                <p className="text-danger">{errors.config_name}</p>
              ) : null}
            </Form.Group>
          </Col>)}
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="config_captiom">
                Caption
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  id="config_caption"
                  placeholder="Caption"
                  name="config_caption"
                  value={values.config_caption}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.config_caption && touched.config_caption ? (
                <p className="text-danger">{errors.config_caption}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="Status">
                Status<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                className="form-select form-select-sm mb-3"
                aria-label="Default select example"
                name="status"
                value={values.status}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              >
                <option value="">Select One</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Select>
              {errors.status && touched.status ? (
                <p className="text-danger">{errors.status}</p>
              ) : null}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>File</Form.Label>
              <div className="input-group">
                <input
                  type="file"
                  onChange={handleFileChange}
                  name="file"
                />
              </div>
              {edit && location.state.feature_file &&
                <a href={baseURL + '/' + location.state.feature_file} target="_blank">Download Previous File</a>

              }
            </Form.Group>
          </Col>
          <div className="mt-4 d-flex justify-content-between">
            <Button
              type="submit"
              className={`btn btn-primary  `}
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting}
            >
              {edit
                ? isSubmitting
                  ? "Updating...."
                  : "Update"
                : isSubmitting
                  ? "Submitting...."
                  : "Submit"}
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default SysInfoFileForm;
