import React from "react";
import PageData from "../../PageData/PageData";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";

import { useQuery } from "react-query";

const Employer = () => {
  const employerColumns = [
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Organization Name",
      selector: (row) => row.company_name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Singing Officer",
      selector: (row) => row.signing_officer_email,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => <ActionButton row={row} pageTitle="employer" hasdetail isEmployer/>,
    },
  ];
  async function getField() {
    const url = `/admin/employer`;
    try {
      const response = await getAxios(url);
      const fetchedList = response.data;
      return fetchedList.employer;
    } catch (error) {
      console.log(error);
    }
  }
  const { data, error, isLoading } = useQuery("EmployerList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  return (
    <>
      <PageData columns={employerColumns} tableData={data} title="Employer" />
    </>
  );
};

export default Employer;
