import React from "react";
import { TopNav, SideNav } from "../Components/GeneralUI";
import { Outlet } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/demo2/style.css";

const AdminUi = () => {
  return (
    <>
      <div className="bmi main-wrapper">
        <SideNav />
        <div className="page-wrapper">
          <TopNav />
          <div className="page-content">
            <Outlet />
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
};

export default AdminUi;
