import React from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";

const Tag = () => {

  const tagsColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Display Order",
      selector: (row) => row.display_order,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Slug",
      selector: (row) => row.slug,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <ActionButton row={row} pageTitle="tag" />
      ),
    },
  ];
  async function getField() {
    const url = `/admin/tag`;
    try {
      const response = await getAxios(url);
      const fetchedList = response.data;
      return (fetchedList.tag);
    } catch (error) {
      console.log(error);
    }
  }

  const { data, error, isLoading } = useQuery("TagList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  return (
    <>
      <PageData columns={tagsColumns} tableData={data} title="Tag" />

    </>
  );
};

export default Tag;
