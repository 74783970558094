import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationModal = ({
  show,
  handleClose,
  //   handleConfirm,
  Content: employeeData,
  EmployerContent: employerData,
  isDetail = false,
  isemployer = false,
}) => {
  const fileURL = process.env.REACT_APP_FILE_URL;
  console.log(employerData)
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      scrollable
      className="not-confirm"
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isemployer ? employerData.company_name : employeeData.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid mt-5">
          {employeeData && (
            <div className="row">
              <div className="col-md-6">
                <p>
                  <strong>Email:</strong> {employeeData.email}
                </p>
                <p>
                  <strong>Phone:</strong> {employeeData.phone}
                </p>
                <p>
                  <strong>Country:</strong> {employeeData.country}
                </p>
                <p>
                  <strong>Current Address:</strong>{" "}
                  {employeeData.current_address}
                </p>
                <p>
                  <strong>Current Street:</strong> {employeeData.current_street}
                </p>
                <p>
                  <strong>Current City/Town:</strong>{" "}
                  {employeeData.current_city_town}
                </p>
                <p>
                  <strong>Special Accommodation:</strong>{" "}
                  {employeeData.special_accomodation ? "Yes" : "No"}
                </p>
                <p>
                  <strong>Physical Limitation:</strong>{" "}
                  {employeeData.physical_limitation ? "Yes" : "No"}
                </p>
                <p>
                  <strong>Pending US Asylum:</strong>{" "}
                  {employeeData.pending_us_asylum ? "Yes" : "No"}
                </p>
                <p>
                  <strong>Deported/Removed from US:</strong>{" "}
                  {employeeData.deported_removed_from_us ? "Yes" : "No"}
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  <strong>Passport Front:</strong>{" "}
                  <img
                    src={fileURL + "/" + employeeData.passport_front}
                    alt="Passport Front"
                    className="img-fluid"
                  />
                </p>
                {employeeData.resume && (
                  <p>
                    <strong>Download Resume:</strong>{" "}
                    <a
                      href={`${fileURL}/${employeeData.resume}`}
                      download
                      target="_blank"
                    >
                      Download
                    </a>
                  </p>
                )}
              </div>
            </div>
          )}
          {employerData && (
            <div className="row">
             
              <div className="col-md-6">
                <p>
                  <strong>Email:</strong> {employerData.email}
                </p>
                <p>
                  <strong>Phone:</strong> {employerData.phone}
                </p>
                <p>
                  <strong>Country:</strong> {employerData.country}
                </p>
                <p>
                  <strong>Current Address:</strong>{" "}
                  {employerData.street_number_name}{" "}
                  {employerData.suite_floor_apartment_number &&
                    "Suite/Floor/Apartment " + employerData.suite_floor_apartment_number}
                  , {employerData.city}, {employerData.state} {employerData.zip_code}
                </p>
                <p>
                  <strong>Current Street:</strong> {employerData.sth_street_number}
                </p>
                <p>
                  <strong>Current Designation:</strong> {employerData.sth_designation}
                </p>
                <p>
                  <strong>Actual Designation:</strong>{" "}
                  {employerData.sth_actual_designation}
                </p>
                <p>
                  <strong>Current City/Town:</strong> {employerData.sth_city}
                </p>
                <p>
                  <strong>Current State:</strong> {employerData.sth_state}
                </p>
                <p>
                  <strong>Current Zip Code:</strong> {employerData.sth_zip_code}
                </p>
                <p>
                  <strong>EIN:</strong> {employerData.sth_ein}
                </p>
                <p>
                  <strong>State Tax ID:</strong> {employerData.sth_state_tax_id}
                </p>
                <p>
                  <strong>Daytime Telephone:</strong> {employerData.sth_daytime_tel}
                </p>
                <p>
                  <strong>Mobile Telephone:</strong> {employerData.sth_mobile_tel}
                </p>
                <p>
                  <strong>Fax:</strong> {employerData.sth_fax}
                </p>
                <p>
                  <strong>Website:</strong> {employerData.sth_website}
                </p>
                <p>
                  <strong>Business Type:</strong> {employerData.sth_business_type}
                </p>
                <p>
                  <strong>NAICS Code:</strong> {employerData.sth_naics_code}
                </p>
                <p>
                  <strong>Date Established:</strong> {employerData.sth_date_established}
                </p>
                <p>
                  <strong>Current Employees:</strong> {employerData.sth_current_employees}
                </p>
                <p>
                  <strong>H1B Employees:</strong> {employerData.sth_h1b_employees}
                </p>
                <p>
                  <strong>Intended Employment:</strong>{" "}
                  {employerData.sth_intended_employment}
                </p>
                <p>
                  <strong>About Us:</strong> {employerData.sth_about_us}
                </p>
                <p>
                  <strong>H1B Dependent:</strong> {employerData.sth_h1b_dependent}
                </p>
                <p>
                  <strong>Willful Violator:</strong> {employerData.sth_willful_violator}
                </p>
                <p>
                  <strong>Gross Revenue:</strong> {employerData.sth_gross_revenue}
                </p>
                <p>
                  <strong>Net Income:</strong> {employerData.sth_net_income}
                </p>
              </div>
              <div className="col-md-6">
                {employerData.org_logo && (
                  <img
                    src={fileURL + '/' + employerData.org_logo}
                    alt="Organization Logo"
                    className="img-fluid"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                )}
              </div>
            </div>

          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>

      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
