import React, { useState, useRef, useEffect } from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import "./Form.css";
import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { GallerySchema } from "./Schemas";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios, getAxios } from "../../../helper/AxiosHelper";
import { UploadImage } from "../../../Home/assets/images";

const GalleryForm = ({ edit }) => {
  const baseURL = process.env.REACT_APP_FILE_URL;
  const location = useLocation();
  const client = useQueryClient();
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const [images, setImages] = useState("");
  function handleImageClick(e) {
    imageRef.current.click();
  }
  function handleImageChange(e) {
    const file = e.target.files[0];
    setImages(file);
  }
  const handlePost = async (e) => {
    const formData = new FormData();

    for (const key in values) {
      if (key === "image") {
        continue;
      }
      formData.append(key, values[key]);
    }
    if (!edit) {
      if (InitialState.image instanceof File) {
        formData.append("image", InitialState.image);
      } else {
        formData.append("image", InitialState.image);
      }
    } else {
      if (edit && images instanceof File) {
        formData.append("image", images);
      } else {
        formData.append("image", InitialState.image);
      }
    }
    const res = edit
      ? await PatchAxios(`/admin/gallery/${location.state._id}`, formData)
      : await PostAxios("/admin/gallery", formData);

    if (res.success) {
      client.invalidateQueries("GalleryList");
      navigate("/admin/gallery");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
    } else {
      toast.error(res.message);
    }
  };

  const InitialState = {
    title: edit ? location.state.title : "",
    image: edit ? location.state.image : images,
    album: edit ? location.state.album : "",
  };
  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: GallerySchema,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });
  const [albums, setAlbums] = useState([]);
  const getAlbum = async (e) => {
    const res = await getAxios("/admin/album");
    setAlbums(res.data.album);
  };
  const getAlbumName = (id) => {
    const album = albums.find((album) => album._id === id);
    if (album) {
      return album.title;
    } else {
      return "Loading...";
    }
  };

  useEffect(() => {
    getAlbum();
  }, []);
  return (
    <>
      <Form>
        <h2 className="text-center text-muted mb-2">Add gallery</h2>

        <Row className="m-2 p-2">
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="name">
                Title
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  placeholder="Title"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                />
              </InputGroup>

              {errors.title && touched.title ? (
                <p className="text-danger">{errors.title}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="album_id">
                Album <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                className="form-select form-select-sm mb-3"
                aria-label="Default select example"
                name="album"
                value={values.album}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              >
                {!edit ? (
                  <option value="">Select One</option>
                ) : (
                  <option value={InitialState.album}>
                    {getAlbumName(InitialState.album)}
                  </option>
                )}
                {albums.map(
                  (album) =>
                    album._id !== InitialState.album && (
                      <option key={album._id} value={album._id}>
                        {album.title}
                      </option>
                    )
                )}
              </Form.Select>
              {errors.album && touched.album ? (
                <p className="text-danger">{errors.album}</p>
              ) : null}
            </Form.Group>
          </Col>{" "}
          <Col md={6}>
            Image <span className="text-danger">*</span>
            <div className="image_holder_rect">
              {!images ? (
                <img
                  src={
                    edit && InitialState.image
                      ? `${baseURL}/${InitialState.image}`
                      : UploadImage
                  }
                  // className="rounded-circle"
                  alt="Cinque Terre"
                  height={200}
                  width={200}
                  onClick={handleImageClick}
                />
              ) : (
                <img
                  src={URL.createObjectURL(images)}
                  alt="galleryInput"
                  // className="rounded-circle"
                  height={200}
                  width={200}
                  onClick={handleImageClick}
                />
              )}
              <input
                type="file"
                ref={imageRef}
                accept="image/*"
                onChange={handleImageChange}
              />
            </div>
          </Col>
          <div className="mt-4 d-flex justify-content-between">
            <Button
              type="submit"
              className={`btn btn-primary  `}
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting}
            >
              {edit
                ? isSubmitting
                  ? "Updating...."
                  : "Update"
                : isSubmitting
                  ? "Submitting...."
                  : "Submit"}
            </Button>
            <Button
              type="button"
              className="btn btn-danger "
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default GalleryForm;
