import * as Yup from "yup";

const ContactSchema = Yup.object().shape({
  first_name: Yup.string().required("Please enter your first name"),
  last_name: Yup.string().required("Please enter your last name"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  phone: Yup.string(),
  message: Yup.string(),
  status: Yup.string().oneOf(["active", "inactive"]).default("active"),
});

export default ContactSchema;
