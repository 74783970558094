import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getUserAxios } from "../../../../helper/UserAxios";
import { useNavigate } from "react-router-dom";
import { EmployerForm } from "../../../../AdminPanel/Components/Forms";
import EditModal from "./EditModel";



const EmpProfile = ({ employer }) => {
  const fileURL =
    process.env.REACT_APP_FILE_URL;
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const data = useSelector((state) => state.emp.emp_info.data);
  const getField = async () => {
    const url = `/employer/me`;
    try {
      const response = await getUserAxios(url);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  };
  const { data: EmpInfo } = useQuery("EmpInfo", getField);

  const handleEdit = () => {
    console.log(EmpInfo);
    navigate("/employer/edit", { state: EmpInfo });
  };
  const handleImageChange = () => {
    setShow(true);
  }
  return (
    !show ? (
      <div className="main-content-inner">
        <div className="profile-outer">
          <div className="dashboard__profile card profile-card">
            <div className="card-body profile-card__body">
              {/* <div className="profile-card__avatar">
              <img src="dist/images/avatar-3.jpg" alt="" />
            </div> */}
              <div className="profile-card__name">{data.company_name}</div>{" "}
              <div className="profile-card__email">{data.email}</div>
              <div className="profile-card__edit">
                <button
                  className="btn btn--primary btn-sm"
                  onClick={() => handleEdit()}
                >
                  Edit Profile
                </button>
              </div>
            </div>
          </div>

          <div className="dashboard__profile card profile-card">
            <div className="card-body profile-card__body">
              <div className="profile-card__avatar">
                <img src={fileURL + '/'+ data.org_logo} alt="" />
              </div>
              <div className="profile-card__name">

              </div>{" "}
              {console.log(EmpInfo)}
              <div className="profile-card__email">{data.company_name}</div>
              <div className="profile-card__edit">
                <button
                  className="btn btn--primary btn-sm"
                  onClick={handleImageChange}
                >
                  Edit Image
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) :
      <EditModal
        show={show}
        handleClose={() => setShow(false)}
        isDetail
      />

  );
};

export default EmpProfile;
