import * as Yup from "yup";

const GallerySchema = Yup.object().shape({
  title: Yup.string().required("Please enter a title"),
  album: Yup.string(),
  image: Yup.string(),
  
});

export default GallerySchema;
