import React, { useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { PatchUserAxios } from "../../../../helper/UserAxios";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";

const EditModal = ({
  show,
  handleClose,
  handleConfirm,
  Content: employeeData,
  isDetail = false,
}) => {
  const imageRef = useRef(null);
  const [images, setImages] = useState("");
  const navigate = useNavigate();
  const client = useQueryClient();
  const baseURL = process.env.REACT_APP_FILE_URL;
  const InitialState = {
    image: images,
  };
  
  function handleImageClick(e) {
    imageRef.current.click();
  }
  function handleImageChange(e) {
    const file = e.target.files[0];
    setImages(file);
  }
  const handlePost = async (e) => {
    const formData = new FormData();
    formData.append('org_logo', images)

    const res = await PatchUserAxios('/employer/changeimage', formData)

    if (res.success) {
      client.refetchQueries('EmpInfo')
      navigate("/employer/dashboard");
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };
  const {
    isSubmitting,
    values,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: InitialState,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      scrollable
      className="not-confirm"
      size={isDetail ? "lg" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Organization Logo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='text-center'>
          <h3 className='text-light'>Upload Image</h3>
          <div className="image_holder">
            {!images ? (
              <img
                src={
                  InitialState.image
                    ? `${baseURL}/${InitialState.image}`
                    : "https://media.istockphoto.com/id/1248723171/nl/vector/camera-pictogram-van-het-foto-uploaden-op-ge%C3%AFsoleerde-witte-achtergrond-eps-10-vector.jpg?s=612x612&w=0&k=20&c=WLTMjPeoFbET0aBnnJmLyzDE1wUkJu9nj07KPtmf8qg="
                }
                className="rounded-circle"
                alt="Cinque Terre"
                height={200}
                width={200}
                onClick={handleImageClick}
              />
            ) : (
              <img
                src={URL.createObjectURL(images)}
                alt="bannerInput"
                className="rounded-circle"
                height={200}
                width={200}
                onClick={handleImageClick}
              />
            )}
            <input
              type="file"
              ref={imageRef}
              accept="image/*"
              onChange={handleImageChange}
            />
          </div>

        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          className={`btn btn-primary`}
          onClick={handleSubmit}
          disabled={!isValid || isSubmitting}
        >
          {isSubmitting
            ? "Updating...."
            : "Update"
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
