import React from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
const UserPageData = ({
  employer,
  data,
  columns,
  customeHeader = false,
  handleRefetch,
}) => {
  const navigate = useNavigate();


  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#ccc",
      },
    },
    subHeader: {
      header: {
        margin: 0, // Set the margin for the subheader to zero
      },
    },
  };
  return (
    <DataTable
      customStyles={tableHeaderstyle}
      pagination={!customeHeader}
      columns={columns}
      data={data}
      highlightOnHover
      fixedHeader
      subHeader={customeHeader}
      subHeaderComponent={
        <>
          <button
            className="btn btn-info m-1"
            onClick={() => {
              navigate(`/${employer ? 'employer' : 'employee'}/jobs`);
            }}
          >
            View More
          </button>
        </>
      }

    // paginationRowsPerPageOptions={option}
    // paginationPerPage={defaultPagination}

    // subHeaderAlign="right"
    />
  );
};

export default UserPageData;
