// StepRenderer.jsx
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Step0, Step5 } from "../UserRegister/List";
import EditImagesCatTag from "../UserRegister/EditImagesCatTag";
import { getUserAxios } from "../../../helper/UserAxios";

const Edit = () => {
  const location = useLocation();

  const what = location.state ? location.state.what : null;
  const data = location.state ? location.state.data : null;

  if (what === "profile") {
    return <Step0 edit={true} editData={data} />;
  } else if (what === "detail") {
    return <Step5 edit={true} editData={data} />;
  } else {
    return <EditImagesCatTag />;
  }
};

export default Edit;
