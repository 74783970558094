import React, { useEffect, useState } from "react";
import RegButton from "./RegButton";
import { useNavigate } from "react-router-dom";
import { getCMS } from "../../../../helper/UserAxios";

const Step1 = ({ next, back }) => {
  const [content, setContent] = useState([]);
  const navigate = useNavigate();
  const getFunc = async () => {
    const res = await getCMS("page_id=term-1");
    setContent(res.data[0]);
  };
  useEffect(() => {}, []);

  useEffect(() => {
    getFunc();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main class="body-wrapper inner bg--1 p-tb-60">
        <div class="pine-cost-page">
          <div class="container">
            <div class="pine-cost ">
              <div className="text-center">
                <h3 class="m-b-30">{content.title}</h3>
                <ul class="number-tab">
                  <li class="active text-white">1</li>
                  <li>2</li>
                  <li>3</li>
                  <li>4</li>
                  <li>5</li>
                </ul>
              </div>
              <div class="pine-cost-content m-t-30">
                <div dangerouslySetInnerHTML={{ __html: content.desc }} />

                {/* <div class="text-center">
                  <div class="check-outer text-center m-t-60">
                    <div class="check-inner">
                      <label class="check-box" id="check">
                        <small>
                          I have reviewed and understand all materials shown
                          above.
                        </small>
                        <input
                          type="checkbox"
                          onChange={handleCheckboxChange}
                          checked={isChecked}
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div> */}

                <RegButton back={back} next={next} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Step1;
