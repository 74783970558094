import React, { useState } from "react";
import { PostUserAxios } from "../../../../helper/UserAxios";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { ConfirmationModal } from "../../../../AdminPanel/Components/GeneralUI";
import { useNavigate } from "react-router-dom";

const ActionButton = ({
  row,
  noApply = false,
  employer,
  employerApp = false,
}) => {
  const client = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
  };

  const [content, setContent] = useState(row);
  const navigate = useNavigate();
  const handleApply = async (row) => {
    const res = await PostUserAxios("/employee/apply_vacancy", {
      vacancy: row._id,
    });

    if (res.success) {
      toast.success(res.message);
      client.refetchQueries("EmploJobList");
      navigate("/employee/dashboard");
    } else {
      toast.error(res.message);
    }
  };
  const handleDetail = (row) => {
    setShowModal(true);
  };
  return !showModal ? (
    <div>
      {!noApply && (
        <button
          className="btn btn-success m-1"
          onClick={() => handleApply(row)}
        >
          Apply
        </button>
      )}
      <button className="btn btn-primary m-1" onClick={() => handleDetail(row)}>
        Detail
      </button>
    </div>
  ) : (
    <ConfirmationModal
      show={showModal}
      handleClose={handleClose}
      Content={content}
      isDetail
      employer={employer}
      employerApp={employerApp}
    />
  );
};

export default ActionButton;
