import React from "react";
import PageData from "../../PageData/PageData";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";
import { useQuery } from "react-query";

const Education = () => {
  const educationColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Started",
      selector: (row) => row.started,
      sortable: true,
    },
    {
      name: "Completed",
      selector: (row) => row.completed,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => <ActionButton row={row} pageTitle="education" />,
    },
  ];

  async function getField() {
    const url = `/employee/education`;
    try {
      const response = await getAxios(url);
      const fetchedList = response.data;

      return fetchedList.education;
    } catch (error) {
      console.log(error);
    }
  }
  const { data, error, isLoading } = useQuery("EducationList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }

  return (
    <>
      <PageData columns={educationColumns} tableData={data} title="Education" />
    </>
  );
};

export default Education;
