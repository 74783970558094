import React, { useState, useRef, useEffect } from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios, getAxios } from "../../../helper/AxiosHelper";

import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { EmployeeSchema } from "./Schemas";
import makeAnimated from "react-select/animated";

const EmployeeForm = ({ edit }) => {
  const [tags, setTags] = useState([]);
  const getTag = async (e) => {
    const res = await getAxios("/admin/tag");
    setTags(res.data.tags);
  };
  useEffect(() => {
    getTag();
  }, []);
  const location = useLocation();

  const navigate = useNavigate();

  const InitialState = {
    first_name: edit ? location.state.first_name : "",
    last_name: edit ? location.state.last_name : "",
    date_of_birth: edit ? location.state.date_of_birth : "",
    email: edit ? location.state.email : "",
    phone: edit ? location.state.phone : "",
    password: "",
    re_password: "",
    status: edit ? location.state.status : "active",
    tags: edit ? location.state.tags : "",
    role: edit ? location.state.role : "",
    country: edit ? location.state.country : "",
    current_address: edit ? location.state.current_address : "",
    current_street: edit ? location.state.current_street : "",
    current_city_town: edit ? location.state.current_city_town : "",
    current_province_stree: edit ? location.state.current_province_stree : "",
    zip_code: edit ? location.state.zip_code : "",
    special_accommodation: edit
      ? location.state.special_accommodation
      : "false",
    physical_limitation: edit ? location.state.physical_limitation : "false",
    pending_us_asylum: edit ? location.state.pending_us_asylum : "false",
    deported_removed_from_us: edit
      ? location.state.deported_removed_from_us
      : "false",
    passport_front: null, // Assuming this is a file input
    passport_back: null, // Assuming this is a file input
    resume: null, // Assuming this is a file input
    message: "",
    pine_visa_cost: edit ? location.state.pine_visa_cost : "false",
    obligations: edit ? location.state.obligations : "false",
    work_history_disclaimer: edit
      ? location.state.work_history_disclaimer
      : "false",
    used_in_immigration_filing: edit
      ? location.state.used_in_immigration_filing
      : "false",
  };

  const imageRef = useRef(null);
  function handleImageClick(e) {
    imageRef.current.click();
  }
  function handleImageChange(e) {
    const file = e.target.files[0];
    setImage(file);
  }
  const [image, setImage] = useState("");
  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: EmployeeSchema,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
      if (res.success) {
        navigate("/admin/user");
        toast.success(edit ? "Updated Successfully" : "Added Successfully");
        return true;
      } else {
        toast.error(res.message);
      }
    },
  });
  const handlePost = async (e) => {
    const res = edit
      ? await PatchAxios(`/admin/employee/${location.state._id}`, values)
      : await PostAxios("/admin/employee", values);

    if (res.success) {
      navigate("/admin/employee");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
      return true;
    } else {
      toast.error(res.message);
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <h2 className="text-center text-muted mb-2">Add Employee</h2>

        <Row className="m-2 p-2">
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="name">
                First Name
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  id="name"
                  placeholder="First Name"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="name">
                Last Name
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  id="name"
                  placeholder="Last Name"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="name">
                Select your country of birth
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  id="name"
                  placeholder="Country Name"
                  name="country"
                  value={values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="current_address">
                Where do you live now?
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  id="current_address"
                  placeholder="Current Address"
                  name="current_address"
                  value={values.current_address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="current_street">Current Street</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  id="current_street"
                  placeholder="Current Street"
                  name="current_street"
                  value={values.current_street}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="current_street">City or Town</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  id="current_street"
                  placeholder="Current City Town"
                  name="current_city_town"
                  value={values.current_city_town}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="current_street">
                Province, State or Country
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  id="current_street"
                  placeholder="Current Provience Street"
                  name="current_provience_street"
                  value={values.current_province_street}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="current_street">Zip Code</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  id="current_street"
                  placeholder="Zip Code"
                  name="zip_code"
                  value={values.zip_code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="colourIdentifier">
                Your primary phone number<span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  id="colourIdentifier"
                  placeholder="Phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
              </InputGroup>
            </Form.Group>
          </Col>
          {/* OCCUPATION BAKI XA */}
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="residentType">
                Do you require and special accomodations? ( Example: work
                schedule limitations due to religious or cultural beliefs )
              </Form.Label>
              <Form.Select
                className="form-select form-select-sm mb-3"
                aria-label="Default select example"
                name="special_accomodation"
                value={values.special_accommodation}
                onChange={handleChange}
                onBlur={handleBlur}
                //required
              >
                <option value="false">False</option>
                <option value="true">True</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="residentType">
                Have you ever been deported or removed from the United States?
              </Form.Label>
              <Form.Select
                className="form-select form-select-sm mb-3"
                aria-label="Default select example"
                name="removed_from_us"
                value={values.removed_from_us}
                onChange={handleChange}
                onBlur={handleBlur}
                //required
              >
                <option value="false">False</option>
                <option value="true">True</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group>
              <Form.Label htmlFor="residentType">
                Do you have a pending U.S. asylum application?
              </Form.Label>
              <Form.Select
                className="form-select form-select-sm mb-3"
                aria-label="Default select example"
                name="pending_us_asylum"
                value={values.pending_us_asylum}
                onChange={handleChange}
                onBlur={handleBlur}
                //required
              >
                <option value="false">False</option>
                <option value="true">True</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>
                Upload your passwort (Files supported: JPG, JPEG, PNG) *
              </Form.Label>
              <input
                type="file"
                // ref={imageRef}
                accept="image/*"
                // onChange={handleImageChange}
              />
              <div className="upload-img">
                <i className="fa-solid fa-image"></i>
              </div>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Upload your resume ( optional) </Form.Label>
              <Form.Control type="file" />
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group>
              <Form.Label htmlFor="roomNumber">Message</Form.Label>
              <div className="input-group">
                <Form.Control
                  as="textarea"
                  rows={3}
                  className="form-control"
                  placeholder=" "
                />
              </div>
            </Form.Group>
          </Col>
          {/* <div className="check-outer m-t-30">
                <div className="check-inner">
                  <label className="check-box" id="check">
                    <small>
                      I have reviewedd and understand all materials shown above.
                    </small>
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="btn-wrap m-t-20">
                <button
                  class="btn btn--primary-outline w-25"
                  onClick={() => {
                    back();
                  }}
                >
                  Back
                </button>
                <button type="submit" className="btn btn--accent w-25 ">
                  Register
                </button>
              </div> */}
        </Row>
      </Form>
    </>
  );
};

export default EmployeeForm;
