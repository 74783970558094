import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as formik from "formik";
import * as Yup from "yup";
import { Row, Col, Button, NavLink } from "react-bootstrap";
import { loginAxios } from "../../../../helper/AxiosHelper";
import "react-toastify/dist/ReactToastify.css";
import { loggedIn } from "../../../routing/admin.routing";
import { toast } from "react-toastify";
import "../../../assets/css/demo2/style.css";
import { addUser } from "../../../../store/adminSlice";
import { useDispatch } from "react-redux";
import { logo } from "../../../../Home/assets/images";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  async function postData(values) {
    const data = {
      email: values.userEmail,
      password: values.userPassword,
    };
    try {
      const res = await loginAxios("/auth/login", data);
      if (res.success) {
        navigate("/admin/dashboard", { replace: true });
        dispatch(addUser(res.data));
        toast.success(res.message);
        localStorage.setItem("level", JSON.stringify(res.data.role_id.level));
        const level = JSON.parse(localStorage.getItem("level"));
        console.log(level);
        window.location.reload();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.warning(error.message);
    }
  }
  const { Formik, Form, Field, ErrorMessage } = formik;
  const validationSchema = Yup.object().shape({
    userEmail: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    userPassword: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });
  useEffect(() => {
    if (loggedIn()) {
      navigate("/admin/dashboard");
    }
  }, []);

  const initialValues = {
    userEmail: "",
    userPassword: "",
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const response = await postData(values);
    setSubmitting(false);
  };
  const handlePwForget = () => {
    navigate("/forget-password", { state: "admin" });
  };
  return (
    <div className="bmi">
      <div className="main-wrapper ">
        <div className="page-wrapper full-page">
          <div className="page-content d-flex align-items-center justify-content-center">
            <div className="row w-100 mx-0 auth-page">
              <div className="col-md-8 col-xl-6 mx-auto">
                <div className="card">
                  <div className="row">
                    <div className="col-md-8 ps-md-0">
                      <div className="auth-form-wrapper px-4 py-5">
                        <div className="col-md-3 col-lg-2 col-7">
                          <Link to="/" className="logo">
                            <img src={logo} alt="Logo" />
                          </Link>
                        </div>
                        <h5 className="text-muted fw-normal mb-4">
                          Welcome back! Log in to your account.
                        </h5>

                        <Row>
                          <Col xs={12} md={12}>
                            <Formik
                              initialValues={initialValues}
                              validationSchema={validationSchema}
                              onSubmit={onSubmit}
                            >
                              {({ isSubmitting, errors, touched }) => (
                                <Form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="userEmail"
                                      className="form-label"
                                    >
                                      Email address
                                    </label>
                                    <Field
                                      type="email"
                                      name="userEmail"
                                      id="userEmail"
                                      className={`form-control form-lg ${
                                        errors.userEmail && touched.userEmail
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Email"
                                    />
                                    <ErrorMessage
                                      name="userEmail"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <label
                                      htmlFor="userPassword"
                                      className="form-label"
                                    >
                                      Password
                                    </label>
                                    <Field
                                      type="password"
                                      name="userPassword"
                                      id="userPassword"
                                      className={`form-control ${
                                        errors.userPassword &&
                                        touched.userPassword
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Password"
                                    />
                                    <ErrorMessage
                                      name="userPassword"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>

                                  <div className="form-check mb-3">
                                    <Field
                                      type="checkbox"
                                      name="authCheck"
                                      id="authCheck"
                                      className="form-check-input"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="authCheck"
                                    >
                                      Remember me
                                    </label>
                                    <div
                                      to="/admin/forgotpassword"
                                      className="forgotpassword cursor-pointer"
                                      onClick={handlePwForget}
                                    >
                                      Forgot Password?
                                    </div>
                                  </div>

                                  <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting ? "Submitting..." : "Login"}
                                  </Button>
                                </Form>
                              )}
                            </Formik>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
