import React from "react";
import PageData from "../../PageData/PageData";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";
//Comment Added
import { useQuery } from "react-query";
const Sysinfo = () => {
  const systemInfoColumns = [
    {
      name: "Config Name",
      selector: (row) => row.config_name,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <ActionButton row={row} pageTitle="Systeminfo" RouteTitle="sysinfo" />
      ),
    },
  ];
  async function getField() {
    const url = `/admin/sysinfo`;
    try {
      const response = await getAxios(url);
      const fetchedList = response.data;
      console.log(fetchedList);
      return fetchedList.system_info;
    } catch (error) {
      console.log(error);
    }
  }
  const { data, error, isLoading } = useQuery("SysteminfoList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }

  return (
    <>
      <PageData
        columns={systemInfoColumns}
        tableData={data}
        title="SystemInfo"
        displayTitle="System Information"
      />
    </>
  );
};

export default Sysinfo;
