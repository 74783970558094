import React from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";

const Currency = () => {
  const currenyColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Symbol",
      selector: (row) => row.symbol,
      sortable: true,
    },
    {
      name: "Vaue",
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => <ActionButton row={row} pageTitle="currency" />,
    },
  ];

  async function getField() {
    const url = `/admin/currency`;
    try {
      const response = await getAxios(url);
      const fetchedCurrencyList = response.data;
      return fetchedCurrencyList.currency;
    } catch (error) {
      console.log(error);
    }
  }

  const { data, error, isLoading } = useQuery("CurrencyList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  return (
    <>
      <PageData columns={currenyColumns} tableData={data} title="Currency" />
    </>
  );
};

export default Currency;
