import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const FormUi = ({ form, edit = false }) => {
  const user = useSelector((state) => state.admin.user_info);
  const navigate = useNavigate();
  if (user && edit && user.role_id.level > 3) {
    navigate("/admin/dashboard");
  }
  return (

    <div className="text-white">
      <button
        className="btn btn-primary"
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </button>
      {form}
    </div>

  );
};

export default FormUi;
