import React, { useState } from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";

const Gallery = () => {
  const fileURL =
    process.env.REACT_APP_FILE_URL;

  const [albums, setAlbums] = useState([]);
  const getAlbum = async (e) => {
    const res = await getAxios("/admin/album");
    setAlbums(res.data.album);
  };
  const getAlbumName = (id) => {
    const album = albums.find((album) => album._id === id);
    if (album) {
      return album.title;
    } else {
      return "Loading...";
    }
  };
  const galleryColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Album",
      selector: (row) => getAlbumName(row.album),
    },
    {
      name: "Image",
      selector: (row) => row.image,
      cell: (row) => (
        <div className="rounded-circle">
          <img
            className="rounded-circle"
            src={fileURL + "/" + row.image} // Use the image URL from the data
            alt={row.title} // Provide alt text for accessibility
            width={50}
            height={50}
          />
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <ActionButton row={row} pageTitle="gallery" />
      ),
    },
  ];
  async function getField() {
    const url = `/admin/gallery`;
    try {
      const response = await getAxios(url);
      const fetchedGalleryList = response.data;
      return (fetchedGalleryList.image);
    } catch (error) {
      console.log(error);
    }
  }

  const { data, error, isLoading } = useQuery("GalleryList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }

  return (
    <>
      <PageData
        columns={galleryColumns}
        tableData={data}
        title="Gallery"
      />
    </>
  );
};

export default Gallery;
