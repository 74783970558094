import React from "react";
import { Link, useNavigate } from "react-router-dom";
import RegisterSchema, { EditSchema } from "./Schema/Step0";
import { useFormik } from "formik";
import { PatchUserAxios } from "../../../../helper/UserAxios";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
const UserRegister = ({ edit, editData, next, initialState, update }) => {
  const navigate = useNavigate();
  const query = useQueryClient();
  const InitialState = {
    first_name: edit
      ? editData.first_name
      : initialState.first_name
        ? initialState.first_name
        : "",
    last_name: edit
      ? editData.last_name
      : initialState.last_name
        ? initialState.last_name
        : "",
    email: edit ? editData.email : initialState.email ? initialState.email : "",
    phone: edit
      ? editData.phone
      : initialState.phone
        ? initialState.phone
        : null,
    ...(edit
      ? {}
      : {
        password: initialState.password ? initialState.password : "",
        re_password: initialState.re_password ? initialState.re_password : "",
      }),
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    setFieldValue,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: edit ? EditSchema : RegisterSchema,
    onSubmit: async () => {
      if (edit) {
        const res = await PatchUserAxios("/employee/updateprofile", values);
        if (res.success) {
          query.refetchQueries("EmpInfoList");
          navigate("/employee/dashboard");
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      } else {
        // Assuming `update` is a function you want to call
        update(values);
        next();
      }
    },
  });

  return (
    <main class="body-wrapper p-tb-60">
      <div class="container">
        <div class="row">
          <div class="col-md-7 col-lg-7 mx-auto">
            <form
              className="module"
              id="signUpForm"
              autoComplete="new-password"
            >
              <div className="heading text-center m-b-60">
                <h2>{edit ? "Update " : "Registration "} Form</h2>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div
                    className={`form-group ${errors.first_name && touched.first_name ? "has-error" : ""
                      }`}
                  >
                    <label htmlFor="fname">
                      First Name
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errors.first_name && touched.first_name
                          ? "is-invalid"
                          : ""
                        }`}
                      name="first_name"
                      id="fname"
                      placeholder="John"
                      value={values.first_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      autoFocus
                    />
                    {errors.first_name && touched.first_name ? (
                      <p className="text-danger">{errors.first_name}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <div
                    className={`form-group ${errors.last_name && touched.last_name ? "has-error" : ""
                      }`}
                  >
                    <label htmlFor="lname">
                      Last Name
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errors.last_name && touched.last_name
                          ? "is-invalid"
                          : ""
                        }`}
                      name="last_name"
                      id="lname"
                      placeholder="Doe"
                      value={values.last_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.last_name && touched.last_name ? (
                      <p className="text-danger">{errors.last_name}</p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div
                    className={`form-group ${errors.phone && touched.phone ? "has-error" : ""
                      }`}
                  >
                    <label htmlFor="number">
                      Mobile Number
                      <span className="text-danger">*</span>
                    </label>
                    <PhoneInput
                      inputStyle={{
                        width: "-webkit-fill-available",
                        padding: "5px 14px 5px 60px",
                      }}
                      enableSearch={true}
                      value={values.phone}
                      onChange={(phone) => setFieldValue("phone", phone)}
                      onBlur={handleBlur("phone")}
                    />

                    {errors.phone && touched.phone ? (
                      <p className="text-danger">{errors.phone}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <div
                    className={`form-group ${errors.email && touched.email ? "has-error" : ""
                      }`}
                  >
                    <label htmlFor="email">
                      Email address
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className={`form-control ${errors.email && touched.email ? "is-invalid" : ""
                        }`}
                      id="email"
                      name="email"
                      placeholder="Email Address"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.email && touched.email ? (
                      <p className="text-danger">{errors.email}</p>
                    ) : null}
                  </div>
                </div>
              </div>

              {!edit && (
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className={`form-group ${errors.password && touched.password ? "has-error" : ""
                        }`}
                    >
                      <label htmlFor="password1">
                        Password
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="password"
                        className={`form-control ${errors.password && touched.password
                            ? "is-invalid"
                            : ""
                          }`}
                        id="password1"
                        name="password"
                        placeholder="Password"
                        value={values.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {errors.password && touched.password ? (
                        <p className="text-danger">{errors.password}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className={`form-group ${errors.re_password && touched.re_password
                          ? "has-error"
                          : ""
                        }`}
                    >
                      <label htmlFor="confirm_password">
                        Repeat Password
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="password"
                        className={`form-control ${errors.re_password && touched.re_password
                            ? "is-invalid"
                            : ""
                          }`}
                        id="confirm_password"
                        name="re_password"
                        placeholder="Repeat Password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.re_password}
                      />
                      {errors.re_password && touched.re_password ? (
                        <p className="text-danger">{errors.re_password}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
              <div className={edit ? 'd-flex justify-content-between' : ''}>
                {edit &&
                  (<button
                    type="button"
                    className={`btn btn--primary w-49`}
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                  )}
                <button
                  type="submit"
                  className={`btn btn--accent ${!edit ? 'w-100' : ''} mx-1`}
                  onClick={handleSubmit}
                  disabled={!isValid}
                >
                  {edit ? "Update" : "Continue"}
                </button>
                {!edit && (
                  <div className="register-policy mt-4 text-center">
                    If you have an account. Please go to{" "}
                    <Link to="/login">Login</Link>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default UserRegister;
