import React from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";

const PageLimit = () => {

  const pagelimitColumns = [
    {
      name: "Limit",
      selector: (row) => row.limit_no,
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <ActionButton row={row} pageTitle="pagelimit" />
      ),
    },
  ];

  async function getField() {
    const url = `/admin/pagelimit`;
    try {
      const response = await getAxios(url);
      const fetchedPageLimitList = response.data;
      return (fetchedPageLimitList.page_limit);
    } catch (error) {
      console.log(error);
    }
  }

  const { data, error, isLoading } = useQuery("PageLimitList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  return (
    <>
      <PageData
        columns={pagelimitColumns}
        tableData={data}
        title="PageLimit"
      />
    </>
  );
};

export default PageLimit;
