import React from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios } from "../../../helper/AxiosHelper";

import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { SysInfoSchema } from "./Schemas";
import Editor from "../GeneralUI/Editor/Editor";

const SysInfoForm = ({ edit }) => {
  const location = useLocation();

  const navigate = useNavigate();
  const client = useQueryClient();
  const InitialState = {
    config_name: edit ? location.state.config_name : "",
    config_value: edit ? location.state.config_value : "",
    status: edit ? location.state.status : "active",
  };
  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    isValid,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: SysInfoSchema,
    onSubmit: async (action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    
    },
  });
  const handlePost = async (e) => {
    const res = edit
      ? await PatchAxios(`/admin/sysinfo/${location.state._id}`, values)
      : await PostAxios("/admin/sysinfo", values);

    if (res.success) {
      client.refetchQueries("SysteminfoList");
      navigate("/admin/systeminfo");
      toast.success(edit ? "Updated Successfully" : "Added Successfully");
      return true;
    } else {
      toast.error(res.message);
    }
  };
  return (
    <>
      <Form>
        <h2 className="text-center text-muted mb-2">
          {edit ? "Update " : "Add "}System Information
        </h2>

        <Row className="m-2 p-2">
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="config_name">
                Name
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  id="config_name"
                  placeholder="Title"
                  name="config_name"
                  value={values.config_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.config_name && touched.config_name ? (
                <p className="text-danger">{errors.config_name}</p>
              ) : null}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="Status">
                Status<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                className="form-select form-select-sm mb-3"
                aria-label="Default select example"
                name="status"
                value={values.status}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              >
                <option value="">Select One</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Select>
              {errors.status && touched.status ? (
                <p className="text-danger">{errors.status}</p>
              ) : null}
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Label htmlFor="long desc">Value</Form.Label>
            <Editor
              values={values.config_value}
              onValueChange={(value) => setFieldValue("config_value", value)}
            />
          </Col>
          <div className="mt-4 d-flex justify-content-between">
            <Button
              type="submit"
              className={`btn btn-primary  `}
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting}
            >
              {edit
                ? isSubmitting
                  ? "Updating...."
                  : "Update"
                : isSubmitting
                  ? "Submitting...."
                  : "Submit"}
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default SysInfoForm;
