import React from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";

const User = () => {
  const fileURL =
    process.env.REACT_APP_FILE_URL;

  const usercolumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role_id.name,
      sortable: true,
    },
    {
      name: "Photo",
      selector: (row) => row.photo,
      cell: (row) => (
        <div className="rounded-circle">
          <img
            className="rounded-circle"
            src={fileURL + "/" + row.image} // Use the image URL from the data
            alt={row.title} // Provide alt text for accessibility
            width={50}
            height={50}
          />
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => <ActionButton row={row} pageTitle="user" />,
    },
  ];

  async function getField() {
    const url = `/admin/user`;
    try {
      const response = await getAxios(url);
      const fetchedUserList = response.data;
      return fetchedUserList.users;
    } catch (error) {
      console.log(error);
    }
  }

  const { data, error, isLoading } = useQuery("UserList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  return (
    <>
      <PageData columns={usercolumns} tableData={data} title="User" />
    </>
  );
};

export default User;
