import React from "react";
import { useFormik } from "formik";
import { Form, Button } from "react-bootstrap";
import ChangePasswordSchema from "./ChangePasswordSchema"; 
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PatchAxios } from "../../../../helper/AxiosHelper";

const ChangePasswordAdmin = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      re_password: "",
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      const res = await Update();
      if (res) {
        resetForm();
      }
    },
  });
  const Update = async () => {
    const res = await PatchAxios("/user/changepass", formik.values);
    if (res.success) {
      navigate("/admin/dashboard");
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };
  return (
    <main className="bmi">
      <div className="row">
        <div className="col-md-8 col-lg-6 col-xl-5 col-12 mx-auto">
          <Form onSubmit={formik.handleSubmit} className="recover-pwd-form ">
            <div className="module">
              <h3 className="m-b-30 text-center">Change Password</h3>

              <Form.Group controlId="old_password">
                <Form.Label className="recover-pwd m-b-5">
                  Current Password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Old Password"
                  {...formik.getFieldProps("old_password")}
                  className={
                    formik.touched.old_password && formik.errors.old_password
                      ? "is-invalid"
                      : formik.touched.old_password
                        ? "is-valid"
                        : ""
                  }
                />
                {formik.touched.old_password && formik.errors.old_password ? (
                  <div className="text-danger">
                    {formik.errors.old_password}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group controlId="new_password">
                <Form.Label className="recover-pwd m-b-5">
                  New Password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="New Password"
                  {...formik.getFieldProps("new_password")}
                  className={
                    formik.touched.new_password && formik.errors.new_password
                      ? "is-invalid"
                      : formik.touched.new_password
                        ? "is-valid"
                        : ""
                  }
                />
                {formik.touched.new_password && formik.errors.new_password ? (
                  <div className="text-danger">
                    {formik.errors.new_password}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group controlId="re_password">
                <Form.Label className="recover-pwd m-b-5">
                  Retype Password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Retype Password"
                  {...formik.getFieldProps("re_password")}
                  className={
                    formik.touched.re_password && formik.errors.re_password
                      ? "is-invalid"
                      : formik.touched.re_password
                        ? "is-valid"
                        : ""
                  }
                />
                {formik.touched.re_password && formik.errors.re_password ? (
                  <div className="text-danger">{formik.errors.re_password}</div>
                ) : null}
              </Form.Group>

              <Button type="submit" className="btn btn--accent w-100 mb-3 mt-3">
                Change Password
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </main>
  );
};

export default ChangePasswordAdmin;
