import React from "react";
import { Modal, Button } from "react-bootstrap";

const StepsModal = ({
  show,
  handleClose,
  // Content,
  // appointmentDate,
  // link,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      scrollable
      className="not-confirm"
    >
      <Modal.Header closeButton>
        <Modal.Title>Onboarding Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Hello
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StepsModal;
