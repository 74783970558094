import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCMS } from "../../../helper/UserAxios";


const Aboutus = () => {
  const [content, setContent] = useState([]);
  const navigate = useNavigate(); 
  const getFunc = async () => {
    const res = await getCMS(
      "page_id=about-us"
    );
    setContent(res.data[0]);
  };
  useEffect(() => {
  }, []);
  
  useEffect(() => {
    getFunc();
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="body-wrapper inner p-t-40 p-b-60">
      <div className="about-page wow fadeInDown" data-wow-duration="2s">
        <div className="container">
          <div className="section-title m-b-40">
            <h2>{content.title}</h2>
          </div>
          <div className="about-content" dangerouslySetInnerHTML={{ __html: content.desc }}>
            
          </div>
      </div>
      </div>
    </main>
  );
};

export default Aboutus;
