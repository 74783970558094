import * as Yup from "yup";

const TagSchema = Yup.object().shape({
  title: Yup.string().required("Please enter a title").strict().trim(),
  display_order: Yup.string(),
  status: Yup.string().oneOf(["active", "inactive"]).default("active"),
  slug: Yup.string(),
});

export default TagSchema;
