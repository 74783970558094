import React, { useState } from "react";
import PageData from "../../PageData/PageData";
import { getAxios, delAxios, PatchAxios } from "../../../../helper/AxiosHelper";
import { useNavigate } from "react-router";
import { useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { ConfirmationModal } from "../../GeneralUI";
const SideBar = () => {
  const [showModal, setShowModal] = useState(false);
  const [content, setContent] = useState({});
  const navigate = useNavigate();
  const handleEdit = (row) => {
    navigate(`/admin/sidebar/edit/${row._id} `, { state: row });
  };
  const handleToggle = async (rowData) => {
    const status = rowData.status;
    let toggledStatus = "";
    if (status === "active") {
      toggledStatus = "inactive";
    } else {
      toggledStatus = "active";
    }
    const res = await PatchAxios(
      `/admin/sidebar/${rowData._id}/status?stats=${toggledStatus}`
    );
    if (res.success) {
      toast.success(res.message);
      getField();
    } else {
      toast.error(res.error);
    }
  };
  const handleDelete = async (uid) => {
    const res = await delAxios(`/admin/sidebar/${uid}`);
    if (res.success) {
      toast.success(res.message);
      getField();
      setShowModal(false);
    } else {
      toast.error(res.error);
      setShowModal(false);
    }
  };
  const deleteModel = (row) => {
    const newContent = {
      title: "Delete the Sidebar",
      body: `Are you sure you want to delete the Sidebar ${row.side_bar} `,
      id: row._id,
    };
    setContent(newContent); // Update the content state
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  const sidebarColumns = [
    {
      name: "Name",
      selector: (row) => row.display_name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <p className={row.status === "active" ? "text-success" : "text-danger"}>
          {row.status}
        </p>
      ),
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <div>
          <button
            className="btn btn-success mx-2"
            onClick={() => handleToggle(row)}
          >
            Toggle
          </button>
          <button
            className="btn btn-primary mx-2"
            onClick={() => handleEdit(row)}
          >
            Edit
          </button>
          <button
            className="btn btn-danger mx-2"
            onClick={() => deleteModel(row)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
  async function getField() {
    const url = `/admin/sidebar`;
    try {
      const response = await getAxios(url);
      const fetchedList = response.data;
      return fetchedList.side_bar;
    } catch (error) {
      console.log(error);
    }
  }
  const { data, error, isLoading } = useQuery("SidebarList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }

  return (
    <>
      {showModal ? (
        <ConfirmationModal
          show={showModal}
          handleClose={handleClose}
          handleConfirm={handleDelete}
          Content={content}
        />
      ) : (
        <PageData
          columns={sidebarColumns}
          tableData={data}
          title="Sidebar"
          addRequired={false}
        />
      )}
    </>
  );
};

export default SideBar;
