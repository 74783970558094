import React from "react";
import PageData from "../../PageData/PageData";
import { useQuery } from "react-query";
import { getAxios } from "../../../../helper/AxiosHelper";
import { ActionButton } from "../../GeneralUI";

const Language = () => {
  const fileURL = process.env.REACT_APP_FILE_URL;

  const languageColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Symbol",
      selector: (row) => row.symbol,
      sortable: true,
    },
    {
      name: "Image",
      selector: (row) => row.image,
      cell: (row) => (
        <div className="rounded-circle">
          <img
            className="rounded-circle"
            src={fileURL + "/" + row.image} // Use the image URL from the data
            alt={row.title} // Provide alt text for accessibility
            width={50}
            height={50}
          />
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => <ActionButton row={row} pageTitle="language" />,
    },
  ];

  async function getField() {
    const url = `/admin/language`;
    try {
      const response = await getAxios(url);
      const fetchedLanguageList = response.data;
      return fetchedLanguageList.language;
    } catch (error) {
      console.log(error);
    }
  }

  const { data, error, isLoading } = useQuery("LanguageList", getField);
  if (isLoading) {
    return <div>Loading......</div>;
  }
  if (error) {
    return <div>Error:{error.message}</div>;
  }
  return (
    <>
      <PageData columns={languageColumns} tableData={data} title="Language" />
    </>
  );
};

export default Language;
