import {
  PermissionGroupForm,
  RoleForm,
  UserForm,
  EmployerForm,
  EmployeeForm,
  TagForm,
  VacancyForm,
  ContactForm,
  CMSForm,
  BannerForm,
  CountryForm,
  CategoryForm,
  CurrencyForm,
  GoogleMapForm,
  PermissionForm,
  BlogForm,
  ExperienceForm,
  EducationForm,
  PageLimitForm,
  LanguageForm,
  EmailTemplateForm,
  SocialLinkForm,
  SysInfoForm,
  DefaultSeoForm,
  AlbumForm,
  GalleryForm,
  SideBarForm,
  SysInfoFileForm,
} from "./AdminPanel/Components/Forms";
import VacancyApply from "./AdminPanel/Components/Pages/VacancyApply/VacancyApply";
import {
  PermissionGroup,
  User,
  Tag,
  Category,
  Country,
  Role,
  Banner,
  Employer,
  Employee,
  Vacancy,
  Contact,
  CMS,
  Currency,
  GoogleMap,
  Permission,
  Blog,
  Education,
  Experience,
  PageLimit,
  Language,
  EmailTemplate,
  SocialLink,
  Sysinfo,
  DefaultSeo,
  Album,
  Gallery,
  SideBar,
  Appointmet,
} from "./AdminPanel/Components/Pages";
import SysInfoFile from "./AdminPanel/Components/Pages/SysInfoFile/SysInfoFile";
import Steps from "./AdminPanel/Components/Pages/Steps/Steps";
export const routesData = [
  {
    title: "user",
    add: true,
    edit: true,
    main: <User />,
    form: <UserForm />,
  },
  {
    title: "country",
    add: true,
    edit: true,
    main: <Country />,
    form: <CountryForm />,
  },
  {
    title: "education",
    add: true,
    edit: true,
    main: <Education />,
    form: <EducationForm />,
  },
  {
    title: "systeminfofile",
    add: true,
    edit: true,
    main: <SysInfoFile />,
    form: <SysInfoFileForm />,
  },
  {
    title: "experience",
    add: true,
    edit: true,
    main: <Experience />,
    form: <ExperienceForm />,
  },
  {
    title: "permission",
    add: true,
    edit: true,
    main: <Permission />,
    form: <PermissionForm />,
  },
  {
    title: "gmap",
    add: true,
    edit: true,
    main: <GoogleMap />,
    form: <GoogleMapForm />,
  },
  {
    title: "tag",
    add: true,
    edit: true,
    main: <Tag />,
    form: <TagForm />,
  },
  {
    title: "role",
    add: true,
    edit: true,
    main: <Role />,
    form: <RoleForm />,
  },
  {
    title: "category",
    add: true,
    edit: true,
    main: <Category />,
    form: <CategoryForm />,
  },
  {
    title: "emailtemp",
    add: true,
    edit: true,
    main: <EmailTemplate />,
    form: <EmailTemplateForm />,
  },
  {
    title: "banner",
    add: true,
    edit: true,
    main: <Banner />,
    form: <BannerForm />,
  },
  {
    title: "employer",
    add: true,
    edit: true,
    main: <Employer />,
    form: <EmployerForm />,
  },
  {
    title: "employee",
    add: true,
    edit: true,
    main: <Employee />,
    form: <EmployeeForm />,
  },
  {
    title: "vacancy",
    add: true,
    edit: true,
    main: <Vacancy />,
    form: <VacancyForm />,
  },
  {
    title: "contact",
    add: true,
    edit: true,
    main: <Contact />,
    form: <ContactForm />,
  },
  {
    title: "cms",
    add: true,
    edit: true,
    main: <CMS />,
    form: <CMSForm />,
  },
  {
    title: "pagelimit",
    add: true,
    edit: true,
    main: <PageLimit />,
    form: <PageLimitForm />,
  },
  {
    title: "currency",
    add: true,
    edit: true,
    main: <Currency />,
    form: <CurrencyForm />,
  },
  {
    title: "blog",
    add: true,
    edit: true,
    main: <Blog />,
    form: <BlogForm />,
  },
  {
    title: "language",
    add: true,
    edit: true,
    main: <Language />,
    form: <LanguageForm />,
  },
  {
    title: "sociallink",
    add: true,
    edit: true,
    main: <SocialLink />,
    form: <SocialLinkForm />,
  },
  {
    title: "systeminfo",
    add: true,
    edit: true,
    main: <Sysinfo />,
    form: <SysInfoForm />,
  },
  {
    title: "defaultseo",
    add: true,
    edit: true,
    main: <DefaultSeo />,
    form: <DefaultSeoForm />,
  },
  {
    title: "album",
    add: true,
    edit: true,
    main: <Album />,
    form: <AlbumForm />,
  },
  {
    title: "sidebar",
    add: false,
    edit: true,
    main: <SideBar />,
    form: <SideBarForm />,
  },
  {
    title: "vacancyapply",
    add: false,
    edit: false,
    main: <VacancyApply />,
    form: <SideBarForm />,
  },
  {
    title: "gallery",
    add: true,
    edit: true,
    main: <Gallery />,
    form: <GalleryForm />,
  },
  {
    title: "permissiongroup",
    add: true,
    edit: true,
    main: <PermissionGroup />,
    form: <PermissionGroupForm />,
  },
  {
    title: "appointment",
    add: false,
    edit: false,
    main: <Appointmet />,
    form: <PermissionGroupForm />,
  },
  {
    title: "steps",
    add: false,
    edit: false,
    main: <Steps />,
    form: <SysInfoFileForm />,
  },
];

export function getMain(title) {
  const route = routesData.find(function (route) {
    return route.title === title;
  });
  return route ? route.main : null;
}

export function getForm(title) {
  const route = routesData.find(function (route) {
    return route.title === title;
  });
  return route ? route.form : null;
}
