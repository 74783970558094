import React, { useState, useRef, useEffect } from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";
import "./Form.css";
import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { CategorySchema } from "./Schemas";
import { toast } from "react-toastify";
import { PatchAxios, PostAxios, getAxios } from "../../../helper/AxiosHelper";
const CategoryForm = ({ edit }) => {
  const baseURL = process.env.REACT_APP_FILE_URL;
  const location = useLocation();
  const client = useQueryClient();
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const [images, setImages] = useState("");

  const handlePost = async (e) => {
    const formData = new FormData();

    for (const key in values) {
      if (key === "image") {
        continue;
      }
      formData.append(key, values[key]);
    }
    if (!edit) {
      if (InitialState.image instanceof File) {
        formData.append("image", InitialState.image);
      } else {
        formData.append("image", InitialState.image);
      }
    } else {
      if (edit && images instanceof File) {
        formData.append("image", images);
      } else {
        formData.append("image", InitialState.image);
      }
    }
    const res = edit
      ? await PatchAxios(`/admin/category/${location.state._id}`, formData)
      : await PostAxios("/admin/category", formData);

    if (res.success) {
      client.refetchQueries("CategoryList");
      navigate("/admin/category");
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };
  const InitialState = {
    title: edit ? location.state.title : "",
    slug: edit ? location.state.slug : "",
    display_order: edit ? location.state.display_order : "",
    status: edit ? location.state.status : "active",
    image: edit ? location.state.image : images,
  };

  function handleImageClick(e) {
    imageRef.current.click();
  }
  function handleImageChange(e) {
    const file = e.target.files[0];
    setImages(file);
  }
  const {
    isSubmitting,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: InitialState,
    validationSchema: CategorySchema,
    onSubmit: async (values, action) => {
      const res = await handlePost();
      if (res) {
        action.resetForm();
      }
    },
  });
  const [categorys, setCategorys] = useState([]);
  const getCategory = async (e) => {
    const res = await getAxios("/admin/category");
    setCategorys(res.data.categorys);
  };
  useEffect(() => {
    getCategory();
  }, []);
  return (
    <>
      <Form>
        <h2 className="text-center text-muted mb-2">Add Category</h2>
        <div className="image_holder">
          {!images ? (
            <img
              src={
                edit && InitialState.image
                  ? `${baseURL}/${InitialState.image}`
                  : "https://media.istockphoto.com/id/1248723171/nl/vector/camera-pictogram-van-het-foto-uploaden-op-ge%C3%AFsoleerde-witte-achtergrond-eps-10-vector.jpg?s=612x612&w=0&k=20&c=WLTMjPeoFbET0aBnnJmLyzDE1wUkJu9nj07KPtmf8qg="
              }
              className="rounded-circle"
              alt="Cinque Terre"
              height={200}
              width={200}
              onClick={handleImageClick}
            />
          ) : (
            <img
              src={URL.createObjectURL(images)}
              alt="bannerInput"
              className="rounded-circle"
              height={200}
              width={200}
              onClick={handleImageClick}
            />
          )}
          <input
            type="file"
            ref={imageRef}
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
        <Row className="m-2 p-2">
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="name">
                Title
                <span className="text-danger">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  type="text"
                  id="name"
                  placeholder="Title"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
              {errors.title && touched.title ? (
                <p className="text-danger">{errors.title}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Slug</Form.Label>
              <Form.Control
                type="text"
                placeholder="Slug"
                name="slug"
                value={values.slug}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.slug && touched.slug ? (
                <p className="text-danger">{errors.slug}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Display Order</Form.Label>
              <Form.Control
                type="text"
                placeholder="Display Order"
                name="display_order"
                value={values.display_order}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.display_order && touched.display_order ? (
                <p className="text-danger">{errors.display_order}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="residentType">Status</Form.Label>
              <Form.Select
                className="form-select form-select-sm mb-3"
                aria-label="Default select example"
                name="status"
                value={values.status}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Select>
              {errors.status && touched.status ? (
                <p className="text-danger">{errors.status}</p>
              ) : null}
            </Form.Group>
          </Col>
          <div className="mt-4 d-flex justify-content-between">
            <Button
              type="submit"
              className={`btn btn-primary  `}
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting}
            >
              {edit
                ? isSubmitting
                  ? "Updating...."
                  : "Update"
                : isSubmitting
                ? "Submitting...."
                : "Submit"}
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default CategoryForm;
